.workflow-detail .avatar {
  .img-circle {
    width: 30px !important;
    height: 30px !important;
    margin-left: 10px;
  }
}

.sbx-legal-panel {
  > .header {
    font-size: 20px;
    font-family: @font-family-serif;
    font-weight: bold;
    padding-bottom: 5px;
  }
  flex-direction: column;
}

.sbx-knowledge-base-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  .sbx-knowledge-base-support {
    max-width: 75%;
    max-height: 55px;
  }
}

.sbx-legal-services-logo {
  height: auto;
  max-height: 55px;
  max-width: 100%;
}

.sbx-legal-services-text {
  color: @fps-text-secondary;
  font-size: 14px;
  display: flex;
}

.sbx-legal-button {
  display: flex;
  flex-direction: row;
  margin-top: -10px;
  > a {
    padding-right: 5px;
  }
}
