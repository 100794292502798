/**
 * Navbar Styles
 */

/*-----[ Navbar Dropdowns ]--------------------------------------------------- */
.navbar {
  .nav-dropdown {
    > a {
      display: block;
      height: 38px;
      width: 58px;
      position: relative;
    }
    .dropdown-toggle-alert {
      position: absolute;
      top: 50%;
      margin-top: -14px;
      right: 12px;
      background-color: @sb-orange;
      border-radius: 2px;
      padding: 1px 2px;
    }
    .dropdown-toggle-icon {
      display: block;
      width: 28px;
      height: 28px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -14px 0 0 -14px;
      text-align: center;
      border-radius: 50%;
      background-color: @sb-gray;
      color: @sb-gray-darker;
      font-size: 19px;
      padding-top: 2px;
    }
    .dropdown-menu {
      overflow-x: hidden;
    }
  } // end .nav-dropdown
  .nav-dropdown.open {
    > a {
      background: @sb-gray;
    }
    .dropdown-menu {
      margin: 0;
      padding: 10px 0;
    }
    .dropdown-toggle-icon {
      background-color: #fff;
    }
  } // end .nav-dropdown.open
  .empty-dropdown {
    padding: 10px;
  }
} // end .navbar

/*-----[ Notifications Panel ]------------------------------------------------ */
.notifications-actions {
  border-top: 1px solid @sb-gray-light;
  text-align: right;
  position: relative;
  a {
    margin: 5px;
  }
}

/*-----[ Lens Selector ]-------------------------*/
#lens-option {
  color: @fps-text;
  width: 100%;
  display: block;
  font-size: 12pt;
  line-height: 22px;
  position: relative; // for positioning of lids
  padding: 0px 15px 0px 35px;

  .lid-bottom,
  .lid-cap {
    display: block;
    position: absolute;
  }

  .lid-cap {
    top: 6px;
    left: 15px;
    height: 3px;
    width: 14px;
  }

  .lid-bottom {
    top: 10px;
    left: 16px;
    height: 7px;
    width: 12px;
    background: @sb-gray-light;
  }
  .lid-content {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
}

.lens-selector {
  padding: 10px 0;
  .lens-option {
    #lens-option();
    .lid-cap {
      background: @sb-gray;
    }
  }
  .myshoobx-lid .lid-cap {
    background: @sb-myshoobx;
  }
  .company-lid .lid-cap {
    background: @sb-company;
  }
  .board-lid .lid-cap {
    background: @sb-board;
  }
  .administration-lid .lid-cap {
    background: @sb-administration;
  }
  .investor-lid .lid-cap {
    background: @sb-investor;
  }
  .counsel-lid .lid-cap {
    background: @sb-counsel;
  }
}

// Bootstrap styling conflicts with lens-option styling
// Overwrite bootstrap styling
@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a.lens-option {
    #lens-option();
  }
} // end .lens-selector

/*-----[ UI Wizard Selectors ]-------------------------*/
sb-multi-step {
  padding: 5px;
  display: block;

  header {
    padding-bottom: 20px;
  }

  sb-tree {
    display: block;
    padding-left: 20px;
  }
} //end sb-multi-step
