.sbx-limited-partner-right-panel {
  padding-top: 20px;
  margin-left: 5%;
  margin-right: 5%;

  hr {
    border-color: @sb-gray-light;
    margin: 20px 0px;
  }

  .section-image {
    height: 100%;
    margin-left: 10px;
  }

  .logo-lineup {
    margin-bottom: 8px;

    img.entity-logo {
      width: 47%;
    }
    img.shoobx-logo {
      border-left: 1px solid @fps-text;
      padding-left: 7px;
      margin-left: 7px;
      width: 47%;
    }
  }

  .sbx-panel-subheader {
    margin-bottom: 5px;
    i.fas {
      padding-right: 10px;
    }
  }

  .sbx-panel-help-text {
    text-transform: capitalize;
    color: @sb-green;
    font-size: @font-size-h3;
    font-weight: 300;
    line-height: @font-size-h3;
  }

  .sbx-panel-contact {
    display: flex;
  }

  h3.sbx-panel-help-text {
    margin-top: 0;
  }
}
