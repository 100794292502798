.un-styled-list {
  list-style: none;
  padding: 0 !important;
}
sb-stakeholders-events {
  .actions {
    padding-bottom: 10px;
    button-content i {
      padding-left: 5px;
    }
  }
}
.stakeholder-events {
  list-style: none;
  padding-left: 0;

  .edit-action {
    float: right;
  }

  .sh-event-job-changed {
    color: @fps-text-secondary;
    div {
      padding: 5px 0;
    }

    .title {
      text-transform: uppercase;
    }
    .value {
      color: @fps-text;
    }
  }
  .history {
    border-top: 2px solid @sb-gray-light;
    padding: 10px 0;
  }
  .edit-event-action {
    background: none;
    color: @sb-green-type;
  }
  .stakeholder-event {
    .panel.panel-default {
      background-color: inherit;
    }
    .panel-detail {
      border-right: 2px solid @sb-gray-light;
      background-color: inherit;
      flex: 1;
      padding: 5px;
    }
    .panel-detail.date {
      flex: 1;
      margin: 0 10px;
    }
    .panel-detail.by {
      flex: 3;
    }
    .panel-detail.content {
      flex: 5;
      padding-left: 10px;
      padding-right: 0px;
    }
    .panel-detail.title {
      padding-left: 20px;
      flex: 3;

      i.status {
        display: block;
        font-size: 25px;
        padding-right: 10px;
        color: @sb-blue;
      }
      i.pending-marker {
        font-size: 14px;
        display: block;
        white-space: nowrap;
        padding-left: 5px;
      }
      i.status.pending {
        margin-top: -5px;
      }
      i.status.archived {
        color: @sb-red !important;
      }
      i.status.pending:before {
        font-style: normal;
        color: @sb-orange-type !important;
      }
      b.name {
        display: block;
      }
    }
    .event-header {
      background-color: @fps-box-header-background !important;
      padding: 5px;
      margin-bottom: 0;
      display: flex;
    }
    .event-body {
      border: 1px solid @sb-gray-light;
      border-top: none;
      padding: 20px;

      dt.key {
        float: left;
        clear: left;
        padding-right: 5px;
        color: @fps-text-secondary;
        text-transform: uppercase;
        font-weight: normal;
      }
      .documents i {
        padding-right: 5px;
      }
      .document-divider {
        border-top: 2px solid #cfd9d9;
        margin-top: 10px;
        padding-top: 10px;
      }
    }
    .panel-detail:last-child {
      border-right: none;
    }
  }
  dl.sh-event-paylocity-update > dd,
  dl.sh-event-data-update > dd {
    color: @fps-text-secondary;
    .value {
      color: @fps-text;
    }
  }
}

.stakeholder-info .info {
  padding: 15px;
}
.stakeholder-info .description {
  dd {
    font-weight: 600;
  }
  dt {
    color: @fps-text-secondary;
    font-weight: normal;
  }
}

.panel-detail.title {
  padding-left: 20px;
  display: flex;

  i {
    display: block;
    font-size: 25px;
    padding-right: 10px;
  }
  b.name {
    display: block;
  }
}
.stakeholder-events.headers {
  margin-bottom: 0;
  .panel-default {
    border-bottom: none;
  }
  .event-header {
    display: flex;
    background-color: @sb-gray-light !important;
  }
  .panel {
    margin-bottom: 0;
    background-color: @sb-gray-light;
  }
}
