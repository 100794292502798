/*
 * Role Specific Styles
 * The <body> tag has a current skin 'lens-${lens}' class on it.
 */

.lens-MyShoobx {
  .navbar-inner {
    border-color: @sb-myshoobx;
  }
}

.lens-Company {
  .navbar-inner {
    border-color: @sb-company;
  }
}

.lens-Board {
  .navbar-inner {
    border-color: @sb-board;
  }
}

.lens-Administration {
  .navbar-inner {
    border-color: @sb-administration;
  }
}

.lens-Counsel {
  .navbar-inner {
    border-color: @sb-counsel;
  }
}

.lens-Investor {
  .navbar-inner {
    border-color: @sb-investor;
  }
}
