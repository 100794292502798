/*
 * CSS Animations
 */

/*
 * Apply this class to any element you want to NOT
 * animate. Great for initial payload to prevent a
 * "flurry of animations" on page load.
 */
.animation-off {
  transition: none !important;
}

/*---[For all animations (rarely used directly)]----*/
.generic-animation(@duration) {
  & {
    transition: @duration ease all;
  }
}

/*----[Slide]---------*/
.slide-down-animation(@duration;
                      @end-height;
                      @end-padding-top: 0;
                      @end-padding-bottom: 0;
                      @end-border-top: 0;
                      @end-border-bottom: 0) {
  .generic-animation(@duration);
  &.ng-hide,
  &.ng-enter,
  &.ng-leave.ng-leave-active {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-top-width: 0;
    border-bottom-width: 0;
  }
  &.ng-enter.ng-enter-active,
  &.ng-leave {
    height: @end-height;
    padding-top: @end-padding-top;
    padding-bottom: @end-padding-bottom;
    border-top: @end-border-top;
    border-bottom: @end-border-bottom;
  }
}
.slide-right-animation(@duration;
                       @end-width;
                       @end-padding-right: 0;
                       @end-padding-left: 0;
                       @end-border-right: 0;
                       @end-border-left: 0) {
  .generic-animation(@duration);
  &.ng-hide,
  &.ng-leave.ng-leave-active,
  &.ng-enter {
    width: 0;
    padding-right: 0;
    padding-left: 0;
    border-right-width: 0;
    border-left-width: 0;
  }
  &.ng-enter.ng-enter-active,
  &.ng-leave {
    width: @end-width;
    padding-right: @end-padding-right;
    padding-left: @end-padding-left;
    border-right: @end-border-right;
    border-left: @end-border-left;
  }
}

/*----[Reveal (Fade In)]---------*/
.reveal-animation(@duration) {
  .generic-animation(@duration);
  &.ng-hide,
  &.ng-leave.ng-leave-active,
  &.ng-enter {
    opacity: 0;
  }
  &.ng-enter.ng-enter-active,
  &.ng-leave {
    opacity: 1;
  }
}
.reveal-inline-animation(@duration) {
  & {
    transition: @duration ease all;
    opacity: 0;
  }
  &.reveal-active {
    opacity: 1;
  }
}
.animate-reveal {
  .reveal-animation(0.2s);
}
.shake-animation {
  -webkit-animation: shake 300ms ease-in-out;
  animation: shake 300ms ease-in-out;
}

/* Keyframe Animations */
.keyframes(@name; @arguments) {
  @keyframes @name {
    @arguments();
  }
}
.keyframes(buttonglow; {
  50% {
    background-color: @fps-btn-primary-hover-background;
    color: @fps-btn-primary-hover-text;
    border-color: @fps-btn-primary-hover-border;
  }
});
.keyframes(shake; {
  0%    { transform: translateX(0); }
  12.5% { transform: translateX(-8px); }
  37.5% { transform: translateX(8px); }
  62.5% { transform: translateX(-4px); }
  87.5% { transform: translateX(4px); }
  100%  { transform: translateX(0); }
});

.keyframes(heartbeat; {
  0%  { transform: scale(1); }
  14% { transform: scale(1.3); }
  28% { transform: scale(1); }
  42% { transform: scale(1.3); }
  70% { transform: scale(1); }
});

.collapsible {
  transition: height 200ms;
}

.keyframes(rotation; {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
});
