.document-text() {
  font:
    16px Garamond,
    serif;
  line-height: 27px;
  color: @sb-gray-darker;
}
.anti-metadata-placeholder() {
  cursor: auto;
  background: none;
  box-shadow: none;
}

sb-select-resolutions {
  .init-loading {
    text-align: center;
    font-size: 30px;
    margin-bottom: 20px;
  }
  .dndDraggingSource {
    display: none !important;
  }
  .workitem-actions {
    margin-bottom: 20px;
  }
  .resolution-group-divider {
    margin: 40px 0;
    display: flex;
    align-items: center;
    main {
      padding: 0 7px;
      font-size: 19px;
      > span {
        color: @sb-green;
      }
    }
    > div:first-child,
    > div:last-child {
      height: 6px;
      background: @fps-group-background;
      flex: 1;
    }
  } // end .resolution-group-divider
}
sb-resolution-groups {
  @resolution-group-height: 110px;
  @resolution-group-border-width: 3px;
  @resolution-group-empty-border-width: 5px;
  @resolution-height: 25px;
  @resolution-border-width: 1px;
  ul {
    margin: 0;
  }
  *[draggable='true'] {
    cursor: move;
  }
  > ul {
    position: relative;
    min-height: @resolution-group-height;
  }
  > ul > li {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    border: @resolution-group-border-width solid @sb-gray-light;
    &:not(:last-child) {
      border-bottom: none;
    }
    padding: 20px;
    &.dndPlaceholder {
      background: #fff;
      height: @resolution-group-height;
      border: @resolution-group-border-width dashed @sb-gray-light !important;
    }
    > .move-indicator,
    .disabled.move-indicator {
      opacity: 0;
      transition: opacity 0.15s ease;
    }
    > .move-indicator {
      position: absolute;
      top: 5px;
      bottom: 5px;
      left: 5px;
    }
    &:hover > .move-indicator {
      opacity: 1;
    }
  }
  li.empty-row {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border: @resolution-group-empty-border-width dashed @sb-gray-light;
    height: @resolution-group-height;
    background: @fps-list-odd;
    line-height: @resolution-group-height;
    font-size: 25px;
    color: @fps-text-secondary;
    justify-content: center;
    text-align: center;
    > p {
      padding: 0;
      margin: 0;
    }
  }
  &.unapproved > ul {
    margin-bottom: 50px;
  }
  &.general-authority > ul {
    margin-top: 25px;
  }
  &.intro > ul > li:not(.empty-row),
  &.whereas > ul > li:not(.empty-row),
  &.approved > ul > li:not(.empty-row),
  &.general-authority > ul > li:not(.empty-row) {
    border-color: @fps-text;
  }
  .show-section {
    margin-bottom: 15px;
    margin-top: 15px;
  }
  section {
    .hide-section {
      margin-bottom: 15px;
    }
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    > header {
      display: flex;
      justify-content: space-between;
      height: 35px;
      > span {
        .document-text();
        font-size: 19px;
        font-weight: bold;
        padding-bottom: 13px;
        text-decoration: underline;
      }
      > div {
        display: flex;
      }
      span[sb-popover] {
        margin-right: 10px;
        font-weight: bold;
      }
    }
    .dndPlaceholder {
      height: 20px;
      border: 2px dashed @sb-gray-light;
    }
    .resolution {
      display: flex;
      margin: 0;
      margin-left: 13px;
      &:not(:last-child) {
        margin-bottom: 3px;
      }
      aside {
        height: 18px;
        margin: 2px 7px 0 0;
      }
      header {
        .document-text();
        font-weight: bold;
        padding-right: 8px;
      }
      main {
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        .document-text();
        p {
          margin: 0;
        }
        .metadata-placeholder,
        .dynamic-content {
          .anti-metadata-placeholder();
        }
      }
      footer {
        padding-left: 20px;
      }
    } // end section .resolution
  } // end .resolution
  .locked-group-message {
    font-style: italic;
  }
} // end sb-resolution-groups

sb-resolution-groups.whereas > ul,
sb-resolution-groups.intro > ul {
  margin-bottom: 25px;
}

.resolution-create-modal {
  sb-typeahead li {
    padding: 8px;
  }
  .exhibit-list li {
    .generic-animation(0.4s);
    &.ng-move {
      opacity: 0;
    }
    &.ng-move-active {
      opacity: 1;
    }
    a {
      margin-left: 10px;
    }
  }
}

.resolution-details-modal {
  .resolution-content {
    margin: 0;
    .document-text();
  }
  .modal-body h4 {
    margin-top: 20px;
  }
  .resolution-exhibits {
    margin: 0;
    li {
      display: flex;
      padding: 8px 16px;
      &:nth-child(2n - 1) {
        background: @fps-list-odd;
      }
      > span {
        color: @fps-text-secondary;
        padding-right: 16px;
        resize: horizontal;
        display: inline-block;
      }
      > div {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
      .exhibit-list-index {
        color: @fps-text;
        border-left: 1px solid @sb-gray;
        padding-left: 30px;
      }
    }
  }
  .metadata-placeholder,
  .dynamic-content {
    .anti-metadata-placeholder();
  }
}

.select-resolution-modal {
  .resolution-modal-header {
    padding-bottom: 20px;
  }
  .section-title {
    display: inline;
    padding-right: 15px;
  }
}
