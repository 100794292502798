.equity-wait {
  display: flex;
  justify-content: center;

  .equity-wait-container {
    padding-top: 30px;
    max-width: 800px;

    h4 {
      font-size: 20px;
      font-weight: normal;
      text-transform: none;
    }
    .spinner-container {
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 15px;
      padding-bottom: 10px;
      float: left;
    }
  }
}
