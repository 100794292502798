/* On change officers Show Page, we are dealing with a group of record tables
   in which, at the time of this code anyway, there could be either 3 or 4 entries. */

/* Presidents and secreaties do not have custom title cell, so adjust size
   to align as best we can with other officers table format. */

sb-record-table#change_corporate_officers-secretaries,
sb-record-table#change_corporate_officers-presidents {
  td {
    width: 50%;
  }
}
