/**
 * Review and Edit Documents Workitem
 */

.review-and-edit-workitem {
  min-height: 100px;
  @review-docs-ui-speed: 0.2s;
  .fa-highlighter {
    color: @sb-yellow;
  }
  .fa-flag {
    color: @sb-yellow;
  }
  .loading-indicator {
    .reveal-animation(@review-docs-ui-speed);
    text-align: center;
  }
  .panel-title {
    .doc-btns .doc-btn {
      margin-left: 5px;
    }
    .doc-btns .indicator i {
      vertical-align: middle;
    }
    .template-review {
      display: inline-block;
      text-decoration: none !important;
      cursor: default;
      margin-top: 3px;
      em {
        text-transform: none;
        font-weight: normal;
      }
    }
    a[disabled='disabled'] {
      text-decoration: none;
      cursor: default;
    }
    .text-muted {
      // in disabled accordion
      color: @fps-text;
    }
    .deferred-document {
      color: @fps-text-secondary;
      font-size: 15px;
    }
  }

  .panel .panel-heading h4.panel-title {
    text-transform: none;
  }
  .md-set {
    padding-bottom: 10px;
    padding-left: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    .md-section-removed {
      text-decoration: line-through;
    }
    .subsection {
      margin-left: 20px;
    }
  }
  .section-title {
    font-weight: bold;
  }
  .included-metadata {
    font-size: 14px;
    color: @sb-blue;
  }
  .field-row {
    .field-title {
      color: @fps-text-secondary;
    }
    margin-bottom: 5px;
    margin-top: 5px;
    > div {
      vertical-align: top;
    }
  }
  .subfield-row {
    clear: both;
    .field-title {
      padding: 0 0 0 35px;
    }
  }
  .field-value + .subfield-row,
  .field-rating + .subfield-row {
    padding-top: 5px;
  }
  .field-value > div {
    display: inline;
  }
  .field-edit-button {
    margin-left: 3px;
  }
  .metadata-container {
    margin-left: 28px;
  }
  .value-change-warning {
    margin-right: 5px;
  }
  .value-change-warning i.fa-check,
  i.fa-exclamation-triangle {
    margin-right: 3px;
  }
  .warning-icon-placeholder {
    margin-left: 19px;
  }
  .field-rating {
    text-align: right;
  }
  .collapse-caret:before {
    content: '\f0da';
    margin: 0 8px 0 3px;
  }
  .panel-open .collapse-caret:before {
    content: '\f0d7';
    text-decoration: none;
    margin: 0 7px 0 0;
  }
  .share-documents {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 22px;
    border: 1px solid @sb-gray-light;

    .shared-documents-item {
      padding-bottom: 15px;

      .shared-documents-stakeholder-card {
        width: 80%;
        display: inline-block;
      }
      .account-pending {
        display: block;
        padding-left: 37px;
      }
      .shared-documents-action {
        width: 20%;
        vertical-align: top;
        display: inline-block;
      }
    }

    .share-documents-icon {
      color: lighten(@sb-blue, 25%);
    }
  }

  div.review-documents-step {
    .step-icon {
      color: @sb-blue;
    }
  }

  hr.step-divider {
    color: @fps-text-secondary;
  }

  .custom-text-alert-icon {
    color: @sb-green-type;
  }
} // end .review-and-edit-workitem

/*** Edit Terms Modal ***/
.edit-terms-modal {
  @terms-ui-speed: 0.2s;
  .backlink {
    .reveal-animation(@terms-ui-speed);
    transition-property: opacity;
    text-align: right;
    margin-right: 10px;
  }
  .modal-body {
    position: relative;
  }
  .outline-wrap ul {
    li {
      @section-indent: 30px;
      @section-caret-width: 18px;
      overflow: hidden;
      padding: 5px 10px 5px @section-indent;
      border: 1px solid @sb-gray-lighter;
      &:not(:last-child) {
        border-bottom: 0;
      }
      > .caret {
        border-width: 6px 6px 0 6px;
        cursor: pointer;
        & + a {
          margin-left: 3px;
        }
      }
      &.section-closed > .caret {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }
      &.section-collapsed {
        height: 0;
        padding: 0;
        border: none;
      }
      &.section-removed {
        text-decoration: line-through;
        opacity: 0.5;
        button.btn-link.title {
          text-decoration: line-through;
        }
      }
      &.section-hidden {
        opacity: 0.5;
        a.title {
          text-decoration: line-through;
        }
      }
      &.section-isInOutline {
        display: none;
      }
      .sections-generate-levels(@n, @i: 0) when (@i =< @n) {
        @cur-section-indent: @section-indent * (@i + 1);
        &.section-level-@{i} {
          padding-left: @cur-section-indent;
          &.section-has-subsections {
            padding-left: @cur-section-indent - @section-caret-width;
          }
        }
        .sections-generate-levels(@n, @i + 1);
      }
      .sections-generate-levels(10);
    }
    a,
    span {
      font-weight: 600;
    }
    i.custom-marker {
      color: @fps-text-secondary;
      margin-left: 5px;
    }
    span.terms {
      color: @fps-text-secondary;
      font-size: 12px;
    }
    span.ref {
      color: @fps-text-secondary;
      font-size: 90%;
    }
    button.btn-link.title {
      text-transform: none;
    }
  }
  .terms-outline {
    .generic-animation(0.2s);
    position: relative;
  }
  .document-title {
    margin-top: 0;
    font-size: 18px;
  }
  .section-title {
    margin-top: 0;

    .edit-section-info {
      margin-left: 5px;
      vertical-align: text-bottom;
    }
  }
  .richtext-section-header {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;

    p {
      margin-bottom: 20px;
      flex: 1;
    }
  }
  .section-buttons {
    display: flex;
    flex-direction: row-reverse;
    flex: 1;

    .btn {
      margin-left: 5px;
    }
  }
  .section-preview-separator {
    background: @sb-gray;
    width: 100%;
    height: 1px;
    margin: 20px 0;
  }
  .form-group {
    margin-left: 0;
    margin-right: 0;
  }
  .section-preview {
    position: relative;
  }
  .sub-section-edit-link {
    color: @sb-green-type;
    cursor: pointer;
    &:hover {
      color: @sb-green-bright;
    }
  }
  .loading-preview {
    .reveal-animation(@terms-ui-speed);
    background: #fff;
    opacity: 0.7;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .spinner-loading-preview {
    .reveal-animation(@terms-ui-speed);
    color: @fps-text;
    font-size: 26px;
    font-weight: 600;
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .revisions-navigator {
    padding: 10px 30px;
    background-color: rgba(@sb-yellow, 0.3);
  }
  .formatted-title {
    display: inline-block;
    font-size: larger;
    // Hide BRs since they make the title height too large.
    br {
      content: ' ';
    }
    br:after {
      content: ' ';
    }
  }
  .formatted-title-edit-button {
    vertical-align: top;
  }

  // Custom record layout required due to smaller modal space
  sb-dictionary {
    .iterable-row {
      align-items: center;
      flex-wrap: wrap;

      .dict-key {
        flex: 0.2;
        padding-right: 0;
        max-width: unset;
      }

      .iter-widget-colon {
        min-width: 80%;
        flex: 1;
        align-items: flex-start;
        flex-direction: column;
      }

      .dict-value {
        width: 100%;
        max-width: unset;
      }

      sb-record formly-field > .form-group {
        padding-left: 0;
      }
    }
  }

  .btn-link.show-revision {
    margin-left: 10px;
    margin-top: 8px;
  }

  .value-change-warning {
    margin-top: 5px;
    margin-left: 10px;
  }
} // end .edit-terms-modal

/*** large-diff-modal.html ***/
.large-diff-modal {
  .spinner {
    padding: 50px;
    text-align: center;
    opacity: 0.3;
  }

  .section-description {
    margin-bottom: 20px;
    color: @sb-blue;
    font-size: 14px;
    font-weight: bold;
  }

  .revision-container {
    &.document-info {
      padding-bottom: 20px;
      border-bottom: @sb-gray 1px solid;
    }
    hr {
      color: @fps-text-secondary;
    }
    margin-bottom: 20px;
  }

  .revision-info {
    color: @fps-text-secondary;
  }

  .revision-info-header {
    font-size: 14px;
    color: @fps-text-secondary;
  }
} // end .large-diff-modal

/**
 * DEPRECATED Less -- please use above.
 */
#review-documents {
  position: relative;

  h3 {
    margin-bottom: 0;
  }

  .doc-buttons {
    position: absolute;
    right: 0;
  }

  .mdset-title {
    font-weight: bold;
    margin: 5px 0px;
  }

  .reference-title {
    margin: 5px 0px;
  }

  .field-row {
    margin-bottom: 5px;
  }
  .field-row > div {
    vertical-align: top;
    display: inline-block;
  }

  .field-row .field-title {
    width: 300px;
  }

  .field-row .field-value {
    padding-left: 20px;
    width: 300px;
  }

  .field-row .field-rating {
    float: right;
  }

  .subfield-row {
    .field-title {
      padding-left: 30px;
      width: 300px;
    }
    .field-value {
      padding-left: 50px;
      width: 300px;
    }
  }

  .term-set > div {
    display: inline-block;
  }

  .term-set .term-title {
    font-weight: normal;
    font-weight: bold;
    width: 150px;
  }

  .term-set .percent-standard:after {
    content: '%';
  }
}

.doc-details {
  border-bottom: 1px solid #999;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

#form-widgets-rejection_reason {
  width: 400px;
  height: 150px;
}

.rejection-reason {
  margin-bottom: 20px;

  .alert {
    margin-bottom: 10px;
  }

  .msg {
    font-weight: bold;
    padding-left: 10px;
  }
}

#review-documents .panel-title .accordion-toggle:before {
  display: inline-block;
  content: '\f0d7';
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
  text-decoration: none;
  margin: 0px 7px 0px 2px;
}

#review-documents .panel-title .accordion-toggle.collapsed:before {
  content: '\f0da';
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
  margin: 0px 8px 0px 4px;
}

.md-set .field-value table {
  font-weight: normal;
}

.fa-flag {
  color: @sb-yellow;
}

.fa-highlighter {
  color: @sb-yellow;
}
.fa-check {
  color: @sb-green;
}

.share-documents-modal {
  sb-field-label {
    font-size: 18px;
    font-weight: normal;
  }

  div.share-documents-modal-text {
    margin-left: 15px;
    margin-right: 15px;
    padding-bottom: 10px;
    padding-top: 5px;
  }

  p.share-documents-modal-text {
    font-weight: normal;
  }
}

.edit-email-recipients-tooltip {
  h4 {
    font-size: 14px;
    color: @fps-text-secondary;
  }
  .email-recipient {
    margin: 14px 0 0 0;
  }
}

.edit-email-modal {
  .recipients-tooltip-icon {
    color: @sb-blue;
    .pull-left.fas {
      margin: 4px;
    }
  }
  .form-group:first-child {
    border-top: 2px solid @sb-gray-lighter;
  }
  .form-group.email-recipients-field {
    border-top: none !important;
  }
}

.title-editor-info {
  padding-top: 10px;

  div {
    top: -7px;
  }
}

.reset-title-to-template {
  position: absolute;
  z-index: 999;
  right: 13px;
  top: 73px;
}

.title-editor-footer {
  margin-top: 30px !important;
}

.title-editor {
  max-height: 120px;
  padding: 8px;

  &.rml-content p {
    font-weight: bold;
    font-size: 14pt;
    leading: 16pt;
    text-align: center;
    margin-bottom: 14pt;
  }

  .ck.ck-content {
    max-height: 100px !important;
    height: 100px !important;
  }
}

// XXX: This should be refactored into the logic to sbx-modal component
.modal.custom-modal {
  z-index: 1060 !important;
}

.modal-backdrop.custom-modal-backdrop {
  z-index: 1060 !important;
}

.modal.custom-modal ~ .sbx-select-typeahead-window {
  z-index: 1060 !important;
}
