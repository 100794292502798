@import '../thirdparty/bs3-custom-vars.less';

.sb-hris-upload {
  .details {
    margin: 0 24px 22px 0;
  }

  .item {
    border-bottom: solid #bec9c9 1px;
    padding: 22px 0;
    display: flex;
    justify-content: space-between;

    sbx-button {
      padding-right: 10px;
    }
  }

  .item-title {
    color: @fps-text;
    font-weight: 600;
  }
}
