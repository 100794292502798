//board styles

.content-padding {
  padding: 15px;
}

.board-info-title {
  .mixed-case-heading();
  margin-top: 0;
}

.btn-noborder {
  border: none;
}

#board-meeting .heading {
  margin: 0 -15px;
  padding: 0 15px;

  h1 {
    display: inline-block;
    margin: 15px 0 10px 0;
  }
  dl {
    display: inline-block;
    margin: 0;
    dt {
      display: inline-block;
      font-weight: normal;
      text-transform: uppercase;
      font-size: 12px;
      color: @fps-text-secondary;
      line-height: 0.9em;
      font-weight: 600;
      padding: 0 5px 0 10px;
    }
    dd {
      display: inline-block;
      &:not(:last-child) {
        padding-right: 10px;
        border-right: 1px solid @sb-gray-light;
        line-height: 100%;
      }
    }
  }
}

.meeting {
  display: inline-block;
  border: 3px solid @sb-gray-lighter;
  height: 140px;
  width: 100%;
  color: @fps-text;
  padding-top: 10px;
  padding-left: 20px;
  margin-top: 30px;
  margin-right: 0px;
  float: left;
  .meeting-date {
    display: inline-block;
    max-width: 90px;
    height: 90px !important;
    max-height: 90px !important;
    margin-right: 0px;
    .future {
      .month {
        color: black;
      }
      background-color: @sb-green;
      height: 90px !important;
      width: 90px !important;
      color: white;
      text-align: center;
      vertical-align: middle;
    }
    .past {
      .month {
        color: black;
      }
      background-color: @sb-gray-light;
      height: 90px !important;
      width: 90px !important;
      color: white;
      text-align: center;
      vertical-align: middle;
    }
    .tbd {
      height: 90px !important;
      width: 90px !important;
      min-height: 90px !important;
      max-height: 90px !important;
      color: white;
      text-align: center;
      vertical-align: middle;
      background-color: @fps-box-background;
      color: @fps-text-secondary;
      clear: both;
      display: inline-block;
    }
    .tbd span {
      font-size: 34px;
      font-weight: 300;
      text-align: center;
      vertical-align: middle;
      position: relative;
      top: 20px;
    }
  }
  .unscheduled {
    margin-left: 20px;
    font-style: italic;
    display: inline-block;
    color: @fps-text-secondary;
  }
}
