sb-breadcrumb {
  .right-arrow() {
    content: '\f054';
    font-family: 'Font Awesome 6 Free';
    font-weight: 900;
    font-size: @fontSize - 5;
    padding: 0 8px 0 8px;
    color: @fps-text-secondary;
  }
  @fontSize: 16px;
  font-size: @fontSize;
  > a {
    display: inline-block;
    overflow: hidden;
  }
  a {
    text-decoration: none !important;
  }
  a.disabled {
    cursor: default;
    color: @fps-text-secondary;
  }
  a.notlast::after {
    .right-arrow();
  }
  span {
    margin-right: -3px;
    display: inline-block;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    direction: rtl;
    a::after {
      .right-arrow();
      pointer-events: none;
    }
  }
}
