sb-signature-capture {
  display: block;
  position: fixed;
  top: 55px;
  bottom: 75px;
  left: 0;
  right: 0;
  p {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .signature-container {
    position: relative;
    width: 90%;
    height: 80%;
    background-color: lightgray;
    margin: 0 auto;
    border-radius: 3px;
    canvas {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 10;
    }
    &::after {
      content: '';
      z-index: 9; // lower than canvas
      display: block;
      position: absolute;
      transform: translate(-50%, 0);
      width: 80%;
      bottom: 20px;
      left: 50%;
      background-color: gray;
      height: 3px;
    }
  }
}
