@import '~bootstrap/less/mixins.less';

/*
 * Everything Process
 */

// Quick referances:
@nav-bar-height: 50px;
@footer-bar-height: 25px;
@button-height: 13px + 10px; // 13 text, 10px of padding

// Process UI Computes / Constants
@process-header-h1-height: 26px;
@process-header-no-summary-height: @process-header-h1-height + 20px + 1px; // 15px + 5px padding, 1px border-bottom
@process-header-summary-height: 36px;
@process-header-with-summary-height: @process-header-summary-height +
  @process-header-no-summary-height;
@process-status-height: 35px;
@process-ui-speed: 0.5s;
@process-footer-height: @button-height + 20px + 1px; // button + padding + border
@process-left-form-width: 270px;
@process-left-form-border-width: 5px;
@process-left-form-side-padding: 15px;
@process-modal-header-height: 60px;

/*-----[Standard Process View]--------------------------------------*/
.process {
  .tooltip .tooltip-inner {
    min-width: 300px;
  }
  .tooltip {
    z-index: @z-index-4;
    min-width: 300px;
  }
  > header {
    left: 0;
    width: 100%;
    position: fixed;
    z-index: @z-index-2;
    > .container .row {
      border-bottom: 1px solid #fff;
      background: #fff;
      margin-right: 0;
      margin-left: 0;
      h1 {
        height: @process-header-h1-height;
        margin: 15px 15px 5px;
      }
    }
  }
  header.process-seperator > .container .row {
    border-bottom: 1px solid @sb-gray;
  }
  .process-fourohfour {
    .reveal-animation(@process-ui-speed / 2);
  }
  .process-main,
  .process-main-include {
    padding: 0 15px;
  }
  .left-sidebar-form-wrap {
    float: left;
    width: @process-left-form-width;
  }
  .left-sidebar-form {
    list-style: none;
    margin: 0 0 0 -15px;
    padding: @process-left-form-side-padding;
    border-left: @process-left-form-border-width solid @sb-orange-type;
    background: @fps-box-background;
    width: @process-left-form-width;
    h4 {
      position: relative;
    }
    .loading-indicator {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
    }
    h4,
    label {
      margin: 0;
      padding: 0;
      display: block;
    }
    label {
      padding-bottom: 5px;
    }
    .error label {
      color: @fps-text;
    }
    > li {
      clear: both;
      display: block;
      border-bottom: 2px solid #fff;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
    > li:last-child {
      border: none;
      margin: 0;
      padding: 0;
    }
    .input-addon {
      display: block;
      padding: 7px 15px 6px;
      background: @sb-gray-light;
    }
    .currency {
      .input-addon {
        float: left;
        width: 38px;
      }
      input {
        width: 196px;
        margin-left: 38px;
      }
    }
    .measurement {
      .input-addon {
        margin-left: 185px;
        width: 45px;
      }
      input {
        width: 185px;
        float: left;
      }
    }
    input,
    .input-addon {
      .generic-animation(@process-ui-speed);
    }
    .form-dropdown {
      width: 230px;
    }
    .error input {
      border-color: @sb-red-type;
    }
  } // end .left-sidebar-form
  .right-content-for-left-form {
    margin-left: @process-left-form-width;
    padding: 0 0 50px 25px;
    overflow-y: auto;
    overflow-x: hidden;
  } // end .right-content-for-left-form
  > aside,
  > form {
    .generic-animation(@process-ui-speed);
  }
  > form {
    padding: (@process-header-no-summary-height + 10px) 0
      (@process-footer-height + 10px);
    .wi-loading-indicator {
      .reveal-animation(@process-ui-speed);
      position: relative;
      top: -35px;
      float: right;
      width: 16px;
      margin-bottom: -35px;
    }
  }
  > form.process-includes-summary {
    padding-top: @process-header-with-summary-height + 10px;
    .counsel-settings-wi .top-panel {
      top: 132px !important;
    }
  }
  > form.process-includes-status {
    padding-top: @process-header-no-summary-height + @process-status-height + 10px;
  }
  > form.process-includes-status.process-includes-summary {
    padding-top: @process-header-with-summary-height + @process-status-height + 10px;
  }
  > form.form-with-aside {
    margin-left: 207px;
  }
  > aside {
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    bottom: @process-footer-height + @footer-bar-height;
    padding-top: 10px;
    top: @process-header-no-summary-height + @nav-bar-height;
    border-right: 3px dotted @sb-gray-light;
    padding-right: 15px;
    width: 210px;
    .slide-right-animation(@process-ui-speed,
                           207px,
                           15px, 0,
                           3px dotted @sb-gray-light, 0);
  }
  .label {
    background-color: @fps-box-header-background;
    color: @sb-green;
    font-weight: bold !important;
    font-size: 13px;
    margin-left: 15px;
  }
  .process-loading {
    position: fixed;
    top: @process-header-no-summary-height + @nav-bar-height;
    bottom: @process-footer-height + @footer-bar-height;
    left: 0;
    z-index: @z-index-10;
    width: 100%;
    > .container,
    > .container .row {
      height: 100%;
    }
    > .container .row {
      margin-right: 0;
      margin-left: 0;
      font-size: 28px;
      text-align: center;
      background: rgba(255, 255, 255, 0.7);
      display: table;
      width: 100%;
    }
    p {
      width: 70%;
      display: table-cell;
      vertical-align: middle;
      .reveal-animation(@process-ui-speed / 2);
    }
    .reveal-animation(@process-ui-speed);
    &.workitem-completed > .container .row {
      background: fade(@fps-text, 40%);
    }
    .unclaimed-warning {
      transition: none;
      color: #fff;
      .btn {
        font-size: 20px;
        padding: 10px 20px;
      }
    }
  } // end .process-loading
  .process-loading.process-includes-summary,
  > aside.process-includes-summary {
    top: @process-header-with-summary-height + @nav-bar-height;
  }
  .process-loading.process-includes-status,
  > aside.process-includes-status {
    top: @process-header-no-summary-height + @nav-bar-height + @process-status-height;
  }
  .process-loading.process-includes-summary.process-includes-status,
  > aside.process-includes-summary.process-includes-status {
    top: @process-header-with-summary-height + @nav-bar-height + @process-status-height;
  }
  &.bare-process {
    > footer {
      bottom: 0;
    }
    > form {
      padding-top: @process-header-no-summary-height - 10px - @process-header-h1-height +
        @process-modal-header-height;
    }
    > form.process-includes-summary {
      padding-top: @process-header-with-summary-height - 10px -
        @process-header-h1-height + @process-modal-header-height;
    }
    > form.process-includes-status {
      padding-top: @process-header-no-summary-height + @process-status-height - 10px -
        @process-header-h1-height + @process-modal-header-height;
    }
    > form.process-includes-status.process-includes-summary {
      padding-top: @process-header-with-summary-height + @process-status-height - 10px -
        @process-header-h1-height + @process-modal-header-height;
    }
    @bare-process-extra: 20px;
    .process-loading,
    > aside {
      top: @process-header-no-summary-height - @process-header-h1-height -
        @bare-process-extra + @process-modal-header-height;
      bottom: @process-footer-height;
    }
    .process-loading.process-includes-summary,
    > aside.process-includes-summary {
      top: @process-header-with-summary-height - @process-header-h1-height -
        @bare-process-extra + @process-modal-header-height;
    }
    .process-loading.process-includes-status,
    > aside.process-includes-status {
      top: @process-header-no-summary-height + @process-status-height -
        @process-header-h1-height - @bare-process-extra + @process-modal-header-height;
    }
    .process-loading.process-includes-summary.process-includes-status,
    > aside.process-includes-summary.process-includes-status {
      top: @process-header-with-summary-height + @process-status-height -
        @process-header-h1-height - @bare-process-extra + @process-modal-header-height;
    }
  }
  > footer {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: @footer-bar-height;
    z-index: @z-index-4;
    pointer-events: none;
    [sb-scroll-not-at-bottom] {
      display: block;
      height: 50px;
      width: 100%;
      background: -webkit-linear-gradient(
        right,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0) 100%
      );
      background: -moz-linear-gradient(
        right,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0) 100%
      );
      background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 100%);
      text-align: center;
      font-size: 20px;
      color: @sb-green-type;
      padding-top: 18px;
      .fa,
      .fas,
      .far,
      .fab {
        cursor: pointer;
        pointer-events: auto;
      }
    }
  } // end > footer
  .panel-body {
    > .row {
      padding-top: 8px;
      padding-bottom: 8px;
      border-top: 2px solid @sb-gray-lighter;
      .label {
        float: left;
        width: 25%;
        padding: 0 15px;
        margin: 0;
        display: block;
        background: none;
        color: @fps-text;
        font-size: 16px;
        text-align: left;
      }
      .widget {
        float: left;
        display: block;
        width: 75%;
        padding: 0 15px;
      }
    }
  }
} // end .process

/*----[Standard WI Components]--------------------------------------*/
.divider {
  height: 5px;
  background-color: @sb-gray-lighter;
  text-align: center;
  margin: 15px 0 12px;
  overflow: visible; // IE sucks.
}
.or-divider {
  height: 5px;
  background-color: @sb-gray-lighter;
  text-align: center;
  margin: 15px 0 12px;
  overflow: visible; // IE sucks.
}
.or-divider:before {
  content: 'OR';
  color: @sb-green;
  padding: 0 5px;
  position: relative;
  top: -12px;
  font-size: 20px;
  background-color: @sb-white;
}
hr.strict-divider {
  height: 1px;
  background-color: @sb-gray;
  margin: 15px 0 12px;
  overflow: visible; // IE sucks.
}

.no-title-workitem {
  header.process-seperator > .container .row {
    border: none;
    border-bottom: 0px;
  }
  header h1,
  header h2 {
    display: none;
  }
  .process-loading {
    top: @process-header-no-summary-height;
  }

  .process-loading.process-includes-status,
  > aside.process-includes-status {
    top: @nav-bar-height + @process-status-height;
  }
}

.workitem-section-title {
  margin: 0 15px 11px;
  font-size: 25px;
  font-weight: 300;
  text-transform: none;
}
.workitem-details label {
  font-weight: normal !important;
  padding-top: 0;
}
.workitem-details .separator {
  margin-top: 13px;
}
.workitem-main-option {
  margin-bottom: 0;
  margin-top: 8px;
  width: 100%;
  h4 {
    margin: 0 0 11px 30px;
    color: @sb-gray-darker;
    font-size: 20px;
    &.workitem-text-disabled {
      color: @fps-text-secondary;
    }
  }
}

.workitem-text-disabled {
  color: @fps-text-secondary;
}

.workitem-main-option .checkradios-checkbox,
.workitem-main-option .checkradios-radio {
  float: left;
  margin-top: 4px;
}
.workitem-details {
  clear: both;
  margin-left: 30px;
  &.extra-pad {
    margin-left: 75px;
  }
}
.workitem-details label {
  display: block;
  clear: both;
}

.workitem-details .checkbox-wrapper {
  display: flex;

  .field-help {
    margin-left: 10px;
  }
}

.process-progress {
  @circle-diameter: 40px;
  @bar-height: 6px;

  background-color: @sb-gray-steps;
  padding: 12pt;

  ol {
    .clearfix();
    margin: 24pt 0 0 0;
    padding: 0;
    clear: both;
    counter-reset: progress;

    li {
      .nr {
        display: block;
        font-size: 20pt;
        font-weight: bold;
        background-color: @sb-gray;
        position: relative;
        text-align: center;
        color: white;
        width: @circle-diameter;
        height: @circle-diameter;
        margin: 0 auto 12pt auto;
        border-radius: 50%;
        box-shadow: inset 0px 2px 2px darken(@sb-gray, 10%);
      }
      .nr:before {
        counter-increment: progress;
        content: counter(progress);
      }

      .bar {
        height: @bar-height;
        position: relative;
        left: 50%;
        top: (@circle-diameter / 2) + (@bar-height / 2);
        background-color: @sb-gray;
        box-shadow: inset 0px 2px 2px darken(@sb-gray, 10%);
      }

      &:last-child .bar {
        visibility: hidden;
      }

      text-transform: uppercase;
      display: block;
      width: 160px;
      float: left;
      text-align: center;

      &.status-current .nr {
        background-color: @sb-green;
        box-shadow: inset 0px 2px 2px darken(@sb-green, 10%);
      }
      &.status-completed .nr {
        background-color: @sb-blue;
        box-shadow: inset 0px 2px 2px darken(@sb-blue, 10%);
      }
      &.status-completed .bar {
        background-color: @sb-blue;
        box-shadow: inset 0px 2px 2px darken(@sb-blue, 10%);
      }
    }
  }
}

/*-----[Outstanding Process View]--------------------------------------*/
.outstanding-wi {
  header {
    padding-bottom: 50px;
  }
  table {
    width: 100%;
  }
  .wi-link {
    display: block;
  }
  .action-row {
    border-bottom: 1px dashed @sb-gray-light;
  }
  .actions {
    float: none;
    margin: 0 auto;
  }
  th {
    border-right: 1px solid @sb-gray-light;
    width: 60px;
    padding-right: 10px;
    padding-left: 10px;
  }
  td {
    padding: 15px;
  }
  .action-row:last-child {
    border-bottom: none;
  }
  .spinner {
    padding: 50px;
    text-align: center;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 5px;
    }
  }
}

sb-wait-workitem-info {
  display: block;
  margin-top: 10px;
  padding: 10px 0;
  > ul {
    margin-bottom: -8px;
  }
  li {
    margin-bottom: 8px;
  }
  li > * {
    display: flex;
    > * {
      padding: 0 10px 0 0;
      &:last-child {
        flex: 1 1;
      }
    }
  }
  ul {
    margin-left: 20px;
  }
}

sb-process-status-widget {
  sb-wait-workitem-info {
    border-top: 1px solid #fff;
    &:nth-child(2n) {
      border-top: 1px solid @sb-gray-light;
    }
  }
  .modal-header {
    .process-actions {
      float: right;
      margin-left: 20px;
      margin-right: 20px;
      .btn:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  .modal-body:not(.text-center) {
    flex-direction: column;
    display: flex;
    > div {
      display: flex;
      align-items: flex-start;
      gap: 10px;
    }
    sb-alert-banner {
      flex: 1;
    }
    > div > .right-col > div:first-child h4,
    > div > .left-col {
      flex: 1 0;
    }
    > div > .left-col {
      max-height: 440px;
      display: flex;
      flex-direction: column;
      .you-specifier {
        text-transform: uppercase;
        font-weight: bold;
      }
      > h5 {
        margin: 0 0 10px 0;
        padding: 0 0 10px 10px;
        border-bottom: 3px solid @sb-gray-light;
        color: @fps-text;
        font-size: 15px;
        text-transform: none;
        > span {
          text-transform: uppercase;
        }
        sbx-stakeholder-card {
          display: inline-flex;
          margin: 0 10px;
        }
      }
    }
    > div > .right-col {
      width: 340px;
      border: 3px solid @sb-gray-light;
      height: 440px;
      display: flex;
      flex-direction: column;
      .nav-tabs {
        background: @fps-text;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        li:last-child a,
        li:last-child {
          margin-right: 0;
        }
      }
      .tab-content ul {
        height: 384px;
        margin-bottom: 0;
        overflow: auto;
        header {
          display: break;
          font-weight: bold;
          font-size: 14px;
          margin-bottom: 5px;
        }
        li {
          padding: 10px 15px;
          word-wrap: break-word;
          *[ng-bind-html] {
            .fa,
            .fas,
            .far,
            .fab {
              margin-right: 5px;
            }
          }
        }
        li:first-child {
          padding-top: 0;
        }
        li:nth-child(2n) {
          background: @sb-gray-lighter;
        }
      }
    }
  }
}

sb-workitem-status-list {
  overflow-y: auto;
  flex-grow: 1;
  ul {
    margin-bottom: 0;
  }
  ul > li {
    padding: 10px;
    ul > li {
      padding: 0;
    }
    header {
      display: flex;
      .stakeholders {
        display: flex;
        flex-direction: column;
        > span {
          display: flex;
          &:not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }
      > * {
        flex: 1 1;
        margin-right: 15px;
        padding-right: 15px;
        border-right: 1px solid @sb-gray-light;
      }
      > *:first-child,
      > *:last-child {
        flex: none;
        text-align: center;
      }
      > *:first-child {
        min-width: 27px;
      }
      > *:last-child {
        margin-right: 0;
        padding-right: 0;
        border: none;
        min-width: 81px;
      }
    }
    &:nth-child(2n) {
      background: @fps-list-odd;
    }
  }
}

.process-modal {
  .modal-dialog {
    height: calc(100vh - 60px);
    width: 1100px;
    max-width: 100%;
    .modal-content {
      height: 100%;
    }
  }
  .modal-header {
    min-height: 60px;
  }
  .modal-body {
    height: calc(100% - 60px);
    padding: 0;
  }
  .process-frame {
    border: none;
    height: 100%;
    min-height: 350px;
    width: 100%;
    header {
      display: none !important;
    }
  }
}
