/*
 * Styles for signatures
 */

.help-pointer {
  cursor: pointer;
  color: @fps-text-secondary;
}

div.sample_signature {
  text-align: center;
  img {
    max-height: 140px;
  }

  .signature {
    display: inline;
    img {
      max-height: 120px;
    }
  }

  .qr_code {
    display: inline;
  }
  .signature_block {
    text-align: left;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    border: @sb-gray-light 1px solid;
    padding: 15px;
  }

  .uuid {
    text-align: left;
  }
}

div.signature-help {
  .img-responsive {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

div.signature-preview-modal {
  display: flex;
  div.signature-review div.sig-top {
    height: auto;
  }
  div.signature-review {
    height: auto;
  }
}

.sig-preview-body {
  padding-bottom: 5px;

  button {
    margin: 0;
  }
  .fa-question {
    margin-top: -15px;
  }
  button.signature-help {
    margin: 0 !important;
  }
  button.btn-primary {
    margin: 0px 15% 0 0;
  }
}

div.signatures .signature {
  display: inline-block;
  width: 300px;
  vertical-align: top;
}

div.signatures .sig_title {
  font-weight: bold;
  display: block;
}

#signature-table td {
  float: left;
}

.sig-preview-body .signature-preview div.signature-review {
  div.sig-top {
    height: auto;
    display: block;
    font-size: 15px;
    font-family: 'Garamond';
    padding-bottom: 5px;
  }
  #sigPreviewTitle {
    padding: 5px 5px;
    margin-bottom: 10px !important;
    width: 100%;
  }
  textarea {
    width: 100%;
    margin-bottom: 10px;
    padding-left: 5px;
    margin-left: -5px;
  }
}

div.signature-review {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  height: 200px;

  div.sig-top {
    height: auto;
    min-height: 40px;
    display: block;
    font-size: 15px;
    font-family: 'Garamond';
    padding-bottom: 5px;
  }

  .sig-details {
    position: relative;
    margin: 0px;
    max-width: 345px;
    height: 30px;
    padding-top: 4px;
    .sig-details-left {
      display: inline-block;
      width: 300px;
      img {
        max-height: 85px;
      }
    }
    .sig-icon {
      color: @sb-white;
    }
    .sig-details-right {
      display: inline-block;
      width: 85px;
      height: 85px;
      background-repeat: no-repeat;
      background-size: 85px;
      margin: 0;
      position: absolute;
      top: 0px;
      right: -7px;
    }

    .signed-in-text,
    .sig-small-text-right,
    .sig-ip {
      position: absolute;
      bottom: 5px;
    }
    .signed-in-text {
      float: left;
      padding-top: 4px;
    }
    .sig-ip {
      right: 0;
    }
  }

  .sig-main {
    position: relative;
    border-top: 2px solid @sb-gray-light;
    border-bottom: 2px solid @sb-gray-light;
    margin: 0px;
    max-width: 345px;
    height: 90px;
    padding-top: 4px;
    img {
      display: inline-block;
      vertical-align: bottom;
      padding-bottom: 6px;
    }
    .sig-byword {
      font-family: 'Garamond';
      display: inline-block;
      vertical-align: bottom;
      padding-bottom: 6px;
    }
    .sig-main-left {
      display: inline-block;
      width: 300px;
      img {
        position: absolute;
        max-height: 85px;
      }
    }
    .sig-icon {
      color: @sb-white;
    }
    .sig-main-right {
      display: inline-block;
      width: 85px;
      height: 85px;
      background-repeat: no-repeat;
      background-size: 85px;
      margin: 0;
      position: absolute;
      top: 0px;
      right: -7px;

      &.shoobx-logo {
        color: #e0e0e0;
        font-family: Shoobx;
        font-size: 80px;
        right: -5px;
        top: -15px;
      }
    }
  }

  div.sig-bottom {
    padding-top: 9px;
    padding-bottom: 10px;
    font-size: 15px;
    font-family: 'Garamond';
    line-height: 120%;
  }

  .sig-small-text {
    font-size: 10px;
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  }

  .sig-icon {
    color: @sb-white;
  }
}
