@import 'thirdparty/bs3-custom-vars.less';

.sb-exercise-purchase-summary {
  .details {
    margin: 0 24px 22px 0;
  }

  .details > div {
    border-bottom: solid #bec9c9 1px;
    padding: 11px 0 11px 0;
    display: flex;
    &:last-child {
      border: none;
    }
  }

  .item-title {
    flex-basis: 40%;
    text-transform: uppercase;
  }

  .last-item-title {
    font-weight: bold;
    flex-basis: 40%;
    text-transform: uppercase;
  }

  .item-description {
    font-weight: bold;
    flex-basis: 60%;

    .minus {
      color: @sb-red;
    }
  }
}
