@import 'thirdparty/bs3-custom-vars.less';

.rml-content {
  font-family: Garamond;
  font-size: 12pt;
  line-height: 1.3em;
}

.rml-content table {
  width: 100%;

  td {
    padding: 5px;
  }
}

.rml-content p {
  text-align: justify;
}

.rml-content h1 {
  font-weight: bold;
  font-size: 100%;
  line-height: 2em;
  text-align: center;
  text-transform: inherit;
}

.rml-content h2 {
  font-weight: bold;
  font-size: 12pt;
  font-style: italic;
}

.rml-content h3 {
  font-weight: bold;
  font-style: italic;
  font-size: 80%;
}

.rml-content h4 {
  font-weight: bold;
  font-style: italic;
  font-size: 70%;
}

.rml-content h5 {
  font-weight: bold;
  font-size: 70%;
}

.rml-content h6 {
  font-weight: bold;
  font-size: 60%;
}

.rml-content div.spacer {
  line-height: 0;
}

.rml-content .Normal {
  font-size: 12pt;
}

.rml-content .Small {
  font-size: 8pt;
}

.rml-content .SmallLeft {
  font-size: 8pt;
  text-align: left;
}

.rml-content .IndentOne {
  text-indent: 0.5in;
}

.rml-content .IndentTwo {
  text-indent: 1in;
}

.rml-content .Indent {
  padding-left: 0.75in;
}

.rml-content .IndentHalf {
  padding-left: 0.25in;
}

.rml-content .ResolutionIndent {
  padding-left: 80pt;
}

.rml-content .ResolutionsGroupTitle {
  padding-left: 80pt;
  font-weight: bold;
  text-decoration: underline;
}

.rml-content .ResolutionsTherefore {
  padding-left: 80pt;
  font-weight: bold;
  text-transform: uppercase;
}

.rml-content .Right {
  text-align: right;
  display: block;
}

.rml-content .Left {
  text-align: left;
  display: block;
}
.rml-content .Center {
  text-align: center;
  display: block;
}

.rml-content .HalfSpaceAfter {
  padding-bottom: 6pt;
}

.rml-content .QuarterSpaceAfter {
  padding-bottom: 3pt;
}

.rml-content .NoSpaceAfter {
  padding-bottom: 0;
}

.rml-content .BlankMessage {
  font-style: italic;
  text-align: center;
}

.rml-content .MultiHeading {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.rml-content .ExhibitTitle {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.rml-content .SectionTitle {
  font-weight: bold;
}

.rml-content .SectionTitleBreak {
  font-weight: bold;
}

.rml-content .Declaration {
  text-transform: uppercase;
}

.rml-content .Helvetica {
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
}

.rml-content .Legend {
  font-family: Garamond;
  font-weight: bold;
  text-align: justify;
}

.rml-content .LegendSmall {
  font-family: Garamond;
  font-size: 8pt;
  font-weight: bold;
  text-align: justify;
}

.rml-content ul.Simple {
}

.rml-content ol.SectionEnum {
}

.rml-content ol.SectionEnum > li {
}

.rml-content ol.SectionEnum > li:before {
}

.rml-content ol.Numbered {
}

.rml-content ol.SubSectionEnum {
}

.rml-content ol.ArticleContentEnum {
  padding-left: 1.25in;
}

.rml-content ol.RomanEnum {
}

.rml-content ol.MattersEnum {
}

.rml-content ul.Whereases {
  padding-left: 80pt;
  position: relative;
}

.rml-content ul.Whereases > li {
  display: block;
}

.rml-content ul.Whereases > li:before {
  content: 'WHEREAS: ';
  font-weight: bold;
  left: 0;
  position: absolute;
}

.rml-content ul.Resolutions {
  padding-left: 80pt;
  position: relative;
}

.rml-content ul.Resolutions > li {
  display: block;
}

.rml-content ul.Resolutions > li:before {
  content: 'RESOLVED: ';
  font-weight: bold;
  left: 0;
  position: absolute;
}

.rml-content.section-preview ul.Resolutions > li:before {
  left: 26px;
}

.rml-content ol.Articles {
  list-style-type: decimal;
  font-weight: bold;
}

.rml-content .TermDef {
  font-weight: bold;
}

.rml-content .TermDefAlt {
  font-weight: bold;
  font-style: italic;
}

.rml-content .DocumentTitle {
  font-weight: bold;
  font-size: 14pt;
  leading: 16pt;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 14pt;
}

.rml-content .InlineSectionTitle0 {
  text-decoration: underline;
}

.rml-content .InlineSectionTitle1 {
  font-weight: bold;
  text-decoration: underline;
}

.rml-content .InlineSectionTitle2 {
  text-decoration: underline;
}

.rml-content .InlineSectionTitle3 {
  text-decoration: underline;
}

.rml-content .InlineSectionTitle4 {
  text-decoration: underline;
}

.rml-content .Upper {
  text-transform: uppercase;
}

.rml-content .Link {
  text-decoration: underline;
}

.rml-content .ExhibitRef {
  text-decoration: underline;
}

.rml-content .SectionRef {
  text-decoration: underline;
}

.rml-content .DiffInsert {
  color: @sb-green-type;
}

.rml-content .DiffDelete {
  color: #c42126;
}

.rml-content .DiffInsertFormatting {
  color: #5f9dc0;
}

.rml-content .DiffDeleteFormatting {
  color: #5f9dc0;
}

.rml-content table.SimpleTable {
}

.rml-content table.SimpleTable tr td {
  margin: 0;
  vertical-align: top;
}

.rml-content table.TableMiddle {
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
}

.rml-content table.SignaturesTable {
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
}

.rml-content table.SignatureTable {
  display: flex;
  line-height: 1.2;
}

.rml-content table.SignatureTable .sig-main tr td {
  margin: 0;
  vertical-align: top;
  display: inline;
}

.rml-content table.SignatureTable .sig-main tr:nth-child(2) > td > p {
  margin: 0px;
}

.rml-content table.SignatureTable .sig-small-text {
  font-size: 10px;
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  display: inline;
  float: left;
}

.rml-content table.SignatureTable .sig-small-text-right {
  font-size: 10px;
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  display: inline;
  float: right;
}

.rml-content table.sig-main {
  img {
    height: auto;
    width: 188px;
  }
}

.rml-content table.SignatureTable .sig-main {
  position: relative;
  .signature-row {
    border-top: 2px solid @sb-gray-light;
    border-bottom: 2px solid @sb-gray-light;
    height: 70px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
  }
  margin: 0px;

  tr:nth-child(1) {
    display: none;
  }
}

.rml-content table.SignatureTable .sig-details {
  margin-bottom: 0px;
}

.rml-content table.GridTable,
.rml-content table.GridTableWithHeader {
  border-collapse: collapse;
  border: 1px solid black;
}

.rml-content table.GridTable tr td,
.rml-content table.GridTableWithHeader tr td {
  padding: 3px;
  vertical-align: top;
  border: 1px solid black;
}

.rml-content table.GridTableWithHeader tr::first-child td {
  background: #eeeeee;
}

.rml-content .code {
  font-family: Courier;
  font-size: 10pt;
}

.rml-content table.sig-details,
.rml-content .signature-byword {
}

.rml-content .code-uuid {
  font-family: Courier;
  font-size: 10pt;
}

.rml-content .sig-small-text {
  color: @fps-text;
  font-family: 'Source Sans Pro';
  font-size: 7pt;
  position: relative;
}

// XXX: Supports old, already rendered docs which contains SIGNED IN SHOOBX text + logo.
// Since Shoobx logo doesn't exist in shoobx font, we replace it with word "shoobx".
.rml-content .sig-small-logo {
  position: relative;
  top: -34.5px;
  left: 45px;
  font-size: 0;

  &::after {
    content: 'shoobx';
    color: @fps-text;
    font-family: 'Source Sans Pro';
    font-size: 10px;
    text-transform: uppercase;
  }
}

.rml-content .sig-icon {
  color: @sb-white;
}

.rml-content .sig-small-text-right {
  color: @fps-text;
  font-family: 'Source Sans Pro';
  font-size: 7pt;
  position: relative;
  text-align: right;
}

.rml-content .sbx-signature-escrowed {
  position: relative;

  &::after {
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    font-weight: bold;
    content: 'Escrowed';
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 240%;
    color: @sb-gray-darker;
    opacity: 0.5;
  }
}

.rml-content .ArticleTitle {
}

.rml-content .ArticleEnum {
  padding-left: 24pt;
  text-align: center;
}
.rml-content .ArticleHeading {
  text-align: center;
}
.rml-content .ArticleNumber {
  clear: left;
  display: flex;
  justify-content: flex-end;
  text-transform: uppercase;
  max-width: 150px;
  padding-right: 0;
  font-weight: 700;
}
.rml-content .ArticleContent {
  padding-left: 0;
}

.rml-content .ArticleContentIntro {
  padding-left: 1.25in;
  padding-bottom: 3pt;
}

.rml-content .Definition {
}

.rml-content .SubDefinition {
  text-indent: 0.25in;
}

.rml-content table.DefinitionTable {
}

.inc-article {
  td {
    vertical-align: top;
  }
}

.ck-content .rml-content {
  margin: 0;
}

.ck-content td {
  border: 2px dashed @sb-gray-light !important;
}

.richtext-reference-pill,
.richtext-exhibit-pill,
.metadata-placeholder,
.dynamic-content {
  cursor: pointer;
  display: inline-block;
  border-radius: 10px;
  padding: 0 10px;
  text-indent: 0;
}

.metadata-placeholder {
  background: @sb-highlight-orange;
  box-shadow: inset 0 0 0 1px @sb-bright-orange;
}

.dynamic-content {
  background: rgba(@sb-highlight-orange, 0.5);
  box-shadow: inset 0 0 0 1px rgba(@sb-bright-orange, 0.5);
}

.richtext-reference-pill,
.richtext-exhibit-pill {
  background: @sb-gray-lighter;
  box-shadow: inset 0 0 0 1px @sb-gray-light;

  &[data-apply-styling='true'] {
    text-decoration: underline !important;
  }

  &[data-is-assigned='false'] {
    box-shadow: inset 0 0 0 1px @sb-red !important;
  }
}

/* TermSheet custom styles */

.rml-content .TSSectionTitle {
  font-size: 12pt;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-decoration: underline;
}

.rml-content .TSSubSectionTitle {
  font-size: 12pt;
  font-weight: bold;
  font-style: italic;
}

.rml-content .TSDefinition {
  font-size: 12pt;
  font-weight: bold;
  font-style: italic;
}

.rml-content .TSSubDefinition {
  font-size: 11pt;
  font-weight: bold;
  font-style: italic;
  text-indent: 0.75in;
}

.rml-content .TSArticleHeading {
  font-size: 12pt;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-decoration: underline;
}
