.similar-team-sh-modal {
  .option.radio {
    padding-left: 0;
    font-weight: normal;
  }
  .not-similar-option {
    border-top: 1px dashed @sb-gray;
    margin-top: 5px;
  }
  .similar-affiliates-sep {
    border-top: 7px solid @sb-gray-light;
    padding-top: 14px;
    margin-top: 7px;
  }
}
