.review-grant-page {
  display: flex;
  flex-direction: row;

  .congrats-img {
    width: 289px;

    img {
      width: 100%;
    }
  }

  .mt-20 {
    margin-top: 20px;
  }

  .payment-type {
    label {
      span {
        font-weight: 400;
      }
    }
  }

  tr:last-of-type {
    border: none;
  }

  @media (min-width: @screen-md) {
    .summary-content {
      flex: 1;
    }
  }

  @media (max-width: @screen-sm) {
    .congrats-img {
      display: none;
    }
  }
}
