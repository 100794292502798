table.table.table-striped.subprocesses-table {
  th,
  td {
    text-align: center;
  }

  td sb-collapsed-actions {
    .btn {
      color: initial;
    }
    .btn-link:hover {
      text-decoration: none;
    }
    .dropdown-menu {
      text-align: left;
      right: 4px;
      left: auto;
    }
    .open .btn-link {
      color: @sb-white;
      background-color: @sb-gray;
    }
  }

  .continue-error-highlight {
    border: 2px solid red;
  }

  .fa-check-circle.success-icon {
    color: @sb-green;
  }

  .fa-exclamation-triangle {
    color: @fps-text-secondary;
  }

  .fa-exclamation-circle {
    color: @brand-warning;
  }

  .fa-clock,
  .fa-clock-o {
    color: @sb-green-type;
  }
}
