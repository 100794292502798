/* -----[ Workitem: Collect Metadata: complete your profile ]-------------- */

.profile .profile-group h3 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.profile {
  .profile-group .form-group:nth-child(2n) {
    padding-top: 15px;
    padding-bottom: 8px;
    border-top: 2px solid @sb-gray-lighter;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .profile-group .form-group:last-child {
    border: none;
  }
}
