@import 'chosen';
.accept-grant-page {
  .widget-price {
    margin: 20px 0;

    .widget-label {
      font-weight: 600;
    }

    .item-description {
      padding-left: 55px;
    }
  }

  .async-document-download {
    margin-bottom: 10px;
  }

  .widget-main {
    padding: 0;
  }

  .formly-field > .sb-widget.form-group {
    border-top: 1px solid #bec9c9;
    margin: 0;
    padding: 20px 0;
  }

  .formly-field:last-of-type > .sb-widget.form-group {
    padding-left: 250px;
  }

  .select-multi-line {
    max-width: 364px;
  }

  .widget-main {
    span {
      font-weight: normal;
    }

    .col-md-12 {
      padding-left: calc(25% + 49px);
    }
  }
}

.pay-for-shares {
  .select-multi-line {
    .short-select;
  }
}
