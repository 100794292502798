.onboarding.intro {
  display: flex;
  align-items: flex-start;

  .onboarding-intro-main {
    flex: 1;
  }

  .sidebar-desc-box {
    flex: 1;
    min-width: 25%;

    .cap-info {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .subitem {
      margin-left: 5%;
      margin-bottom: 2%;
    }
  }
}

.sbx-onboarding-right-panel {
  padding-top: 20px;
  margin-left: 5%;
  margin-right: 5%;
  .sbx-panel-help-text {
    text-transform: capitalize;
    color: @fps-section-header;
    font-size: @font-size-h3;
    font-weight: 300;
    line-height: @font-size-h3;
  }
  .sbx-panel-contact {
    display: flex;
  }
  .help-right-panel-image {
    object-fit: contain;
  }
  hr {
    border-color: @sb-gray-light;
  }
}

.sbx-support-img {
  max-width: 200px;
}
