.StripeElement {
  background-color: #fff;
  padding: 5px 15px;
  height: 35px;
  border: 1px solid @sb-gray-light;
  width: 100%;
  display: block;
}
.StripeElement--focus {
  border-color: @sb-green;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 5px rgba(141, 198, 63, 0.4);
}
.StripeElement--invalid {
  border-color: @sb-red-type;
}

.cell-align-left {
  text-align: left;
}

.cell-align-right {
  text-align: right;
}

.label-style-width {
  width: 319px;
}

.order-table {
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  td,
  th {
    border: 5px solid @sb-white;
    padding: 15px;
    background: @fps-table-odd;
    vertical-align: top;
  }
  tr:first-child th {
    border-top: 0;
  }
  tr:last-child td {
    border-bottom: 0;
  }
  tr td:first-child,
  tr th:first-child {
    border-left: 0;
  }
  tr td:last-child,
  tr th:last-child {
    border-right: 0;
  }
  .item {
    width: 70%;
  }
  .sub-item {
    &::before {
      font-family: 'Shoobx';
      color: @fps-text-secondary;
      content: '\003E';
      font-weight: bold;
      display: block;
      position: absolute;
    }
    .sub-item-content {
      padding-left: 20px;
    }
  }
  .price {
    width: 30%;
    text-align: center;
    font-weight: bold;
  }
  .total {
    text-align: right;
    background: @fps-table-background;
  }
}

.billing-admin-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid @sb-gray-light;
  padding: 15px 15px 10px;
  > * {
    margin: 0;
  }
}

sb-billing-administration {
  display: block;
  .checkradios-radio {
    margin: 0;
    top: 0;
  }
  .alert {
    margin-top: 10px;
  }
  th:last-child,
  td:last-child,
  th:first-child,
  td:first-child {
    text-align: center;
    width: 80px;
    padding-left: 8px !important;
  }
}

.payment-table {
  td {
    padding: 3px 20px;
    padding-bottom: 20px;
    vertical-align: top;
    width: 50%;
  }
  .btn-add {
    float: right;
  }
  .right {
    text-align: right;
  }
}

sb-billing-card-form {
  display: block;
  border: 3px solid @fps-text;
  margin-bottom: 20px;
  > header {
    background: @sb-green;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      color: #fff;
      font-weight: bold;
      margin: 0;
    }
  }
  > main {
    padding: 10px;
  }
  // New card radio when its closed:
  div[sb-radio]:last-child {
    margin-bottom: 10px;
  }
  div[sb-radio] > label {
    padding-left: 10px;
  }
  div[sb-radio] + sb-new-card-form,
  sb-saved-card-form {
    margin-left: 35px;
  }
  sb-saved-card-form {
    margin-top: 8px;
  }
}

sb-saved-card-form {
  display: block;
}

sb-new-card-form {
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
  }
  formly-field,
  .card-element {
    flex: 1;
  }
  .card-element-row {
    padding: 8px 0;
  }
  > div > label {
    width: 25%;
  }
  > div > [sb-error-tooltip] {
    text-align: center;
    width: 49px;
    margin-top: 11px;
    visibility: hidden;
    pointer-events: none;
    &.show {
      visibility: visible;
      pointer-events: auto;
    }
  }
}
