.jpm-offering {
  max-width: 100%;
}

.jpm-sidebar {
  background-color: black;
  color: white;
  height: 70%;
  .sidebar-logo {
    max-width: 70%;
  }
  hr {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.jpm-sidebar-no-intro {
  max-width: 25%;
  margin-bottom: 15px;
}

.product-border {
  tr {
    border-bottom: 1px solid @sb-gray-light;
  }
  .desc {
    color: @fps-text-secondary;
  }
  td,
  th {
    padding: 3px 15px;
  }
  margin-bottom: 22px;
}

.jpm-footer {
  padding-top: 10px;
  color: @fps-text-secondary;
  font-size: 14px;
}

.jpm-panel {
  flex-direction: column;
  .text {
    color: @fps-text-secondary;
    font-size: 14px;
    display: flex;
  }
  .text-small {
    color: @fps-text-secondary;
    font-size: 12px;
    display: flex;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .legal-offer {
      max-height: 50px;
    }
    .description {
      max-width: 70%;
    }
  }

  .legal-offer {
    flex-direction: row;
    display: flex;
    width: 100%;
    .logo {
      min-width: 30%;
      max-width: 30%;
      padding-right: 5px;
      img {
        width: 100%;
      }
    }
    a {
      text-transform: uppercase;
    }
    .description {
      flex-direction: column;
      display: flex;
    }
  }

  hr {
    width: 100%;
    border-color: @sb-gray-light;
    border-width: 1px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.bundle-form {
  .option > div {
    max-width: 95%;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .option > div {
      max-width: 500px;
    }
  }
}

.jpm-panel-button {
  display: flex;
  flex-direction: row;
  margin-top: -10px;
  > a {
    padding-right: 5px;
    &.hide-panel {
      font-size: 80%;
      padding-left: 10px;
      padding-top: 2px;
    }
  }
}
.jpm-banking-logo {
  height: auto;
  max-height: 35px;
  max-width: 100%;
  object-fit: contain;
  align-self: flex-start;
}
