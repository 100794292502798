@import '../chosen';
sb-exercise-options-calculator {
  display: block;

  .expression {
    border: 3px solid @fps-box-border;
    text-align: center;

    &:not(:last-child) {
      margin-right: 20px;
    }

    .value,
    .help {
      padding: 10px;
    }

    .value {
      font-size: 30px;
      color: @fps-text;
    }

    .help {
      color: @fps-text;
      background: @fps-box-border;
    }
  }

  .expression-line {
    display: flex;
    margin-bottom: 20px;

    &.flexer > * {
      flex: 1;
    }

    &.in-form {
      margin: 20px 0 20px 258px;

      .expression {
        border-color: @fds-green-600;

        .help {
          color: white;
          background: @fds-green-600;
        }
      }
    }
  }
}

.exercise-options {
  .select-multi-line {
    .short-select;
  }

  .widget-main sb-field-label {
    width: 208px;
  }

  .radio,
  .checkbox {
    padding-left: 12px;
  }

  .chosen-container {
    max-width: 364px;

    .chosen-singe {
      color: @fps-text;
      border: 1px solid #bec9c9;
    }
  }
}
