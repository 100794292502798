// Shoobx: change the amount of glow around focused inputs.

// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `@input-focus-border` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.

h1,
h2,
h3,
h4 {
  text-transform: uppercase;
  color: @fps-text;

  &.de-emphasis {
    text-transform: none;
    font-weight: normal;
  }
}

h1 {
  font-size: 24px;
  font-weight: bold;
}

h2 {
  font-size: 22px;
  font-weight: bold;
}

h3 {
  font-size: 18px;
  font-weight: bold;
}

h4 {
  font-size: 110%;
  font-weight: 600;
}

a {
  text-decoration: underline;
}

.form-control {
  .box-shadow(~'none');
}

.input-group {
  .form-control {
    z-index: auto;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.form-control-focus(@color: @input-border-focus) {
  @color-rgba: rgba(red(@color), green(@color), blue(@color), 0.4);
  &:focus {
    border-color: @color;
    outline: 0;
    .box-shadow(~'inset 0 1px 1px rgba(0,0,0,.075), 0 0 5px @{color-rgba}');
  }
}

.dropdown-menu {
  right: 0;
  left: auto;
  border: 5px solid @dropdown-border;
  padding: 15px 0;
  border-radius: 0;

  .box-shadow(none);

  // Links within the dropdown menu
  > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: @line-height-base;
    color: @dropdown-link-color;
    white-space: nowrap; // prevent links from randomly breaking onto new lines
  }
}

.navbar-fixed-top {
  border: 0;
}

@sb-btn-line-height: 90%;

.btn {
  text-transform: uppercase;
  .button-size(@padding-base-vertical; @padding-base-horizontal; @font-size-base; @sb-btn-line-height; @border-radius-base);
  font-size: 15px;
}

.btn-primary {
  font-weight: bold;
}

.btn-default {
  font-weight: normal;
}

.btn-lg {
  .fa,
  .fas,
  .far,
  .fab {
    font-size: 17px;
    padding-right: 0;
  }
}

.btn-xs {
  height: 22px;
  font-weight: normal;
  font-size: 12px;
  border-radius: 2px;
  padding: 2px 10px;
  line-height: 16px;

  .fa,
  .fas,
  .far,
  .fab {
    font-size: 13px;
    line-height: 12px;
    color: @sb-green-type;
    padding-right: 5px;
    position: relative;
    bottom: -1px;
  }
}

.btn-file {
  background: @sb-gray-light;
}

// Interior buttons
.btn-int {
  .btn-default();
  .btn-xs();
  font-size: 12px;
}

.btn-lg {
  font-size: 15px;
  padding: 5px 15px;
  height: auto;
}

.multi-widget .buttons .btn {
  .btn-int();
}

.btn.with-pointer[disabled],
.btn.disabled.with-pointer {
  pointer-events: auto;
  outline: none;
}

// Progress bars

// Bar itself
// -------------------------

// Outer container
.progress {
  overflow: hidden;
  height: 12px;
  margin-top: 3px;
  margin-bottom: 20px;
  border-radius: 0;
  background: @sb-gray-light url('./../../img/progressBarBg.gif') repeat-x;
  background-size: 12px 12px;
  .box-shadow(none);
}

.progress-holder {
  margin-top: 12px;
  position: relative;
}

// Text on/under the bar
.progress span {
  position: absolute;
  top: 10px;
  display: block;
  color: @sb-green-type;
  font-weight: 600;
  width: 100%;
  //background: #ffffcc;
}

// Bar of progress
.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: @font-size-small;
  line-height: @line-height-computed;
  color: @progress-bar-color;
  text-align: center;
  background-color: @progress-bar-bg;
  .box-shadow(inset 0 -1px 0 rgba(0,0,0,0));
  .transition(width 0.6s ease);
}

// Variations
// -------------------------

.progress-bar-success {
  .progress-bar-variant(@sb-green);
}

.progress-bar-info {
  .progress-bar-variant(@sb-gray);
}

.progress-bar-warning {
  .progress-bar-variant(@sb-orange);
}

.progress-bar-danger {
  .progress-bar-variant(@sb-red-type); // Maybe @sb-red?
}

// Popovers
// --------

.popover {
  border: 5px solid @popover-fallback-border-color;
  border: 5px solid @popover-border-color;
  border-radius: 0;
  .box-shadow(none);
}

.popover-content {
  padding: 15px;
}

// Alerts
// ------

.alert {
  position: relative;
  border: none;
  font-size: 16px;
  line-height: 100%;
  min-height: 25px;
  padding-left: 10px;

  padding-right: (@alert-padding + 20);

  // Adjust close link position
  .close {
    position: relative;
    top: -4px;
    right: -19px;

    color: @fps-text-secondary;
    opacity: 1;
    text-shadow: none;
  }
}

.alert-info {
  border-left: 25px solid @sb-blue;
  &.fds-blue {
    .alert-variant(@fds-neutral-50, @fds-blue-800, @fps-text);
    padding: 15px;
  }
}

.alert-success {
  border-left: 25px solid @sb-green;
}

.alert-warning {
  border-left: 25px solid @sb-yellow;
  &.gray-bg {
    .alert-variant(@sb-gray-steps, @sb-yellow, @fps-text);
  }
  &.centered {
    padding: 15px;
  }

  a {
    color: @fps-link-color-light;
    &:hover {
      color: @fps-link-hover-color-light;
    }
  }
}

.alert-danger {
  border-left: 25px solid @sb-red;
}

.alert-flag {
  border-left: 25px solid @sb-orange;
  .alert-variant(@alert-flag-bg, @alert-flag-border, @alert-flag-text);
}

.alert-decoration {
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
  display: inline-block;
  height: 25px;
  width: 25px;
  color: @sb-white;
  padding: 5px 4px;
  text-align: center;
  margin-right: 10px;

  position: absolute;
  left: -25px;
  top: 0;
}

.alert {
  &::before {
    margin: auto;
    top: 0;
    bottom: 0;
  }
}

.alert-success::before {
  .alert-decoration();
  content: '\f00c';
  // background-color: @sb-green;
}

.alert-info::before {
  .alert-decoration();
  content: '\f129';
  // background-color: @sb-blue;
}

.alert-warning::before {
  .alert-decoration();
  content: '\f12a';
  // background-color: @sb-yellow;
}
.alert-danger::before {
  .alert-decoration();
  content: '\f12a';
  // background-color: @sb-red;
}
.alert-flag::before {
  .alert-decoration();
  content: '\f024';
  // background-color: @sb-orange;
}

// Forms.

label {
  font-weight: 600; // Semibold
}

.form-group {
  border-top: 2px solid @sb-gray-lighter;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 0;
}

.form-group:first-child {
  border: none;
}

.options .form-group {
  border-top: none;
  padding-top: 2px;
}

.error {
  color: @sb-red-type;
}

.md-changelog {
  color: @fps-text-secondary;
  font-size: 13px;
}

// Tables

.table {
  th {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: normal;
    color: @sb-gray-darker;
  }
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: none;
      }
    }
  }
  th:first-child,
  td:first-child {
    padding-left: 20px;
  }
  th:last-child,
  td:last-child {
    padding-right: 20px;
  }
}

.input-xs {
  height: 22px;
}

.control-div .error {
  font-size: 13px;
}

// Modals
.modal-content {
  border: 5px solid @modal-content-fallback-border-color;
  border-radius: 0;
  .box-shadow(none);
}

.modal-header {
  padding: @modal-title-padding;
  border-bottom: none;
  min-height: (@modal-title-padding + @modal-title-line-height);
  background-color: @fps-box-header-background;

  h4 {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    color: @sb-gray-darker;
  }

  .close {
    opacity: 1;
    color: @fps-text-secondary;
    //.text-shadow(none);
  }
}

.modal-footer {
  background-color: inherit;

  .form-actions {
    position: static !important;
    padding: 0 !important;
    border: 0;
    margin: 0;
    height: auto !important;
  }
}

// Tabs
// Give the tabs something to sit on
.nav-tabs {
  border: none;
  height: 40px;
  background-color: @fps-nav-tabs-background;

  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 15px 0 15px;
  margin-top: 10px;
  margin-bottom: 10px;

  > li {
    float: none;
    display: inline-block;
    margin-bottom: 0;
    height: 25px;
    padding: 0;

    // Actual tabs (as links)
    > a {
      margin-right: 15px;
      line-height: @line-height-base + 0.9;
      height: 35px;
      display: block;
      padding: 0 15px !important;
      border-width: 0 !important;
      border-radius: 0 !important;
      color: @fps-nav-tab-text;
      background-color: @fps-nav-tab-background;
      text-align: center;
      text-decoration: none;
      &:hover {
        color: @fps-nav-tab-hover-text;
        background-color: @fps-nav-tab-hover-background;
      }
    }

    // Active state, and its :hover to override normal :hover
    &.active > a {
      &,
      &:focus {
        color: @fps-nav-tab-active-text;
        background-color: @fps-nav-tab-active-background;
        border: none;
        cursor: default;
      }
      &:hover {
        color: @fps-nav-tab-active-text;
        background-color: @fps-nav-tab-active-background;
      }
      &::after {
        // Triangle
        content: ' ';
        display: block;
        height: 10px;
        width: 20px;
        border-left: 10px solid @sb-transparent;
        border-right: 10px solid @sb-transparent;
        border-top: 10px solid @fps-nav-tab-active-background;
        position: relative;
        bottom: -1px;
        margin: 0 auto;
      }
    }
  }
  // pulling this in mainly for less shorthand
  &.nav-justified {
    .nav-justified();
    .nav-tabs-justified();
  }
}
