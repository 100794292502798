sb-typeahead {
  display: block;
  position: relative;
  > ul {
    z-index: @z-index-10;
    background: #fff;
    border: 1px solid @sb-gray;
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    top: 35px;
    li.selected {
      background: @sb-green;
      color: #fff;
      .sbx-stakeholder-card {
        .sbx-off-color {
          color: #fff !important;
        }
      }
    }
    &.invalid-results li {
      opacity: 0.7;
    }
  }
  input {
    padding-right: 18px;
  }
  > div.loading {
    display: block;
    position: absolute;
    top: 6px;
    right: 10px;
    z-index: @z-index-3;
  }
}

.typeahead {
  // Always position it at the bottom left
  top: 35px !important;
  left: 15px !important;
  color: @fps-text;
  border-radius: 0px;
  border-color: @sb-green;
  border-width: 1px;
  padding: 1px;
  li {
    cursor: pointer;
  }
  li.active a {
    color: @fps-text;
  }

  li.active a:hover {
    color: @fps-text;
  }

  > li > a:focus,
  > li > a:hover {
    color: @fps-text;
    background-color: @sb-green;
  }
}
