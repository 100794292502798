@import '../../node_modules/OrgChart.js/dest/orgchart.min.css';

.header-item {
  margin-left: 22px;

  &.sort {
    padding: 0;
  }
}

.for-orgchart(@list, @code) {
  & {
    .loop-orgchart(@i:1) when (@i =< length(@list)) {
      @value: extract(@list, @i);

      @code();

      .loop-orgchart(@i + 1);
    }

    .loop-orgchart();
  }
}

.orgchart-container {
  min-width: 100%;
  height: ~'calc(100vh - 114px)';
  overflow: hidden;
  margin: 0 -15px;
  background: fade(#cfd9d9, 50%);
}

#orgchart {
  overflow: initial !important;
}

@colors-orgchart: #8dc63f, #e59413, #5eccd6, #d7c000, #6fa4e6, #9c987d, #e66b4d, #a5795b,
  #97aaaa, #a985e3, #bfcf0b, #d671b4, #141414, #74d1a0, #747cce, #33a3a8, #d3444b,
  #8c8c4b, #9957a3, #e8ae0c, #576db2, #9b4e37, #7cb585, #7f5eaa, #edda56, #427ad3,
  #848484, #50b250, #8c4955;

.orgchart {
  min-width: 100%;
  background: #fff;

  .show-all {
    top: -29px;
  }

  .show-all.bottom {
    top: initial;
    bottom: -29px;
    border-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .show-all .fa-angle-double-down {
    font-size: 22px;
    color: #ca7b00;
  }

  &.colored {
    .for-orgchart(@colors-orgchart, {
      .node.color-@{i} {
        .title {
          background-color: @value;
        }
      }

      .border-color-@{i} {
        background: fade(@value, 50%);
      }
    });

    .node .content.with-border-legend {
      padding-right: 22px;
    }

    .node .content .border-legend {
      display: block;
    }
  }

  .node {
    border: 0;
    margin: 0 auto;
    padding: 0;
    cursor: pointer !important;
    display: block;
    border: 5px solid transparent;
    border-width: 0 5px;
    z-index: 1;

    &:hover {
      background-color: transparent;
    }

    &.rootNode {
      border-radius: 4px;
    }

    &.withAvatar {
      border-top-left-radius: 33px;
      border-bottom-left-radius: 33px;
      padding-left: 32px;
    }

    .title {
      background-color: #97aaaa;
      border: 1px solid #97aaaa;
      // border-bottom: 0;
      border-radius: 0;
      font-size: 13px;
    }

    .toggleBtn {
      top: 2px;
      right: 2px;
      bottom: initial;
    }

    .content {
      color: @sb-green-type;
      border: 1px solid #97aaaa;
      border-left: 0;
      border-top: 0;
      border-radius: 0;
      padding-right: 2px;
      padding-left: 30px;
      font-size: 13px;
      white-space: nowrap;
      position: relative;

      .border-legend {
        display: none;
        position: absolute;
        right: 0;
        width: 20px;
        top: 0;
        bottom: 0;
        line-height: 39px;
        text-align: center;
        color: #000;
      }
    }

    .contentSecondLine {
      text-transform: uppercase;
      color: @fps-text-secondary;
      font-size: 10px;
      line-height: 1;
      overflow: hidden;
      white-space: normal;
    }

    .avatar {
      background-color: #dddddd;
      border: 1px solid #97aaaa;
      font-size: 36px;
      font-weight: 300;
      color: #566265;
      top: 0;
      left: 0;
      z-index: 10;
    }
  }

  .verticalNodes {
    .node {
      border-width: 5px 0;
    }

    > td > ul li > ul > li:first-child::before {
      top: -33px;
      height: 70px;
    }
  }

  .verticalNodes > td::before {
    border: 1px solid #97aaaa;
    border-left-color: transparent;
  }

  .verticalNodes ul > li {
    padding-left: 15px;
  }

  .verticalNodes > td > ul > li:first-child::before {
    height: 41px;
    border-width: 1px 0 0 1px;
  }

  .verticalNodes ul > li::before {
    top: -19px;
    height: 56px;
  }

  .verticalNodes ul > li:first-child::after {
    top: 36px;
    border-width: 1px 0 0 1px;
  }

  .verticalNodes ul > li:last-child::after {
    border-width: 1px 0 0;
  }

  .verticalNodes ul > li::before,
  .verticalNodes ul > li:first-child::after {
    width: 200px;
  }

  .verticalNodes ul > li::before,
  .verticalNodes ul > li::after {
    border-color: #97aaaa;
    border-width: 0 0 1px 1px;
  }

  tr.lines .topLine {
    border-top: 1px solid #97aaaa;
  }

  tr.lines .rightLine {
    border-right: 1px solid transparent;
  }

  tr.lines .leftLine {
    border-left: 1px solid #97aaaa;
  }

  tr.lines .downLine {
    background-color: #97aaaa;
    width: 1px;
  }
}

.oc-export-btn {
  display: none;
}

.orgchart-modal {
  .close {
    margin-top: -12px;
    margin-right: -8px;
  }

  .modal-body {
    padding: 0;
    background: @fps-text;
    color: #fff;
  }

  .main-info {
    background: #70af12;
    margin-bottom: 5px;

    > img {
      width: auto;
      height: 200px;
      float: left;
      margin-right: 15px;
      border-right: 5px solid @fds-neutral-900;
    }

    .main-info-content {
      padding: 15px;
    }
  }

  .meta-info {
    display: flex;
  }

  .meta-info-company,
  .meta-info-employee {
    margin: 0 0 5px;
    padding: 15px;
    list-style: none;
    flex: 1;

    li {
      margin-bottom: 10px;
      display: flex;

      &:last-child {
        margin: 0;
      }

      .meta-title {
        text-transform: uppercase;
        font-weight: 300;
        width: 110px;
        flex-grow: 0;
        flex-shrink: 0;
      }

      .meta-icon {
        width: 30px;
        display: inline-block;
      }

      a {
        color: #fff;
      }
    }
  }

  .meta-info-company {
    background: #ca7b00;
    margin-right: 2.5px;
  }

  .meta-info-employee {
    background: #71a1d8;
    margin-left: 2.5px;
  }
}

.legend-list {
  margin: 5px 0 15px;
  padding: 0;

  li {
    list-style: none;
    display: block;
    margin-right: 10px;
    line-height: 20px;
    margin-bottom: 5px;

    &:last-child {
      margin-right: 0;
    }

    .color-tile {
      content: '';
      border: 1px solid #97aaaa;
      border-radius: 2px;
      width: 20px;
      height: 20px;
      display: block;
      margin-right: 5px;
      float: left;
      text-align: center;
      line-height: 18px;
    }

    .for-orgchart(@colors-orgchart, {
      &.color-@{i} {
        .color-tile {
          background-color: @value;
        }

        .border-color-tile {
          background-color: fade(@value, 50%);
        }
      }
    });
  }
}

.legend-outline {
  position: fixed;
  top: 107px;

  .outline-button {
    margin-left: -15px;
    height: 70px;

    .inner {
      width: 72px;
      margin-left: -72px;
      height: 70px;
    }
  }

  .outline-sections {
    margin-left: -15px;
  }
}

.zoom-container {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 999;
  padding: 3px;
  border-radius: 3px;
  background: #fff;
  color: #adadad;

  .zoom-in,
  .zoom-out {
    cursor: pointer;
    border: 1px solid #adadad;
    padding: 3px;

    &:hover {
      background: #adadad;
      color: #fff;
    }
  }

  .zoom-in {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom: 0;
  }

  .zoom-out {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}
