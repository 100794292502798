ul.carta-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li.carta-bullet {
  padding-left: 30px;
  margin-top: 3px;
  margin-bottom: 3px;
  &.carta-pie {
    background: url('../../img/integration/carta-pie.png') no-repeat left;
  }
  &.carta-cal {
    background: url('../../img/integration/carta-cal.png') no-repeat left;
  }
}

.carta-review-cap-table {
  display: flex;
  flex-direction: row;
  align-items: center;

  span.carta-review-cap-table-img {
    margin-left: 40px;
  }
}

.carta-use-shoobx {
  .carta-user-inputs {
    label {
      display: flex;
    }
    .carta-input-label {
      width: 175px;
    }
    input {
      width: 400px;
    }
  }

  .carta-bot-description {
    padding-top: 10px;
  }
}
