.btn {
  border: none;
  border-radius: 100px;
  text-transform: none;
  font-weight: 700;
  text-decoration: none;

  &:focus,
  &:active,
  &:focus-visible,
  &:focus&:active {
    outline: none;
  }
}

.btn-login {
  border-radius: 24px;
}

.btn-primary {
  .fps-button(primary);
}

.btn-info {
  .fps-button(secondary);
}

.btn-danger {
  .fps-button(danger);
}

.btn-int {
  .fps-button(default);
  font-weight: 400;
  font-size: 12px;
  border-radius: 100px;
}

.fps-button(@variant) {
  @fps-button-text: 'fps-btn-@{variant}-text';
  @fps-button-background: 'fps-btn-@{variant}-background';
  @fps-button-border: 'fps-btn-@{variant}-border';
  @fps-button-icon: 'fps-btn-@{variant}-icon';
  @fps-button-hover-text: 'fps-btn-@{variant}-hover-text';
  @fps-button-hover-background: 'fps-btn-@{variant}-hover-background';
  @fps-button-hover-border: 'fps-btn-@{variant}-hover-border';
  @fps-button-hover-icon: 'fps-btn-@{variant}-hover-icon';
  @fps-button-disabled-text: 'fps-btn-@{variant}-disabled-text';
  @fps-button-disabled-background: 'fps-btn-@{variant}-disabled-background';
  @fps-button-disabled-border: 'fps-btn-@{variant}-disabled-border';
  @fps-button-disabled-icon: 'fps-btn-@{variant}-disabled-icon';

  color: @@fps-button-text;
  background-color: @@fps-button-background;
  border: 1px solid @@fps-button-border;

  .fa,
  .fas,
  .far,
  .fab {
    color: @@fps-button-icon;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  .open .dropdown-toggle& {
    color: @@fps-button-hover-text;
    background-color: @@fps-button-hover-background;
    border: 1px solid @@fps-button-hover-border;

    .fa,
    .fas,
    .far,
    .fab {
      color: @@fps-button-hover-icon;
    }
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: @@fps-button-disabled-text;
      background-color: @@fps-button-disabled-background;
      border: 1px solid @@fps-button-disabled-border;

      .fa,
      .fas,
      .far,
      .fab {
        color: @@fps-button-disabled-icon;
      }
    }
  }
}
