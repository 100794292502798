/* Cap Table Styles */

#report {
  margin: 0;
  width: 100%;
  padding-right: 20px;
  .row {
    margin-right: 0;
  }
}

.report-left .row {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#report .form-dropdown {
  width: 210px;
}

#report .heading {
  height: 60px;
  padding-top: 15px;

  h1 {
    display: inline-block;
    margin: 0 0 10px 0;
  }

  .pull-left {
    margin-right: 5px;
  }

  .form-dropdown {
    font-size: 13px;
    margin: 2px 0 0 5px;
  }

  .selected {
    padding: 2px 15px 1px;
    height: 20px;
  }

  button {
    position: relative;
    top: -2px;
  }
}

.report-tall-header {
  .base-list-header-left {
    float: left;
    width: 400px;
  }
  .sort-page-header {
    width: auto;
    float: right;
  }
  h1 {
    width: 350px;
  }
}

table.report-table {
  margin-right: 0;
  margin-left: auto;
  td,
  th {
    background: @sb-white;
    padding: 2px 10px 2px 10px;
  }
  td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  th {
    text-align: center;
  }
  td,
  th {
    position: relative;
    z-index: 1;
    &:not(:first-child) {
      min-width: 100px;
    }
    &:first-child {
      box-shadow: inset -20px 0 @sb-white;
      left: 0;
      max-width: 270px;
      min-width: 270px;
      overflow: hidden;
      padding-right: 20px;
      position: sticky;
      text-overflow: ellipsis;
      white-space: nowrap;
      z-index: 2;
    }
  }
  th {
    cursor: pointer;
  }
  .number {
    text-align: right;
  }
}

div.report-container {
  overflow-x: auto;
  overflow-y: visible;
  margin-bottom: 20px; // Make sure the horizontal scroll is not obscured by the footer.
}

.reports.actions {
  a {
    float: left;
  }
  sb-collapsed-actions {
    float: right;
    padding-right: 10px;
  }
}

/* --- EQUITY TRANSACTIONS REPORTS --- **/
.transactions-report {
  .title-header {
    display: flex;
    align-items: baseline;
    div:last-child {
      margin-left: auto;
      margin-right: 15px;
    }
  }
  .report-header {
    display: flex;
  }
  .load-more {
    margin: 15px 15px 50px;
  }
  h2,
  .no-tasks {
    margin-left: 10px;
  }
  sb-collapsed-actions {
    position: relative;
    top: 23px;
    left: 23px;
  }
}
