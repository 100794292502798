/**
 * Styles for Printing Shoobx
 */

#wrap > .navbar,
body > footer,
.backlink,
.dt-viewer-header .header h1,
.btn-print,
.popover {
  display: none !important;
}
a[href]:after {
  content: none;
}
