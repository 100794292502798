@import '@fmr-ap152550/dom/styles/dom-signin.css';

// @ng-bootstrap/ng-bootstrap@13.0.0 package introduced elements with
// .visually-hidden class which are accessible to assistive technologies.
// This class comes with Bootstrap v5.0 which we don't use, so we need to
// write our own.
// stylelint-disable selector-class-pattern
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
