/* -----[ General Entity Profile ]------------------------------------------- */
.entity-profile {
  padding-top: 15px;
  .email {
    width: 500px;

    .email-widget {
      width: 255px;
      display: inline;
      background: white;
      border: none;
      cursor: pointer;
    }
    .preferred {
      float: left;
      margin-right: 10px;
    }
    .remove-email {
      margin-left: 10px;
    }
  }

  h3 {
    padding: 0 !important;
    margin: 0 0 22px !important;
    border: 0 !important;
  }

  .entity-profile-group {
    margin-bottom: 35px;
  }

  .entity-profile-group-header {
    padding-bottom: 8px;
    margin-bottom: 10px;
    color: @fps-text;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 2px solid @sb-gray-light;
  }

  .entity-profile-officer-container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .entity-profile-last-edit {
    margin-top: 2px;
    text-transform: none;
    color: @fps-text-secondary;
    font-size: 13px;
    font-weight: normal;
  }

  h3 .entity-profile-last-edit {
    margin-top: 4px;
  }

  .entity-profile-col-last-edit {
    text-align: right;
  }

  tr:not(:last-child),
  .entity-profile-row {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid @sb-gray-light;
  }
  .entity-profile-row.edit-emails {
    padding-top: 40px;
    padding-bottom: 50px;
    .ng-invalid {
      border-color: @sb-red-type;
    }
  }

  .entity-profile-row .add {
    padding-top: 10px;
    .new-email-widget {
      margin-left: 60px;
      display: inline-block;
      width: 251px;
    }
    .add-email {
      margin-left: 10px;
    }
  }

  .entity-profile-row:last-child {
    padding-bottom: 0;
    border: 0;
  }

  .entity-profile-row-child {
    margin-top: 10px;

    .entity-profile-label {
      padding-left: 25px;
      position: relative;
    }

    .entity-profile-label:before {
      color: @fps-text-secondary;
      content: '\21b3'; // arrow
      top: 0;
      left: 0;
      position: absolute;
    }
  }

  .entity-profile-label {
    font-size: 16px;
    color: @fps-text;
    float: left;
    width: 30%;
    font-weight: 600;
  }

  .entity-profile-value {
    margin-left: 33%;

    .entity-profile-field-log {
      width: 45%;
    }
  }

  .entity-profile-widget-with-log {
    margin-right: 47%;
  }

  .entity-profile-no-value {
    font-size: 15px;
  }

  .entity-profile-field-log,
  .entity-profile-last-edit,
  .entity-profile-generic-process {
    float: right;
    margin-left: 10px;
  }

  .entity-profile-custom-process {
    margin-left: 3px;
  }

  .metadata-changelog-modal .modal-footer {
    display: none;
  }

  /* -----[ Stakeholder Profile ]----------------------------------------------------------- */

  &.stakeholder {
    display: flex;

    .viewspace {
      flex: 1;
    }

    .entity-profile-row {
      margin-bottom: unset;
      display: flex;
      flex: 1;

      .entity-profile-group {
        margin-bottom: unset;
        display: flex;
        flex: 1;
        flex-direction: column;

        .entity-profile-generic-process {
          margin-left: unset;
          align-self: flex-end;
        }
      }
    }
  }
}

#stock-incentive-plans-container {
  th {
    width: 230px;
    text-transform: none;
    font-size: 16px;
    color: @fps-text;
    font-weight: 600;
  }

  .borderless-tr {
    border: none;
  }

  .padded-td {
    padding-left: 40px;
  }

  .document-group {
    position: relative;

    .document-group-text {
      &:before {
        color: @fps-text-secondary;
        content: '\21b3'; // arrow
        top: 1px;
        left: -20px;
        position: absolute;
      }
    }
  }

  .padding-left-40 {
    padding-left: 40px;
  }

  .border-none {
    border: none;
  }
}

/* -----[ Equity ]----------------------------------------------------------- */
.equity {
  .entity-profile-last-edit {
    margin-top: 5px;
  }

  .profileHeader {
    margin: 15px 0px 0;
    padding: 0 10px 15px;
    float: left;
    border: none;
  }

  .viewspace {
    margin: 0 !important;
    padding: 0;
  }

  .form-group {
    padding: 0 0 15px;
    border: 0;
    label {
      padding: 0 15px;
      margin: 0;
    }
    span {
      padding: 0;
    }
  }
}
/*-----------[Protective Provisions] -----------------------------------------*/
.entity-profile-stockholder-pp {
  table {
    margin-bottom: 20px;

    .fa {
      font-size: 20px;
    }

    .fa-check {
      color: @sb-green;
    }

    .fa-times {
      color: @sb-red;
    }

    .pp-passing {
      padding-right: 10px !important;
      text-align: right;
    }

    .bold-provision {
      font-weight: bold;
    }
  }
}
