#ccorp-name-check-form {
  h2,
  h3 {
    font-weight: normal;
  }
  .row-ml-reset {
    margin-left: 0px;
  }
  .row-mr-right {
    margin-right: 0px;
  }
  .alert {
    padding: 15px;
    line-height: 130%;
    &::before {
      background-color: transparent;
    }
  }
  .fa-check-circle {
    font-size: 20px;
    &:before {
      color: @sb-green;
    }
  }
  .fa-exclamation-triangle {
    color: @sb-red;
  }
  .form-content {
    text-align: right;
    .form-group {
      text-align: left;
      border-top-color: transparent !important;
      label {
        position: relative;
      }
      .control-div {
        position: relative;
      }
    }
    button {
      background-color: @sb-green-bright;
      color: #fff;
      padding: 5px 15px;
      display: inline-block;
    }
  }
  #display-name {
    margin-top: 10px;
    .display-name-box {
      border: 3px solid @sb-gray-steps;
      text-align: center;
      h2 {
        margin-top: 5px;
        font-size: 28px;
        font-weight: bold;
        word-break: break-word;
      }
      span {
        font-size: 20px;
      }
    }
    .namecheck-results {
      padding: 5px 10px;
      text-align: left;
      background-color: @fps-box-background;
      .sb-gray {
        color: @fps-text-secondary;
      }
      .results-message {
        position: relative;
        display: inline-block;
        margin-left: 25px;
        i {
          position: absolute;
          left: -25px;
          top: 3px;
        }
        label {
          cursor: default;
        }
        &.warning-icon {
          label > i {
            &:before {
              color: @sb-red;
            }
          }
        }
        &.error-icon {
          i {
            &:before {
              color: @sb-blue;
            }
          }
        }
      }
    }
    .similar-names-box {
      margin-top: 10px;
      padding: 10px;
      border: 2px solid @sb-gray-lighter;
      img {
        width: 80%;
      }
    }
  }

  #no-matches {
    .alert {
      margin-top: 10px;
    }
  }
  .sidebar-desc-box {
    margin-bottom: 10px;
    h3 {
      margin-top: 0;
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
  }
  #namecheck-loading {
    position: relative;
    clear: both;
    float: none;
    margin: 0 auto;
    font-size: 20px;
    top: 0;
    width: inherit;
    text-align: center;
    transition: none;
    p {
      font-size: 30px;
    }
    i {
      font-size: 40px;
    }
  }
  .similar-results {
    margin-bottom: 0;
  }
}

.captcha-modal .modal-content {
  .squeeze {
    padding: 10px;
  }
  sb-recaptcha #recaptcha_area {
    margin-bottom: 10px;
  }
  .results-lists {
    padding: 10px 10px;
    ul {
      display: inline-block;
      width: 50%;
      list-style-type: none;
      vertical-align: top;
      height: 100%;
      padding: 0;
      margin-bottom: 0;
      li {
        min-height: 45px;
      }
    }
  }
}

.deccorp-name-check {
  margin-left: 40px;
  color: @fps-text-secondary;
  font-style: italic;
}

sb-url-availability-checker {
  .form-content {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
    span {
      text-align: left;
    }
    .form-group {
      position: relative;
      margin: 0;
      input {
        width: 100%;
        float: left;
      }
      .wi-loading-indicator {
        margin-bottom: 0;
        position: absolute;
        top: 47%; //questionable hack
        transform: translateY(-50%);
        right: 6px;
      }
    }
  }
  ul {
    list-style-type: none;
    text-align: left;
    word-break: break-word;
    padding-left: 20px;
    li {
      position: relative;
      .fa,
      .fas,
      .far,
      .fab {
        position: absolute;
        left: -20px;
        top: 5px;
        &.fa-check {
          left: -22px;
        }
      }
    }
  }
}
