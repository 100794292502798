@import (less) '~chosen-js/chosen.css';

.chosen-container-single .chosen-single {
  span {
    /* white-space: nowrap causes long values to spill out
           past the container width */
    white-space: normal;
  }
}

.select-multi-line {
  display: flex;
  flex: 1;
  gap: 10px;
  flex-direction: column;
}

.chosen-container {
  width: 100% !important; /* override inline style */
  font-size: 16px;
  outline: none;
  display: block;

  .input-body > & {
    flex: 1;
    width: auto !important;
  }

  .chosen-single {
    height: 35px;
    padding: 5px 15px;
    color: @fps-text;
    border-radius: 0px;
    border-color: @sb-gray-light;
    background: white;
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0),
      0 0 5px rgba(0, 0, 0, 0);
    /* white-space: nowrap causes long values to spill out
           past the container width */
    white-space: normal;
    &.chosen-default {
      color: #999;
    }
    div b {
      /* down arrow position */
      background-position: 0 8px;
    }
  }
  .chosen-drop {
    border-color: @sb-green;
    border-radius: 0px;

    .chosen-search input {
      border-color: @sb-gray;
      outline: none;
    }
  }
  .chosen-results {
    li.highlighted {
      background: @sb-green;
      color: @fps-text;
    }
  }
}

.chosen-container-active,
.chosen-container-active.chosen-with-drop {
  .chosen-single {
    background-image: none;
    border-color: @sb-green;
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 5px rgba(141, 198, 63, 0.4);
  }
  &.chosen-with-drop .chosen-single {
    div b {
      /* up arrow position */
      background-position: -18px 8px;
    }
  }
}
.sb-widget-error .chosen-single {
  border-color: @sb-red-type;
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close,
.chosen-container-single .chosen-search input[type='text'],
.chosen-rtl .chosen-search input[type='text'],
.chosen-container-single .chosen-single abbr,
.chosen-container-single .chosen-single div b {
  background-image: url('~chosen-js/chosen-sprite.png') !important;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-resolution: 144dpi) {
  .chosen-rtl .chosen-search input[type='text'],
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-single .chosen-search input[type='text'],
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    background-image: url('~chosen-js/chosen-sprite@2x.png') !important;
  }
}

formly-field .chosen-container,
[formly-field] .chosen-container {
  display: inline-block;
}

.short-select {
  max-width: 364px;
}
