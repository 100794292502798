// grant-details.html page

.content-padding {
  padding: 15px;
}

.hr-info-title {
  .mixed-case-heading();
  margin-top: 0;
}

.btn-noborder {
  border: none;
}

.hr-complete {
  color: #8dc63f;
  margin: auto;
  text-align: center;
}

.hr-incomplete {
  color: #ff0000;
  margin: auto;
  text-align: center;
}

.hr-inprocess {
  color: @fps-text-secondary;
  margin: auto;
  text-align: center;
}

.hr-button {
  position: relative;
  top: -5px;
}

.sbf-document-missing {
  .hr-button {
    position: initial;
  }
}

.hr-terminated {
  text-decoration: line-through;
}

.hr-onboarding-table {
  text-align: center;
}

.hr-onboarding-table-7 {
  white-space: normal;
  word-wrap: normal;
  margin: auto;
  text-align: center;
  width: 7%;
}

.hr-onboarding-table-6 {
  white-space: normal;
  word-wrap: normal;
  margin: auto;
  text-align: center;
  width: 8%;
}

.hr-onboarding-table-5 {
  white-space: normal;
  word-wrap: normal;
  margin: auto;
  text-align: center;
  width: 10%;
}

.hr-onboarding-table-4 {
  white-space: normal;
  word-wrap: normal;
  margin: auto;
  text-align: center;
  width: 13%;
}

.hr-onboarding-table-3 {
  white-space: normal;
  word-wrap: normal;
  margin: auto;
  text-align: center;
  width: 17%;
}

.hr-onboarding-table-2 {
  white-space: normal;
  word-wrap: normal;
  margin: auto;
  text-align: center;
  width: 25%;
}

.doc-list .sbf-document-missing {
  padding-right: 5px;
}

.hr-onboarding-left {
  text-align: left;
}

.onboarding-nav {
  margin-left: -15px;
  margin-right: -15px;
}

.hr-pad {
  padding: 5px;
}

ul.hr-details {
  margin-bottom: 0;
  list-style-type: none;
  padding: 20px 0;

  li span:first-child {
    color: @fps-text-secondary;
    font-weight: 500;
  }

  &:first-child {
    th,
    td {
      padding-top: 15px !important;
    }
  }
}

.sparkline-progres-bar {
  width: 100px;
  display: inline-block;
  margin: 3px 15px;
  height: 5px;
  .progress-bar {
    box-shadow: none;
  }
}

.hr-info-box {
  border-bottom: 2px solid @sb-gray-lighter;

  .hr-info-box-header {
    color: @sb-white;
    background-color: @sb-gray-light;
    dl {
      margin: 0;
    }
    dt {
      display: inline-block;
      font-weight: normal;
      text-transform: uppercase;
      font-size: 12px;
      color: @sb-white;
      line-height: 0.9em;
      font-weight: 600;
      padding: 0 5px 0 15px;
    }
    dd {
      display: inline-block;
      color: @fps-text;
      padding: 0 0 0 0;
      div.progress {
        .sparkline-progres-bar();
      }
    }
  }
  /* be sure to make room for the border */
  .hr-info-dotted {
    margin-left: 0;
    margin-right: 0;
  }

  .hr-info-dotted > div {
    border-right: 3px dotted @sb-gray-light;
    padding-left: 14px;
    padding-right: 13px;
  }

  .hr-info-dotted > div:last-child {
    border-right: none;
    padding-right: 15px;
  }

  .hr-info-dotted > div:first-child {
    padding-left: 15px;
  }
}

.hr-info-docs {
  .fa,
  .fas,
  .far,
  .fab {
    padding-right: 5px;
  }
  .date {
    padding-left: 20px;
    padding-bottom: 15px;
  }
}

// onboarding.html
#hr-grants {
  .heading {
    border-bottom: 1px solid @sb-gray-light;
    margin: 0 -15px;
    padding: 0 15px;

    h1 {
      display: inline-block;
      margin: 15px 0 0;
    }
    dl {
      display: inline-block;
      margin: 0;
      dt {
        display: inline-block;
        font-weight: normal;
        text-transform: uppercase;
        font-size: 12px;
        color: @fps-text-secondary;
        line-height: 0.9em;
        font-weight: 600;
        padding: 0 5px 0 10px;
      }
      dd {
        display: inline-block;
        &:not(:last-child) {
          padding-right: 10px;
          border-right: 1px solid @sb-gray-light;
          line-height: 100%;
        }
      }
    }
  }

  .table {
    ul {
      list-style: none;
      padding: 0;

      li {
        margin: 0 0 10px;
      }

      li:last-child {
        margin: 0;
      }
    }
    dl {
      margin: 0;
      padding: 0;
      dt {
        display: inline-block;
        font-weight: normal;
        text-transform: uppercase;
        font-size: 12px;
        color: @fps-text-secondary;
        line-height: 0.9em;
        font-weight: 600;
        padding: 0;
        margin: 0;
      }
      dd {
        display: inline;
        line-height: 1;
        &:after {
          content: ' ';
          display: block;
        }
      }
    }
    .progress {
      .sparkline-progres-bar();
      margin: 1px 0 5px;
      width: 120px;
      display: block;
    }
    .grant-id {
      font-size: 70%;
    }
    .detail {
      font-size: 70%;
    }
  }
}

/* -----------[HR Admin]---------------------------------------*/
.hr-intro-text {
  margin-bottom: 30px;
}

.hr-departmentments {
  overflow: hidden;
}

sb-departments-list-manager {
  li:not(:first-child) {
    padding-top: 7px;
  }
  li:not(:last-child) {
    margin-bottom: 7px;
  }
  li.level-0 {
    font-weight: bold;
    &:not(:first-child) {
      border-top: 1px solid @sb-gray-light;
    }
  }
  li:not(.level-0)::before {
    font-family: 'Shoobx';
    content: '>';
    color: @fps-text-secondary;
  }
  .generate-levels-indent(@n, @i: 1) when (@i =< @n) {
    li.level-@{i} {
      padding-left: @i * 17px;
    }
    .generate-levels-indent(@n, @i + 1);
  }
  .generate-levels-indent(7);
}

/* -----------[HR Admin Onboarding Tasks]----------------------*/

.onboarding-tasks-table {
  padding: 20px;

  .manage-tasks {
    float: right;
  }

  .ticket-id {
    min-width: 80px;
    width: 80px;
    padding-top: 20px;
  }

  tbody tr {
    min-height: 100px;
    td {
      min-width: 130px;
      position: relative;
      padding: 10px 10px;
      button {
        padding: 5px 5px;
      }
    }
    td:last-child {
      min-width: 90px;
    }
    td:after {
      content: ' ';
      position: absolute;
      width: 2px;
      top: 10px;
      bottom: 10px;
      right: 0;
      background: @sb-gray-light;
    }
    td:last-child:after {
      display: none;
    }
  }
}

/* -----------[Onboarding Tasks List]----------------------*/
.onboarding-tickets-list,
.onboarding-task-report {
  margin-bottom: 22px;
  .filter {
    .chosen-single {
      min-width: 160px;
    }
  }
  tbody tr {
    td {
      position: relative;
    }
    td:after {
      content: ' ';
      position: absolute;
      width: 1px;
      top: 5px;
      bottom: 5px;
      right: 0;
      background: @sb-gray-light;
    }
    td:last-child:after {
      display: none;
    }
  }
  .completed {
    color: @sb-green;
  }
  .description {
    white-space: pre-wrap;
  }
  div.task-group {
    border: 2px solid @sb-gray-light;
    margin-bottom: 15px;

    h3 {
      background-color: @sb-gray-light;
      margin: 0;
      padding: 12px 25px;
      text-transform: none;
    }

    table.table {
      margin: 0;
    }
  }
}

.onboarding-task-report {
  .dropdown {
    display: inline-block;
    border-width: 2px;
    border-style: solid;
    border-bottom: hidden;
    border-color: rgba(0, 0, 0, 0);

    .dropdown-toggle {
      padding: 5px 15px;
    }

    ul.dropdown-menu {
      border-width: 2px;
      position: absolute;
      right: -2px;
      top: 25px;
      z-index: 50;

      .icon-link {
        text-transform: uppercase;
        font-size: 85%;
      }
    }
  }
  .dropdown.open {
    z-index: 60;
    border-color: @sb-gray;
    border-bottom-color: #fff;
    background-color: #fff;
  }
}

a[ng-click] {
  cursor: pointer;
}

#onboarding-forms {
  th:first-child {
    white-space: nowrap;
  }

  .bool {
    text-align: center;
    width: 80px;
  }

  .options {
    text-align: center;
  }

  .title {
    width: 25%;
  }

  td:first-child {
    width: 40px;
  }
}

.context-bar {
  position: fixed;
  width: @sidebar-width;
  border-right: 3px dotted @sb-gray-light;
}

.context-bar-mobile {
  .sb-slide-menu(250px);
}

.profile-content {
  margin-left: 210px;

  .profileTitle {
    margin: 15px 0px 0;
    padding: 0 10px 15px;
    border: none;
  }

  &.mobile {
    margin-top: 0px;
    margin-left: 15px;
  }

  .context-main h4 {
    font-size: 16px;
    margin: 15px 0px 0px;
    padding: 0 10px 15px;
    border-bottom: 2px solid #f1f4f6;
    clear: both;
  }

  form-header {
    font-size: 18px;
    font-weight: lighter;
    margin: 15px 16px 0;
    padding: 0 15px 15px;
    h3 {
      font-weight: bold;
    }
  }
}

.profile-nav dd {
  word-wrap: break-word;
}

.profile-nav .nav {
  margin-top: 20px;
}
