@import '../thirdparty/bs3-custom-vars.less';

.sb-pay-for-shares {
  .details {
    margin: 0 24px 22px 0;
  }

  .item {
    border-bottom: solid #bec9c9 1px;
    padding: 22px 0;
    display: flex;
  }

  .item-title {
    color: @fps-text;
    font-weight: 600;
    flex-basis: 31%;
  }

  .item-description {
    flex-basis: 69%;
  }

  .pay-option-form {
    .form-group.sb-widget.sb-widget-subfield-0 {
      border: none;
    }

    .formly-field-bool-checkbox {
      margin-left: 29%;

      label {
        font-weight: 400;
      }
    }
  }
}

.sb-checkpoint-ach-non-tax {
  .details {
    padding-bottom: 10px;
  }

  .details > div {
    display: flex;
    &:last-child {
      border: none;
    }
  }

  .item-title {
    color: @fps-text;
    font-weight: 600;
  }
}

.sb-checkpoint-non-ach-non-tax {
  .details {
    margin: 0 24px 22px 0;
  }

  .details > div {
    border-bottom: solid #bec9c9 1px;
    padding: 22px 0;
    display: flex;
    &:last-child {
      border: none;
    }
  }

  .item-title {
    color: @fps-text;
    font-weight: 600;
  }
}
