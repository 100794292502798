sb-stakeholder-approval-list {
  span {
    display: block;
  }
  li:not(:last-child) {
    margin-bottom: 10px;
  }
  li,
  .rejection {
    clear: both;
  }
  .rejection {
    margin-top: 10px;
  }
}

.sb-process-status-performer-row:not(:last-child) {
  padding-bottom: 10px;
}

.stakeholder-creation-modal {
  .form-container {
    padding: 0 47px;
  }
  .name-form {
    display: block;
    margin-bottom: 30px;
  }
}

/* - Stakeholder Panel styling- */
.employee-panel {
  ul {
    padding-top: 5px;
  }
  .content {
    height: 180px !important;
    overflow: auto !important;
    margin-top: -2px;
    > div {
      height: 100%;
    }
  }
  .loading {
    padding-top: 25px;
  }
  h5 {
    margin: 5px;
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    color: @fps-text-secondary;
  }
  .item-details {
    line-height: normal;
  }
  .position {
    font-weight: normal;
    color: #90a7a7;
  }
  input {
    margin: 0 10px;
    height: 30px;
    width: 95%;
  }
  input[placeholder].ng-not-empty {
    font-style: normal !important;
  }
  .recent-hire {
    display: inline-block;

    .item-avatar {
      float: left;
      font-size: 16px;
      padding-bottom: 10px;
      .avatar-initials {
        min-height: 45px;
        min-width: 45px;
        padding-top: 10px;
      }
    }
    .item-details {
      padding-left: 15px;
      padding-bottom: 5px;
      overflow: auto;

      .item-title {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 3px;

        .start-date {
          font-weight: normal;
          font-size: 13px;
          color: @fps-text-secondary;
          white-space: nowrap;
        }
        a {
          font-size: 16px;
          font-weight: normal;
          margin-bottom: 5px;
          color: @sb-green-type;
          text-decoration: none;
        }
      }
    }
  }
  .birthdays {
    margin-top: -5px;
    ul {
      padding-left: 5px;
      padding-top: 0;

      li {
        i {
          padding-right: 5px;
        }
        margin-bottom: 5px;
        a {
          font-size: 16px;
        }
      }
    }
  }
}

.circle-icon {
  color: white;
  background: @sb-gray;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
  vertical-align: middle;
  padding: 5px;
}

sb-stakeholder-privilege-page .privileges {
  > * {
    padding-left: 15px;
    padding-bottom: 5px;
  }

  .heading {
    border-top: 2px solid @sb-gray-lighter;
  }
  sb-breadcrumb {
    span {
      max-width: 150px;
    }
  }
  .shared-doc {
    sb-breadcrumb {
      font-size: 85%;
      color: @fps-text-secondary;
      span::before {
        font-size: 100%;
      }
      a {
        color: @fps-text-secondary;
      }
    }
  }
  th {
    background-color: @sb-gray-light;
    color: @fps-text;
    padding: 5px 15px;
  }
}
.folder-path a {
  cursor: default !important;
  color: @fps-text;
}

sb-manage-stakeholders-page {
  .stakeholder-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;

    > div {
      padding: 10px;
      display: flex;
      align-items: center;
      flex-basis: 0;

      &:not(:last-child) {
        border-right: 1px solid @sb-gray-light;
      }
    }

    .sh-card-col {
      flex-grow: 13;
    }
    .sh-contacts-col {
      max-width: 220px;
      min-width: 220px;
      .middle {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .sh-access-col {
      flex-grow: 4;
      justify-content: center;
      .company-access {
        font-style: italic;
        text-align: center;
        a {
          color: @fps-text-secondary;
          .fa,
          .fas,
          .far,
          .fab {
            color: @sb-green;
            font-size: 120%;
          }
        }
      }
    }
    .sh-status-col {
      flex-grow: 5;
      font-size: 80%;
      .extra {
        color: @fps-text-secondary;
      }
    }
    .sh-actions-col {
      flex-grow: 1;
      padding: 5px 7px 5px 10px;
      .btn {
        text-align: left;
        text-transform: none;
        color: @fps-text;
        .fa,
        .fas,
        .far,
        .fab {
          color: @sb-green-type;
          margin-right: 7px;
          width: 1.2em;
        }
      }
    }
  }
}

.selected-stakeholder .other-choice {
  display: none;
}
