sb-pdf-upload,
*[sb-pdf-upload],
sbx-pdf-upload {
  .fileinput-button {
    position: relative;
    overflow: hidden;
  }

  .fileinput-button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
  }
}
