sb-exp-date-drop-down {
  .dropdown {
    display: inline-block;
  }
  .dropdown-menu {
    overflow: visible;
  }
  .btn {
    text-transform: none;
  }
  sb-collapsed-actions > .dropdown > .dropdown-menu > items > div {
    display: flex !important;
    align-items: center;
    > span {
      min-width: 100px;
    }
  }
  sb-date-picker {
    margin-left: 8px;
    display: inline-table;
    border-collapse: separate;
  }
}

sb-exp-date-drop-down,
sb-icon-drop-down {
  sb-collapsed-actions {
    .btn.disabled,
    .btn[disabled] {
      border-color: inherit;
      border-width: 0;
      opacity: inherit;
      background-color: inherit;
    }
  }
}
