.profile {
  sb-metadata-endpoint-form {
    margin-left: 15px;
    margin-right: 15px;
    display: block;
    > ng-form {
      display: block;
      margin-bottom: 100px;
    }
    .form-actions {
      z-index: 2;
      position: fixed;
      bottom: 0;
      left: 50%;
      @media (min-width: 768px) {
        & {
          @width: @container-tablet;
          width: @width;
          margin-left: -(@width / 2);
        }
      }
      @media (min-width: 1024px) {
        & {
          bottom: @footer-height;
          @width: @container-desktop;
          width: @width;
          margin-left: -(@width / 2);
        }
      }
      @media (min-width: 1280px) {
        & {
          @width: @container-large-desktop;
          width: @width;
          margin-left: -(@width / 2);
        }
      }
      .btn {
        margin: 0 10px;
      }
    }
  }

  .form-actions-wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    > div {
      margin: 0 auto;
      padding: 0 15px;
    }
    @media (min-width: 768px) {
      > div {
        width: @container-tablet;
      }
    }
    @media (min-width: 1024px) {
      bottom: @footer-height;

      > div {
        width: @container-desktop;
      }
    }
    @media (min-width: 1280px) {
      > div {
        width: @container-large-desktop;
      }
    }
    .form-actions {
      height: @lower-buttons-height;
      box-sizing: border-box;
      padding-right: 20px;
      button {
        float: right;
      }
    }
  }
  .account-recovery-email {
    padding-right: 1px;
  }

  .modal .form-actions-wrap {
    position: static;

    > div {
      padding: 0;
      width: auto;
    }

    .form-actions {
      padding-right: 0;
    }
  }

  #acc-avatared-upload .btn-int {
    .fa,
    .fas,
    .far,
    .fab {
      padding: 0;
    }
  }

  .header {
    top: @header-height;

    .inner {
      background-color: white;
      padding: @standard-padding;
      border-bottom: 1px solid @sb-gray-light;
    }

    h1 {
      margin: 0;
    }
  }

  .context-bar {
    position: fixed;
    width: @sidebar-width;
    border-right: 1px solid @sb-gray-light;
    padding-top: @content-top;
    display: block;

    @media (max-width: @screen-sm-max) {
      display: none;
    }
  }

  .context-bar-mobile {
    .sb-slide-menu(250px);
    display: none;

    @media (max-width: @screen-sm-max) {
      display: block;
    }
  }

  @content-top: 59px;
  .profile-content {
    padding-top: @content-top;
    margin-left: 210px;

    @media (max-width: @screen-sm-max) {
      margin-top: 0px;
      margin-left: 15px;
    }

    .profileTitle {
      margin: 15px 0px 0;
      padding: 0 10px 15px;
      border: none;
    }

    .context-main h4 {
      font-size: 16px;
      margin: 15px 0px 0px;
      padding: 0 10px 15px;
      border-bottom: 2px solid #f1f4f6;
      clear: both;
    }

    h3 {
      margin: 15px 16px 0;
      padding: 0 15px 15px;
      border-bottom: 2px solid @sb-gray-lighter;
    }
  }

  .profile-nav dd {
    word-wrap: break-word;
  }

  .profile-nav .nav {
    margin-top: 20px;
  }

  .context-right {
    padding-right: 40px;

    h4 {
      margin: 15px 10px 0;
      border-bottom: 2px solid @sb-gray-lighter;
      text-transform: unset;
    }
  }

  .stakeholder-page-main .form-group {
    width: 98%;
    margin: 0 auto;
    border: none;
  }

  .stakeholder-page-main .form-group:nth-child(1n) {
    padding-bottom: 15px;
    border-bottom: 2px solid @sb-gray-lighter;
    margin-bottom: 5px;
  }

  .stakeholder-page-main .form-group:last-child {
    border: none;
  }

  .twitter-widget {
    display: flex;
    span {
      padding: 5px 25px 5px 15px;
    }
  }
  .emergency_contact .address-widget {
    width: 350px;
  }
  #picture-block .picture {
    display: block;
    margin: 10px 0 10px;
    max-width: 100%;
  }

  .picture-block-image {
    display: block;
    margin: 0 auto 10px;
    text-transform: uppercase;
    border: 3px dotted @sb-gray-light;
    padding: 10px 0;
    font-size: 0.7em;
    color: @fps-text-secondary;
  }

  .picture-block-image {
    .fa,
    .fas,
    .far,
    .fab {
      /* Big User Icon */
      font-size: 15em;
    }
  }

  .company-block-image {
    .fa,
    .fas,
    .far,
    .fab {
      /* Company Logo Icon */
      font-size: 10em;
    }
  }

  .small-profile-picture {
    width: 35px;
    height: 35px;
    float: left;
    display: block;
    position: relative;
    top: -4px;
  }

  .small-profile-picture-padding {
    margin-left: 45px;
  }

  .sig-block-empty-signature {
    padding: 25px 0;
    border: 3px dotted @sb-gray-light;
    text-transform: uppercase;
    display: block;
    font-size: 0.7em;
    color: @fps-text-secondary;
    margin-bottom: 10px;
  }

  #sig-block {
    /* Some additional spacing between sections. */
    margin-top: 15px;
  }

  #sig-block,
  .viewspace {
    margin-bottom: 60px; /* Prevent fixed submit overlap */
  }
}

@media (max-width: 1024px) {
  .profile {
    .context-right {
      text-align: center;
    }

    #picture-block .picture {
      margin: 0 auto 10px;
    }
  }
}
