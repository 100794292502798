sb-document-download-dropdown,
sbx-document-download-dropdown {
  .dropdown {
    display: inline;
  }
  ul.dropdown-menu {
    margin: 0;
    padding: 0;
    border-width: 1px;
    li button {
      width: 100%;
      border-width: 0;
      text-align: left;
    }
  }
  .dropdown-icon {
    padding-right: 0px;
    padding-left: 5px;
    bottom: -2px;
  }
}
