.reps-and-warranties {
  .uppercase-heading {
    text-transform: uppercase;
  }

  .disclosures-count {
    text-align: right;
    font-size: 16px;
    font-weight: 400;
  }

  .panel-body > .row {
    padding-top: 0;
    padding-bottom: 0;
    border-top: 0;
  }

  .saved-info {
    color: @fps-text-secondary;
    font-size: 14px;
  }

  .expand-caret {
    width: 15px;

    &::before {
      content: '\F0DA';
    }
  }

  .panel-open {
    .expand-caret::before {
      content: '\F0D7';
    }
  }
}

sb-collect-representations {
  .exception-textarea {
    margin-bottom: 10px;
  }
}
