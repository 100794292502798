.wi-template {
  .sb-gray {
    color: @fps-text-secondary;
  }

  .wi-content {
    border-top: 2px solid #dfe6e6;
    padding: 15px 0px;
  }

  .wi-content h2 i {
    margin-right: 20px;
  }

  .intro {
    margin-bottom: 10px;
  }

  td {
    padding: 5px;
    text-align: center;
  }

  .bottom-padding {
    padding-bottom: 35px;
  }

  table {
    border-bottom: 1px solid @sb-gray;
    border-collapse: separate;
    margin-bottom: 15px;
  }

  table:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
  }

  .stock_classes td {
    padding: 5px;
    width: 33%;
  }

  td[rowspan] {
    vertical-align: top;
  }
}
.wi-vertical-center {
  display: flex;
  align-items: center;
}
