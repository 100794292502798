@font-face {
  font-family: 'Roboto Slab';
  src: url('./../../fonts/roboto-slab/RobotoSlab-Thin.ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./../../fonts/roboto-slab/RobotoSlab-ExtraLight.ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./../../fonts/roboto-slab/RobotoSlab-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./../../fonts/roboto-slab/RobotoSlab-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./../../fonts/roboto-slab/RobotoSlab-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./../../fonts/roboto-slab/RobotoSlab-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./../../fonts/roboto-slab/RobotoSlab-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./../../fonts/roboto-slab/RobotoSlab-Black.ttf');
  font-weight: 900;
  font-style: normal;
}
