.wait-for-tickets {
  padding: 20px;
  .description {
    font-weight: 300;
    font-size: 22px;
  }
  .completed {
    color: @sb-green;
  }
}
