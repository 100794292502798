.frb-intro2 {
  .offer-box {
    margin: 50px 0px;
    border: 3px solid @sb-gray;
    .logo {
      padding: 15px 20px;
      text-align: center;
    }
    .text {
      background: @fps-box-background;
      padding: 20px;
    }
  }
}
