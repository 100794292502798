/* -----[ Monitor Approvals WI Styling ]----------------- */
.signatures-table {
  margin-left: 20px;
}
.signatures-table .status-icon {
  padding-left: 5px;
}
.signatures-table .name {
  width: 150px;
}
.signatures-table .approve-time {
  padding-right: 5px;
}
.signatures-table tr:nth-child(even) {
  background-color: @fps-table-odd;
}

/*Onboarding Summary*/
.onboarding-summary ul.sbx-vertical {
  margin-left: 20px;
}

.font-style-normal {
  font-style: normal;
}

.sb-highlight {
  background-color: @sb-highlight-orange;
}

/*File upload tip*/
.upload-document-tip {
  margin-top: 25px;
  margin-left: 15px;
  color: @fps-text-secondary;
}

.sb-upload-effective {
  font-weight: 600;
  .sbx-list-row {
    background-color: @gray-lighter;
    padding: 15px 15px;

    .item {
      display: block;
      margin-bottom: 15px;

      label {
        font-weight: normal;
      }
    }
  }
}
