.exit-planner {
  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid @sb-gray-light;
    padding: 10px 16px;
    height: 60px;
  }

  .header-left {
    flex: 4;
    display: flex;
    align-items: center;
    margin-right: auto;

    .title {
      margin: 0;
    }

    button {
      margin: 0 10px;
    }
  }

  /*** Generic Styling ***/

  .displaynone {
    display: none !important;
  }

  .btn-link {
    &:hover {
      text-decoration: none;
    }
    padding: 5px 3px;
  }

  .input-group-addon {
    display: flex;
    align-items: center;
    justify-content: center;
    &.shares {
      width: 55px;
    }
    border-radius: 0;
  }

  .td-right {
    text-align: right;
  }

  td .actions {
    display: flex;
    flex-direction: row-reverse;

    .btn .fa-spinner {
      padding-right: 0;
    }
  }

  .overlay {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(241, 244, 246, 0.5);
    z-index: 1001;
    .spinner {
      text-align: center;
      vertical-align: middle;
      font-size: 48px;
      width: 100%;
      position: relative;
      top: 50%;
      color: white;
      font-weight: 600px;
    }
  }

  .divider {
    margin: 0 2px;
    font-size: small;
    vertical-align: text-top;
    font-family: serif;
    font-weight: 800;
    color: @fps-text-secondary;
    font-weight: 800;
  }

  .stock-name {
    display: flex;
    align-items: center;

    .square {
      height: 15px;
      width: 15px;
      display: inline-block;
      margin-right: 5px;
    }
  }

  /***  Investment and Debt Table  ***/
  .table-input {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .help {
    color: @fps-text-secondary;
  }

  .investments-td {
    padding: 2px;

    &.main {
      width: 50%;
      min-width: 200px;
    }
  }

  tr.investor-table > td {
    vertical-align: middle;
  }

  tr.investor-table > td.pct {
    vertical-align: top;
  }

  .investments {
    display: flex;
    flex-direction: column;

    .table-input {
      flex: 1;
      margin: 4px;
    }

    .investments-amount,
    .investments-select {
      display: flex;
      margin: 0 5px;

      .padtop {
        padding-top: 5px;
      }
    }
    .interest-text {
      text-transform: uppercase;
      font-size: 12px;
      color: #333333;
    }

    .investments-amount {
      flex: 2;
    }

    .investments-select {
      width: 200px;
    }

    .debt-amount,
    .debt-select {
      display: flex;
      margin: 0 5px;
    }

    .debt-select {
      max-width: 50%;
      min-width: 40%;
    }

    .investments-actions {
      display: flex;
      align-items: center;
      width: 40px;
      justify-content: space-between;
    }
  }

  .add-investment {
    margin: 4px;

    .btn {
      margin-left: 10px;
    }
  }

  .disclaimer {
    padding: 15px;
  }

  /*** Top-Level Containers ***/

  .content > * {
    margin: 20px;
  }

  /*** Visualization Box ***/

  .exit-visual {
    position: relative;
    display: flex;
    border: 1px solid @sb-gray-light;
  }

  .graph-container {
    width: 65%;
    height: 400px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
  }

  .graph {
    .c3-xgrid-focus line {
      display: none;
    }

    .c3 svg {
      font-size: smaller;
      stroke-width: 2px;
    }

    .c3-axis {
      .tick {
        line {
          display: none;
        }
        &:nth-child(2),
        &:nth-last-child(2) {
          display: none;
        }
      }
    }

    .chart-slider-rect {
      opacity: 0.5;
      fill: black;
    }

    .tick {
      /*display: none;*/
    }
  }

  .graph-info {
    display: flex;
    flex-direction: column;
    font-size: medium;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .subtext {
      color: @fps-text-secondary;
      margin: 0;
      margin-right: 10px;
    }
  }

  .inline-data {
    display: flex;
    align-items: baseline;

    .subtext {
      color: @fps-text-secondary;
    }

    > * {
      margin: 0 5px;
    }

    .help {
      color: @fps-text-secondary;
    }
  }

  .table-item {
    .help {
      color: @fps-text-secondary;
    }
    .note {
      float: right;
      width: 90%;
    }
  }

  .stock-classes {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: center;
    padding: 20px;

    .exit-amt-field {
      display: flex;
      align-items: center;
      margin: 20px 0;

      label {
        margin-right: 20px;
      }
      .input-body {
        display: flex;
      }
      .return-info {
        margin: 20px;
      }
    }

    .exit-date-field {
      display: flex;
      align-items: center;
      margin: 20px 0;

      label {
        margin-right: 20px;
      }
      .input-body {
        display: flex;
      }
    }
  }

  /*** Table Data ***/

  .exit-table {
    display: flex;
    flex-direction: column;

    .fa-caret-down {
      color: @fps-text-secondary;
    }

    /*.table > tbody > tr > td {*/
    /*vertical-align: middle;*/
    /*}*/

    td:first-child {
      min-width: 18%;
    }
  }

  .table-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .table-title {
    margin: 0;
    margin-right: auto;
    margin-left: 10px;
  }

  .table-highlight {
    background: linear-gradient(rgba(40, 180, 40, 0.1), rgba(40, 180, 40, 0.1));
  }

  th {
    white-space: nowrap;
  }

  .ownership {
    text-align: center;
    padding-right: 18px;
    padding-left: 18px;
    margin: 4px;
  }

  .pct-th.table-highlight {
    width: 13%;
  }

  .pct-th {
    text-align: center;
  }

  .pct div {
    height: 35px;
    margin: 4px;
    text-align: right;
  }

  .returns {
    text-align: center;
    padding-left: 3%;
    padding-right: 3%;
    white-space: nowrap;
  }

  #debt-holders,
  #investors {
    th,
    td {
      &:first-child {
        width: 25%;
      }
      &:nth-child(2) {
        width: 15%;
      }
    }
  }

  #debt-holders {
    .safe,
    .npa {
      background: @sb-gray-light;
    }

    th,
    td {
      &:nth-child(2) {
        width: 80px;
      }

      &:nth-child(4) {
        width: 80px;
      }

      &:nth-child(3) {
        width: 100px;
      }
    }

    th.options,
    td.options {
      width: 15%;

      .checkradios-checkbox {
        margin: 0;
        top: 0;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > * {
          flex: 1;
          text-align: center;
        }
      }
    }
  }

  /*** Exit Scenario Specific ***/
  &.exit-scenarios {
    .exit-visual {
      .graph-container {
        width: 65%;
      }
    }
  }

  /*** Next Round Specific ***/
  &.next-round {
    .exit-visual {
      .graph-container {
        width: 65%;
        height: 435px;
        .graph {
          display: flex;
          > div {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .ownership-divider {
            border-left: 6px dotted;
            color: @fps-text-secondary;
            width: 0;
            margin: 0 45px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            opacity: 0.65;
            .fa,
            .fas,
            .far,
            .fab {
              display: table;
              position: absolute;
              font-size: 75px;
              width: 0;
              font-weight: bold;
            }
          }
        }
      }

      .graph-legend {
        font-weight: bold;
        padding: 20px 40px;
        height: fit-content;
        width: 35%;
        .stock-name {
          padding: 8px;
          .square {
            width: 20px;
            height: 20px;
          }
          &:hover {
            background-color: aliceblue;
          }
        }
      }
    }
  }
}

/*** Modal Styling ***/
.planner-modal-container {
  padding: 0 8px;
}

.planner-modal-form {
  .widget-main {
    padding: 0;
    sb-field-label {
      width: 40%;
    }
  }

  .formly-field > .sb-widget.form-group {
    border: none;
  }

  .formly-field-list-of-stakeholder,
  .formly-field-list {
    > div {
      > .widget-main {
        flex-direction: column;

        > .input-body {
          width: 100%;
        }
      }
    }
  }
}

.reconciliation-stripe {
  background-color: @fps-box-background;
}

td.reconciliation {
  padding-left: 9px !important;
}

.planner-modal {
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    justify-content: space-between;

    p,
    label {
      width: 50%;
      .divider {
        margin: 0 2px;
        font-size: small;
        vertical-align: text-top;
        font-family: serif;
        font-weight: 800;
        color: @fps-text-secondary;
        font-weight: 800;
      }
    }
  }

  &.top {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px @sb-gray-light solid;

    label:nth-child(2) {
      text-align: left;
    }
  }

  &.bottom {
    margin-top: 20px;

    &.new-stock-class {
      padding: 15px;
      background-color: @fps-box-background;

      > div:not(:only-child) {
        margin-bottom: 15px;
      }

      label {
        margin: 0;
        padding: 0;
      }

      .total {
        justify-content: center;
        background-color: white;
        color: @sb-green;
        padding: 8px;

        p {
          margin: 0;
          white-space: nowrap;
        }
      }
    }
  }
}
