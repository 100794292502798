/*
 * Styles for generic base-list-page (Data Room, stakeholders, etc.)
 */

@hide-filter: ~'(max-width: 1280px)';
@base-list-header-height: 44px;
@sbx-page-header-height: 50px;
.base-list-page {
  @filter-left-padding: 10px;

  h4 {
    margin-top: 0;
    font-size: 19px;
    font-weight: normal;
    text-transform: none;

    i {
      margin-right: 5px;
    }
  }

  .base-list-attr strong,
  .base-list-attr {
    font-size: 16px;
  }

  /*** Filter List ***/
  .or-filters {
    height: 50px;
    margin-bottom: 20px;

    .filter-deferred {
      padding: 20px 13px;
      text-align: center;
    }
    .bootstrap-switch-danger {
      background-color: @sb-orange-type;
    }
  }

  .filters {
    margin: 5px 0;
    position: fixed;
    width: @side-filter-width;
    .iter-widget-row[data-ng-repeat] {
      margin-right: 0 !important;
      padding-top: 0;
    }
    .viewspace,
    .filter-documents {
      padding: 0 15px 0 0;
    }

    .filter-padding {
      padding-left: @filter-left-padding;
    }

    .current-filters {
      background-color: @sb-gray-light;
      margin-bottom: 10px;
      display: flex;
      padding: 10px 15px 10px 10px;
      justify-content: space-between;
      align-items: center;
    }

    header {
      @header-spacing: 7px;

      .filter-counts {
        margin: 3px 0 0;
        padding: 0;
        list-style: none;

        li i {
          color: @fps-text-secondary;
          font-size: 14px;
        }

        a i {
          color: @sb-orange;
        }

        .label {
          padding: 1px 3px;
          background: @fps-list-label;
          color: @fps-text;
        }
      }

      .filter-heading-label {
        font-size: 12px;
        color: @fps-text-secondary;
        text-transform: uppercase;

        i.fa,
        i.fas,
        i.far,
        i.fab {
          margin-left: 3px;
          font-size: 14px;
        }
      }

      > .filter-heading-label {
        margin: 0 0 @header-spacing;
      }
    } // end <header>

    .filter-inputs {
      .filter-row {
        padding: 0px 0px 6px @filter-left-padding;

        .filter-row-label {
          float: left;
          width: 17%;
          font-weight: 600;
        }

        .filter-row-item {
          margin-left: 20%;
        }
      }
    }

    .filter-categories {
      @filter-checkbox-size: 12px;
      @filter-deep-offset: 10px;
      @filter-li-left-padding: @filter-left-padding + @filter-checkbox-size + 6px;

      /* Resets */
      .option > div {
        margin: 0;
      }

      .checkbox,
      .radio {
        padding: 0 17px 0 0;
      }

      li,
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      label {
        margin: 0;
        padding-right: 17px;
      }

      .checkradios-checkbox,
      .checkradios-radio {
        position: absolute;
        top: 7px;
        left: @filter-left-padding;
        float: none;
        margin: 0 !important;
      }

      /* Level One
       * Because of the chosen DOM (ul/li),
       * one must specifiy each level
       */
      ul li {
        margin-bottom: 1px;
        position: relative;
        padding: 1px 0 1px @filter-li-left-padding;
        background: @sb-gray;
        color: @sb-white;

        label {
          font-size: 16px;
          font-weight: bold;
        }
      }

      .filter-no-label {
        font-size: 16px;
        font-weight: bold;
        padding-left: @filter-left-padding;
      }

      .filter-no-hierarch label {
        font-weight: normal;
      }

      /* Level Two */
      ul ul li {
        background: none;
        color: inherit;

        label {
          font-weight: normal;
        }
      }

      /* Level Three & Beyond (increasing padding) */
      ul ul ul li {
        padding-left: @filter-li-left-padding + @filter-deep-offset;
      }
      ul ul ul ul li {
        padding-left: @filter-li-left-padding + 2 * @filter-deep-offset;
      }
      ul ul ul ul ul li {
        padding-left: @filter-li-left-padding + 3 * @filter-deep-offset;
      }
    } // end .filter-categories
  } // end .filters

  .backlink {
    margin: 3px 0 0;
  }

  /*** Header ***/
  .fixed .alerter {
    background: #fff;
    .alert {
      margin: 10px;
    }
  }
  .header {
    border-bottom: 1px solid @sb-gray-light;
    padding: 10px @filter-left-padding;
    background-color: white;
    height: @base-list-header-height;

    .base-list-header-left {
      position: relative;
    }

    .shadow.base-list-actions-btn {
      box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 5px rgba(141, 198, 63, 0.4);
    }

    @header-button-height: 20px;
    @actions-button-right: 13px;
    sb-collapsed-actions {
      position: absolute;
      top: 2px;
      right: @actions-button-right;
      .dropdown-menu {
        min-width: 280px;
      }
    }

    h1 {
      margin: 0 0px 0 0;
      padding: 0;
    }

    .sortby-label {
      padding-top: 4px;
      margin-left: 10px;
    }

    .form-dropdown {
      margin: 2px 0 0 5px;
      padding-right: 25px;

      .selected {
        padding: 2px 15px 1px;
        height: @header-button-height;
      }

      .carat {
        margin-top: -4px;
        color: @fps-text-secondary;
      }
    }

    .form-dropdown.open {
      .carat {
        margin-top: -11px;
      }
    }

    .pagination {
      .fa-chevron-left,
      .fa-chevron-right {
        padding: 3.5px 0;
      }
      margin: 1px 6px 0;
      a {
        padding: 1px 8px;
      }
    }

    .sort-page-header {
      font-size: 13px;
      width: auto;
      float: right;

      .btn {
        font-size: 13px;
      }

      i.fa,
      i.fas,
      i.far,
      i.fab {
        font-size: 0.9em;
      }

      .batch-summary {
        padding: 3px 4px 0 10px;
      }
    }
  }

  .sb-list.has-header {
    .main {
      padding-top: @sbx-page-header-height + @base-list-header-height;
    }
  }
  .main.has-header {
    padding-top: @sbx-page-header-height + @base-list-header-height;
  }
  .main {
    padding-top: @sbx-page-header-height;

    .alert {
      margin: 15px 15px;
    }
  }

  .result-cards {
    background-color: @sb-gray;
    padding: 10px 20px;

    .relations-list {
      img {
        max-width: 100px;
        max-height: 100px;
        float: none;
        display: inline;
        padding: 15px 0;
      }
      .properties dd {
        text-transform: capitalize;
      }
    }

    .list-group {
      margin: 0;
    }
    .list-group-item {
      margin: 10px 0;

      &.empty {
        background: transparent;
        font-size: 20px;
        letter-spacing: 0.2px;
        color: @fps-text-secondary;
        padding: 15px;
        line-height: 54px;

        > i {
          font-size: 27px;
          margin-right: 10px;
        }
      }
    }

    img {
      max-width: 35px;
      max-height: 35px;
      display: block;
      float: left;
    }

    strong.avatar-included {
      display: block;
      margin: 4px 0 0 45px;
    }

    .roles {
      color: @fps-text-secondary;
      font-size: 90%;
      clear: both;
      padding-top: 8px;
    }

    .actions {
      margin-top: 10px;
    }

    dl {
      margin: 0;
      padding-left: 15px;
      font-size: 85%;
      border-left: 3px dotted @sb-gray-light;
    }

    dt {
      color: @fps-text-secondary;
      font-weight: normal;
      text-transform: uppercase;
    }

    dd,
    dt {
      display: inline;
    }

    dd:after {
      content: ' ';
      margin-bottom: 3px;
      display: block;
    }
  }
}

.base-list-page .result-cards {
  padding: 10px 0;
  margin: 0 20px;
  background-color: white;

  .list-group-item {
    border: none;
    margin: 0;
    padding: 5;
  }
}

.base-list-page div.header {
  .sort,
  .filter {
    .chosen-results {
      font-size: 13px;
    }
    .chosen-single {
      font-size: 13px;
      height: 25px;
      line-height: 1;
      text-align: center;
      margin-top: 5px;
      div b {
        margin: -5px;
      }
    }
    padding: 0 5px;
    margin-top: -5px;
  }
}
.base-list-page sb-filtered-list-paging {
  .batch {
    margin-top: -5px;
    .batch-size-selector {
      .chosen-results {
        font-size: 13px;
      }
      .chosen-single {
        text-transform: uppercase;
        color: @fps-text-secondary;
        border: none;
        font-size: 13px;
        height: 25px;
        line-height: 1;
        text-align: center;
        margin-top: 5px;
        div b {
          margin: -5px;
        }
      }
    }
  }
}

@side-filter-width: 310px;
.base-list-page {
  .sb-list.has-header {
    .filters {
      top: @sbx-page-header-height + @base-list-header-height + @header-height;
    }
  }
}
.base-list-page .side {
  display: block;
  padding-top: 50px;
  min-height: 300px;

  @media (max-width: 1280px) {
    padding-top: 0;
    min-height: 0;
  }

  .item-count {
    line-height: 17px;
    float: right;
    padding-top: 10px;
    padding-right: 10px;
    color: @fps-text-secondary;
  }

  sb-no-input-radio {
    margin-left: -15px;
  }

  .filters {
    top: @sbx-page-header-height + @header-height;
    bottom: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 15px;
    width: @side-filter-width;

    .search-field input[type='text'] {
      margin-bottom: 10px;
    }
    .filter-categories-new {
      overflow-x: hidden;
      width: 300px;
      min-height: 500px;
      [data-sb-check-list] label {
        font-weight: normal;
      }
      [data-sb-check-list][data-sb-options-type='switch'] {
        padding-top: 0;
        label {
          text-transform: uppercase;
          color: @fps-text-secondary;
          font-size: 12px;
        }
      }
      ul {
        margin-bottom: 0;
        li.filter-field {
          display: block;
          padding: 1px 0 1px 1px;
          padding-left: 25px;
        }
        li.field {
          padding-left: 0;
          background: #ffffff;
          color: @fps-text;
        }
        list-style: none;
        padding-left: 0;
        li {
          margin-left: 0;
          padding: 1px 0 1px 10px;
        }
        li ul {
          li.filter-no-label {
            background-color: @sb-gray-light;
            color: @fps-text;
            text-transform: uppercase;
            font-size: 12px;
            padding: 5px 8px;
            margin-bottom: 10px;
            position: relative;
            a.btn {
              float: right;
              font-size: 12px;
              .fa,
              .fas,
              .far,
              .fab {
                padding-right: 5px;
                font-size: 13px;
              }
            }
          }
        }
      }
      .tree.filter-field li.filter-no-label {
        margin-bottom: 0;
      }
    }
  }
}

.base-list-page .has-message {
  .filters {
    top: 125px;
  }
  .main {
    padding-top: 80px;
  }
}

.user-cards {
  padding: 0;
}
.list-striped > li:nth-child(odd) {
  background-color: @gray-lighter;
}

.filter-field.search-field {
  position: relative;
  margin-left: 10px;
  input {
    width: 280px;
    padding-right: 40px;
    &[placeholder] {
      text-overflow: ellipsis;
    }
  }
  button,
  span {
    position: absolute;
    right: 5px;
    top: 4px;
    color: @sb-green-type;
    i:hover {
      color: @sb-green-bright;
    }
  }
  .btn-link {
    padding: 5px;
    right: 30px;
  }
  .clear {
    cursor: pointer;
  }
}

.filter-field.date-range-field {
  background: @fps-list-label;
  margin: 0 10px 10px 10px;
  padding: 10px 10px 0;

  ul {
    padding: 0;

    > li {
      padding: 0 !important;
    }
  }

  li.filter-no-label {
    background: none !important;
    padding: 0 !important;
    line-height: 22px;
  }

  .date-label {
    font-size: 12px;
    width: 35px;
  }

  .date {
    input {
      width: 180px !important;
    }

    .uib-datepicker-popup {
      min-width: 235px;
    }
  }
}

.finance-modal-buttons {
  float: right;
  padding-right: 10px;
}

.sb-list {
  .sb-list-panel {
    z-index: @z-index-1;
  }

  .overlay {
    .spinner {
      margin-left: -30px;
      padding-top: 240px;
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1001;
      text-align: center;
      vertical-align: middle;
      font-size: 32px;
    }
  }
  .employee-item {
    .column-info {
      margin-top: 7px;
    }
    .phone {
      text-align: center;
    }
    .missing {
      font-style: italic;
      font-size: 10px;
      color: @fps-text-secondary;
    }
    .avatar-initials {
      width: 35px;
      height: 35px;
      padding-top: 8px;
      float: left;
    }
    .employee-name-position {
      margin-left: 45px;
      a {
        font-size: 18px;
        font-weight: normal;
        margin-bottom: 5px;
      }
    }
    .date-label {
      color: @fps-text-secondary;
      font-weight: normal;
      text-transform: uppercase;
      font-size: 12px;
      padding-right: 5px;
    }
  }
  .cument-card {
    padding: 10px;
    .full {
      width: 100%;
    }
  }
  .document-cards {
    padding-left: 0;

    .document-actions {
      sb-financing-document-action {
        padding-right: 5px;
      }
    }
  }
  .finance-document-cards .document-item {
    min-height: 140px;
  }
  sb-document-status i {
    width: 25px;
  }
}

sb-filtered-list {
  *[data-sb-options-type='switch'] > label,
  *[sb-options-type='switch'] > label {
    display: flex;
    align-items: center;
  }

  .date-filter {
    display: inline-flex;
    padding-bottom: 10px;

    .date input,
    .date-widget {
      width: 185px !important;
      height: 27px;
    }
    .date-label {
      margin-top: 5px;
      width: 45px;
    }
    .input-group-addon {
      width: 55px;
      border-radius: 0;
    }
  }
}

sb-list-mobile-menu {
  display: none;

  .nav-bg {
    width: 100%;
    height: 100%;
    z-index: @z-index-5;
    position: fixed;
    top: 0;
    left: 0;
  }
  nav {
    @menu-width: @side-filter-width;
    @nav-padding: 10px;
    @action-bar-size: 2 * @nav-padding + 25px;
    background: #fff;
    .generic-animation(0.2s);
    position: fixed;
    top: 0;
    left: -@menu-width;
    height: 100%;
    width: @menu-width;
    z-index: @z-index-5;
    padding-bottom: @action-bar-size;
    &.opened {
      left: 0;
      -webkit-box-shadow: 3px 0px 3px 0px rgba(0, 0, 0, 0.34);
      box-shadow: 3px 0px 3px 0px rgba(0, 0, 0, 0.34);
    }

    /*    Containers    */
    .menu-container {
      display: flex;
      flex-direction: row;
    }
    .menu-body {
      margin-top: @header-height;
      > header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 5px;
        align-items: baseline;
        background: @fps-list-label;
        position: relative;
        width: 100%;
        .close {
          top: @nav-padding;
          right: @nav-padding;
        }

        h1 {
          margin: 5px;
          margin-right: auto;
        }
      }

      .nav-content {
        position: static;
        width: 100%;
        overflow-y: auto;

        .side {
          padding-top: 0px;

          .filters {
            position: relative;
            height: 90%;
            top: 0px;
            margin: 0px;
          }
        }
      }
    }

    .menu-lip {
      display: flex;
      align-items: center;
      width: 35px;
      padding-left: 4px;
      padding-right: 4px;
      background-color: fade(@sb-gray-light, 12%);

      &:hover {
        background-color: fade(@sb-gray-light, 36%);
      }
    }

    /*    End Containers    */

    /*    Local Styling    */
    h4 {
      border-bottom: 1px solid @sb-gray;
      font-size: 25px;
      padding-bottom: 10px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 35px;
      position: relative;
    }
  }
}

sb-data-room-page {
  sb-tree {
    .listingcontainer {
      display: block;
      padding: 5px 0 5px 40px;
      margin-left: -30px;
    }
    .selected:before {
      content: ' ';
      padding: 20px 0;
      margin-top: -5px;
      position: absolute;
      display: inline;
      left: 5px;
    }
    .selected.listingcontainer {
      background-color: @fps-list-selected;
    }
    label {
      display: inline;
      position: relative;
      left: 15px;
      top: 0;
    }
    .fa-folder {
      display: none;
    }
    .sbf-shoobx-generated {
      display: none;
    }
  }
  .dataroom-options {
    .btn {
      padding: 5px;
    }
    .icons {
      text-align: right;
      .fa,
      .fas,
      .far,
      .fab {
        margin-right: 10px;
      }
    }
    font-size: 24px;
    .fa,
    .fas,
    .far,
    .fab {
      font-size: 24px;
    }
  }
}

@media @hide-filter {
  display: none;

  sb-slide-menu {
    .sb-slide-menu(@side-filter-width);
    display: flex;
  }
}
