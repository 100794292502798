/*** Board Circle ***/
*[data-sb-board-circle],
sb-board-circle {
  @board-circle-size: 400px;
  margin: 10px auto;
  width: @board-circle-size;
  height: @board-circle-size;
  position: relative;
  .seat-circle {
    border: 2px solid @sb-gray-light;
    border-radius: 50%;
    text-align: center;
    position: absolute;
  }
  .seat {
    @seat-ui-speed: 1s;
    @seat-transition:
      transform @seat-ui-speed ease-in-out,
      opacity @seat-ui-speed ease;
    @seat-size: 80px;
    @seat-init-transform: rotate(0deg) translate(0, -160px) rotate(0deg);
    -webkit-transition: @seat-transition;
    transition: @seat-transition;
    -webkit-transform: @seat-init-transform;
    transform: @seat-init-transform;
    width: @seat-size;
    height: @seat-size;
    text-transform: uppercase;
    display: table;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -(@seat-size / 2);
    margin-left: -(@seat-size / 2);
    &.ng-leave.ng-leave-active,
    &.ng-enter:not(.ng-enter-active) {
      -webkit-transform: @seat-init-transform !important;
      transform: @seat-init-transform !important;
      opacity: 0;
    }
    &.ng-leave,
    &.ng-enter.ng-enter-active {
      opacity: 1;
    }
    .board-face {
      width: @seat-size;
      height: @seat-size;
      border-radius: 50%;
      overflow: hidden;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
    > .board-seat-content {
      background: #fff;
      cursor: pointer;
      color: @fps-text-secondary;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      min-height: 100%;
      min-width: 100%;
      position: relative;
    }

    .btn {
      border-radius: 50%;
      border: solid 2px #fff;
      background: #fff;
      position: absolute;
      top: 1px;
      right: 1px;
    }
  }
  .seat-no-img > .board-seat-content {
    background: @sb-green;
    span {
      font-size: 28px;
      color: #fff;
    }
  }
  .empty-seat {
    span i {
      position: absolute;
      text-align: center;
      left: 0;
      width: 100%;
      display: block;
    }
    .fa-user {
      font-size: 62px;
      top: 5px;
    }
    .fa-question {
      font-size: 25px;
      color: #fff;
      top: 9px;
    }
  }
  .board-circle-origin {
    @origin-size: (@board-circle-size / 2);
    width: @origin-size;
    height: @origin-size;
    top: 50%;
    left: 50%;
    margin-left: -(@origin-size / 2);
    margin-top: -(@origin-size / 2);
    padding: 30px 0 0;
  }
  .board-circle-info {
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .board-circle-logo {
    font-size: 55px;
    height: 70px;
    width: 85%;
    margin: 10px auto;
    img {
      display: block;
      margin: 0 auto;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
