@import 'thirdparty/bs3-custom-buttons.less';

/* Page Container Selectors */
@status-widget-body: ~'sb-process-status-widget .modal-body:not(.text-center) > div';
@listpage-header: ~'.header > .row';
@list-item: ~'.list-group-item > .row';
@data-room-item: ~'.document-card > div > div.row';
/* End Page Container Selectors */

/* Portrait and Landscape */
@media only screen and (max-width: 1280px) {
  .corporate-actions {
    > .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px;
    }

    > .content {
      margin-left: 0px;

      > .row {
        margin-left: 0px;
        display: flex;
        flex-direction: row;
      }
    }
  }

  @{status-widget-body} {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    > .left-col {
      flex: 3;
      width: 100%;
      padding-right: 0px;
    }

    > .right-col {
      flex: 1;
      width: 100%;
    }
  }

  sb-data-room-page {
    @{listpage-header} {
      display: flex;
      flex-direction: row;
      align-items: center;
      /*margin-top: 6px;*/

      div {
        width: auto;
      }

      .base-list-header-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-right: auto;

        sb-collapsed-actions {
          display: none;
        }
      }

      .sort-page-header {
        margin-left: auto;
      }
    }

    .dr-breadcrumbs,
    .dr-listing-header {
      display: flex;
      flex-direction: row;
      align-items: center;

      div:first-of-type {
        margin-right: auto;
      }
    }

    .dr-breadcrumbs {
      align-items: stretch;
    }
  }

  sb-manage-stakeholders-page,
  sb-entity-relationships-page,
  sb-financing-documents-page,
  sb-workflows-page {
    .header {
      /* overidden by base-list-page.less */
    }

    @{listpage-header} {
      display: flex;
      flex-direction: row;
      align-items: center;

      .base-list-header-left {
        width: 30%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .sort-page-header {
        width: 70%;

        sb-filtered-list-sort {
          display: none;
        }
      }
    }
  }

  sb-workflows-page {
    td {
      width: 60%;
    }
  }

  sb-entity-relationships-page {
    ol.list-striped.relations-list {
      padding-left: 0px;
    }

    .row {
      margin-left: 0px;
    }

    @{list-item} {
      display: flex;
      flex-direction: row;
      width: 100%;

      > div {
        &:first-child {
          width: 150px;
        }

        &:last-child {
          width: 100%;
          padding-left: 0px;
          > .row > .row {
            display: flex;
            flex-direction: row;
            width: 100%;

            div {
              width: 40%;
            }
          }
        }
      }

      .center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  sb-data-room-page {
    @{data-room-item} {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      > div:first-child {
        margin-right: auto;
      }

      > div.action-icons {
        display: none;
      }
    }
  }

  @search-bar-height: 60px;
  #hr-grants {
    .hr-info-dotted {
      display: flex;
      flex-direction: row;
      width: 100%;

      > div {
        width: 33%;
      }
    }

    #hronboarding {
      display: flex;
      flex-direction: column;
      overflow-x: scroll;

      form {
        align-self: flex-end;
        position: absolute;
        height: @search-bar-height;
      }

      table {
        margin-top: @search-bar-height;

        .fa,
        .fas,
        .far,
        .fab {
          display: inline;
        }
      }
    }
  }

  div.content .corporate-actions {
    .content {
      margin-left: 0px;
    }

    > .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0px;
    }
  }

  .relationship-management {
    > header {
      display: flex;
      flex-direction: row;

      > div:first-child {
        width: auto;
        margin-right: auto;
      }
    }
  }
}

@media only screen and (max-width: 830px) {
  body > footer .links {
    float: none;
    margin: 0;
    .copyright {
      border: none;
    }
  }
  .navbar .brand {
    padding: 0;
    padding-top: 27px;
    width: 60px;
    background-image: url('./../img/icon.png');
    background-repeat: no-repeat;
    background-size: 35px;
    background-position: 13px 4px;
    font-size: 90%;
    font-weight: bold;
    text-align: center;
  }

  #page-title {
    width: auto;
    color: white;
    text-overflow: clip;
    display: inline-block;
  }

  .form-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
  }
  .content {
    margin-top: 5px;
    font-size: 90%;
    background-color: white;
    padding: 5px 5px;
  }

  /* Put signature clear button on top of the page. */
  #signature-capture-clear {
    position: absolute;
    top: 0.1em;
    right: 2em;
  }

  .process {
    > header {
      position: static;
      padding-top: 10px;
      padding-bottom: 20px;
      > .container {
        padding: 0 0 10px;
        .row {
          border: none !important;
        }
      }
    }
    > form {
      padding-top: 20px !important;
    }
    > form.form-with-aside {
      margin-left: 0;
    }
    sbx-process-wizard,
    #sigEditorShow,
    .wizard,
    aside {
      display: none;
    }
    sbx-process-status {
      position: static;
      margin-top: 30px;
    }
    /* Make sure the white overlay that says "Working..." covers the screen */
    .process-loading.process-includes-summary {
      top: 0;
      height: 100%;
    }
    /* The copyright footer doesn't exist on mobile, so the process footer
    should stick to the bottom */
    footer {
      bottom: 0;
    }
  }

  body > footer > .content {
    background: none;
  }

  select.hidden-off-screen {
    clip: auto !important;
    min-width: 200px;
    position: static !important;
  }

  sb-manage-stakeholders-page,
  sb-entity-relationships-page,
  sb-financing-documents-page,
  sb-workflows-page {
    @{listpage-header} {
      .base-list-header-left {
        width: 100%;
        margin-right: auto;
      }

      .sort-page-header {
        width: auto;

        .backlink {
          display: none;
        }
      }
    }
  }

  sb-workflows-page {
    .workflow-cards {
      padding-left: 0px;

      td.details {
        display: none;
      }
    }
  }

  .corporate-actions {
    > .content {
      margin-left: 0px;

      > .row {
        margin-left: 0px;
        flex-direction: column;
      }
    }

    > .right-col {
      flex: 1;
      width: 100%;
    }
  }

  sb-filtered-list .header {
    height: auto;

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .base-list-header-left {
      width: auto;
    }

    .sort-page-header {
      display: none;

      sb-filtered-list-paging {
        display: none;
      }

      .backlink {
        display: none;
      }
    }
  }

  #hr-grants {
    .heading {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: flex-start;
      flex-wrap: wrap;

      .backlink {
        display: none;
      }
    }
  }

  /* Reduce horizontal padding for signature preview on mobile */
  .sign-documents-workitem .signature-preview-modal .signature-review {
    padding: 10px;
  }

  /* Make table fit into horizontal space available */
  .create-and-edit-items-table .table {
    word-break: break-word;
  }

  /* Display table headers vertically */
  .striped-table-header {
    writing-mode: vertical-rl;
    text-orientation: sideways;
    max-height: 80px;
    transform: rotate(180deg);
  }
}

@media only screen and (max-width: 425px) {
  .process > footer {
    position: relative;

    .scroll-down {
      display: none !important;
    }
  }
  .dashboard {
    .actions {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .corporate-actions {
    .content {
      margin-left: 20px;
    }
  }
}

/* Portrait */
@media only screen and (max-width: 800px) and (orientation: portrait) {
  .portrait-orientation {
    display: block;
  }
  .landscape-orientation {
    display: none;
  }
}

/* Landscape */
@media only screen and (max-width: 800px) and (orientation: landscape) {
  .portrait-orientation {
    display: none;
  }
  .landscape-orientation {
    display: block;
  }
}
