sb-filtered-list-column-sort {
  cursor: pointer;

  .column-sort-icon {
    margin-left: 4px;
    display: none;
    font-size: 20px;
    padding: 0 4px;
    vertical-align: middle;
    cursor: pointer;

    &.active {
      display: inline-block;
    }
  }
}
