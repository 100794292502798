sb-field-configuration-listing {
  .config-item {
    border: 1px solid @sb-gray-light;
    border-left: 10px solid @sb-gray-light;
    padding: 5px 10px;
    margin-bottom: 5px;
    .input-body textarea {
      min-height: 70px;
    }
    > main {
      margin-bottom: 10px;
    }
    &.selected {
      border-color: @sb-blue;
    }
    &.error {
      border-color: red;
    }

    &:not(.open) {
      > main {
        display: none;
      }
    }
  }
  .parameter,
  .config-item {
    > header {
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      .fa,
      .fas,
      .far,
      .fab {
        color: @fps-text-secondary;
        margin-top: 3px;
      }
      > span {
        flex: 1;
        min-width: 0;
        word-wrap: break-word;
        margin-right: 5px;
      }
      > .fa,
      > .fas,
      > .far,
      > .fab,
      > button:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
  .parameter {
    > header {
      cursor: auto;
      padding: 0 2px;
    }
  }
  sb-group-field-configuration {
    > div {
      background: @fps-group-background;
      padding: 10px;
      margin-top: 10px;
      > h4 {
        text-transform: none;
        font-size: 16px;
        margin-top: 0;
      }
      .form-group {
        margin: 0 !important;
      }
      .widget-main {
        padding: 0 !important;
      }
    }
  }
}

sb-field-customized-pdf-viewer {
  display: block;
}

sb-pdf-form-creator,
sb-docx-form-creator {
  display: block;
  sb-collapsed-actions {
    margin: 0 0 15px;
    display: block;
    .btn {
      padding: 12px;
      height: auto;
      font-size: 16px !important;
      width: 100%;
      display: block;
    }
    button-content {
      display: flex;
      text-align: left;
      align-items: center;
      > span:not(.fa, .fas, .far, .fab) {
        flex: 1;
      }
    }
    .dropdown-menu {
      min-width: 300px !important;
    }
  }
  sb-field-customized-pdf-viewer {
    margin-top: 0;
    > * {
      height: 100%;
    }
  }
  .draw-alert {
    background: @fps-group-background;
    padding: 5px;
    font-size: 13px;
  }
  .italic {
    font-style: italic;
  }
  > .col-xs-8,
  > .col-xs-4,
  > sb-form {
    margin-bottom: 20px;
  }
  .field-list {
    overflow-y: auto;
  }
  > sb-alert-banner {
    margin: 0 15px;
  }
  > .controls {
    clear: both;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid @sb-gray;
    padding: 10px 15px;
    > .btn:only-child {
      margin-left: auto;
    }
  }
}
