/* -----[ Counsel Involvement Panel ]------------------------------- */
.counsel-involvement-panel {
  .company-title {
    min-height: 40px;
    color: @sb-white;
    padding-left: 4px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 22px;
    margin-top: 10px;
    background-color: @sb-green;
  }
}

.stakeholders-panel {
  .timestamp {
    float: right;
  }
  h4.no-margin {
    margin: 0;
  }
}

/* -----[ Dashboard Panel: Paylocity Payroll ]------------------------- */

ul.paylocity-boxes li {
  display: inline-block;
}

ul.paylocity-boxes li.paylocity-banner {
  width: 93%;
  height: 26px;
}

div.paylocity-box {
  display: block;
  position: relative;
  width: 105px;
  height: 107px;
  overflow: hidden;
  margin: 0;

  border: 3px solid @sb-gray-lighter;
  background: @fps-box-background;

  .description {
    display: table;
    height: 56px;
    width: 100%;
    text-transform: uppercase;
    font-size: 95%;
    color: @fps-text-secondary;
    text-align: center;
  }

  .value {
    position: absolute;
    padding-top: 4px;
    height: 40px;
    bottom: 0;
    width: 100%;
    text-transform: uppercase;
    color: @fps-text-secondary;
    font-size: 26px;
    text-align: center;
  }

  .active {
    color: @sb-green-type;
  }
}

.paylocity-logo {
  width: 100%;
  text-align: right;

  a > img {
    width: 75px;
    margin-right: 30px;
  }
}
