.shared-legend {
  list-style: none;
  margin: 0;
  padding: 0;
}

.shared-legend-item {
  cursor: pointer;
  opacity: 0.3;

  &.active {
    opacity: 1;
  }
}

.shared-legend-color-legend {
  display: inline-block;
  width: 14px;
  height: 14px;
}
