// TBD: Need to add list styles for
// "lite" and "lite-stacked" once
// those start to be used somewhere.
// Grover has the specifications.

h3.list-heading {
  margin-bottom: 0px;
}

table.list-normal {
  margin-bottom: 20px;

  > tbody > tr {
    border-bottom: 1px solid @sb-gray-lighter;
    padding: 0px;

    th {
      color: @fps-text-secondary;
      font-size: 14px;
      text-transform: uppercase;
      vertical-align: bottom;
    }

    td {
      color: @fps-text;
      font-size: 16px;
      vertical-align: bottom;
    }
  }
}
