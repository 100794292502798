/**
 * Finance Page Styles
 */

.finance-notes-schedule .excel-export {
  float: right;
  margin-right: 10px;
}

.notes-schedule {
  .inactive {
    text-decoration: line-through;
  }
}

strong.finance-notes-signatures {
  &.fa,
  &.fas,
  &.far,
  &.fab {
    font-size: 14px;
  }
}

strong.finance-notes-signatures.fa-warning:before {
  color: #f5d603;
}

strong.finance-notes-signatures.fa-check:before {
  color: #8dc63f;
}

.disclaimer-margin {
  margin-left: 30px;
}
