.payroll-page {
  .group-conflicts {
    border: 3px solid @sb-gray-light;
    margin-bottom: 15px;

    table {
      margin-bottom: 0;

      td {
        min-height: 32px;
        vertical-align: middle;
      }
    }

    .resolve-button {
      margin: 8px 8px 8px 20px;

      .fa,
      .fas,
      .far,
      .fab {
        padding-right: 0;
        margin-right: 5px;
      }
    }
  }

  .group-conflicts-title {
    margin: 0;
    padding: 8px 11px 11px 11px;
    background: @sb-gray-light;
    text-transform: initial;
  }

  .changed {
    .radio {
      background: lighten(@sb-green, 25%) !important;
    }
  }

  .radio {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 400;
    min-height: 32px;
  }

  .checkradios-radio {
    top: 0;
    margin-top: 3px !important;
  }
}

.paylocity-enroll-modal {
  .missing-data {
    color: @sb-red-type;
  }
}
