/* -----[ Entity Relationship ]--------------------------------------------------- */

.company-relations li.relation:last-child {
  margin-bottom: 10px;
}

.multi-redirect {
  margin-top: 80px;
}

.larger-modal-form {
  .modal-dialog {
    width: 790px;
  }
  .form-group {
    border: none;
  }
  .form-dropdown {
    margin-left: 20px;
  }
}

.left {
  text-align: left;
}

.add-team-member-modal {
  .modal-dialog {
    width: 630px;
    .explanation {
      font-weight: 300;
    }
    label.radio {
      margin-bottom: 20px;
    }
    label.slight {
      font-weight: 300;
    }
  }
}

.welcome-form {
  margin-right: 10px;
}

.entity-relations-header {
  background-color: @fps-box-header-background;
  border-bottom: 1px solid @sb-gray-light;
  margin-top: 5px;
  background-color: white;

  @top-padding: 10px;

  sbx-row {
    min-height: 100px;
    padding: 10px 0px 10px 0px;
  }
  sbx-row.source-header {
    height: 40px;
    min-height: 40px;
    text-align: left;
  }

  .foreign-entity-image {
    align-items: flex-start;
    display: flex;
    float: left;
    justify-content: center;
    text-align: left;
    img.logo-medium {
      display: block;
      height: auto;
      max-height: 90px;
      max-width: 98%;
      width: auto;
    }
    img.logo-large {
      display: block;
      height: auto;
      max-height: 120px;
      max-width: 98%;
      width: auto;
    }
    .rel-large-building {
      align-items: center;
      display: flex;
      font-size: 60px;
      height: 100px;
      justify-content: center;
      padding: 40px;
      width: 100px;
    }

    .rel-medium-building {
      align-items: center;
      display: flex;
      font-size: 30px;
      height: 50px;
      justify-content: center;
      padding: 20px;
      width: 50px;
    }

    .relationship-count {
      text-transform: uppercase;
      font-size: 95%;
      color: @fps-text-secondary;
      margin-top: 5px;
    }
  }
  .foreign-entity-detail-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    > * {
      padding-bottom: 5px;
    }

    .switch-to-relationship {
      width: fit-content;
      flex-direction: end;
    }

    .foreign-entity-title {
      font-size: 24px;
      font-weight: bold;
      color: @sb-gray-darker;
      margin: 0;
    }

    .relationship-contact-info {
      color: @fps-text-secondary;

      > .relationship-contact-info-header {
        font-size: 14px;
        text-transform: uppercase;
      }

      sbx-edit-stakeholder {
        padding-left: 5px;
      }
    }

    .fen-info-contact-details {
      flex: auto;
      .fen-info-contact-detail-separator {
        padding: 0px 2px 0px 2px;
      }
      .fen-info-contact-detail-separator:last-child {
        display: none;
      }
    }
    .contact-user {
      color: @sb-gray-darker;
    }
    .contact-email {
      font-size: 14px;
    }
    .foreign-entity-partner-details {
      color: @sb-gray-darker;
    }
  }
  .relationship-actions {
    padding-top: @top-padding;
    button {
      float: right;
    }
    i {
      color: @sb-green-type;
    }
    .dropdown-menu {
      margin-top: 20px;
    }
  }
  .source-relationship-actions {
    padding-top: @top-padding;
    i {
      color: @sb-green-type;
    }
  }
}

.relationships-permissions {
  list-style-type: none;
  padding: 0px;
  > li {
    padding: 10px 15px;
  }
}

.member-actions i {
  color: @sb-green-type;
}

.edit-sig-block {
  height: 300px;
  textarea {
    height: 100px;
    margin-bottom: 20px;
  }
}

.relationship-data {
  margin-top: 10px;
  h3:first-child {
    margin-top: 0;
  }
  .relationship-metadata {
    ul {
      list-style: none;
      margin-bottom: 20px;
      padding: 0;
    }
  }
  .relationship-main-management {
    margin-bottom: 40px;

    .permissions-table {
      margin-bottom: 30px;
    }

    .access-suspended {
      color: @sb-red;
    }
    .relationship-permissions-table {
      .access-suspended {
        font-style: italic;
        color: inherit !important;
      }

      .member-card {
        min-width: 200px;
      }
      .team-member-row td {
        vertical-align: middle;
      }
      td.member-signature,
      th.member-signature {
        text-align: center;
      }
      .sigpreview {
        color: @sb-green-type;
      }
    }

    .uninvited-pending-members {
      display: flex;

      .uninvited-pending-members-alert {
        padding-right: 10px;
        i {
          color: @sb-yellow;
        }
        font-size: 30px;
      }
    }
  }

  .relationship-fund-container {
    .funds {
      padding: 5px;
    }
  }
  .fund-subrow {
    display: flex;
  }

  .investor-names,
  .fund-title {
    line-height: 1.2;
    padding-top: 2px;
  }

  .fund-icon,
  .relationship-arrow {
    text-align: center;
    width: 25px;
  }

  .fund-icon {
    color: @fps-text-secondary;
  }

  .edit-icon {
    text-align: center;
    width: 55px;
  }

  .investor-names {
    flex: 1;
    font-size: 14px;
  }

  .relationship-arrow {
    color: @fps-text-secondary;
    font-family: Shoobx;
  }
}

.relationship-management {
  form {
    width: 300px;
  }

  dl {
    align-items: baseline;
    display: flex;
    flex-direction: row;
  }

  dt,
  .relationship-metadata h5 {
    color: @fps-text-secondary;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 14px;
  }
  dt {
    padding-right: 2px;
    display: inline;
  }
  dd {
    display: inline;
    padding-right: 20px;
  }

  padding: 0 20px;
  sb-relationship-permissions-table {
    th:first-child,
    td:first-child {
      text-align: center;
      width: 20px;
    }
    .fa-edit-space {
      margin-left: 10px;
    }
  }

  .team-primary {
    color: @sb-green;
  }
}

.fund-member-actions,
.member-actions {
  width: 20px;
}

.fund-signers {
  padding: 10px;
}

sb-relationship-access-page {
  display: block;
  .info-message i {
    color: @sb-blue;
  }
}

.relationship-view-button {
  padding-left: 15px;
  float: right;
}

.diligence-bar {
  background-color: @sb-gray-light;
  padding: 15px;
  height: 50px;

  img {
    float: left;
    margin-right: 5px;
    margin-top: -18px;
  }
  button {
    margin-left: 10px;
    margin-top: -3px;
  }
}

.diligence-notes {
  list-style-type: none;
}

.diligence-log {
  .overlay {
    .spinner {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1001;
      text-align: center;
      font-size: 24px;
    }
  }
}

.diligence-report-modal {
  .ip-address {
    cursor: default;
  }
  .toggle-collapse {
    cursor: pointer;
  }
  .target-report .table {
    > tbody:nth-child(odd) {
      > tr > td {
        background-color: @table-bg-accent;
      }
    }

    > tbody + tbody {
      border-top: 0px;
    }

    th.toggle-column {
      width: 1em;
    }
    th.date-column {
      width: 13em;
    }
    th.user-column {
      width: 13em;
    }
    th.action-column {
      width: 15em;
    }
  }

  div.source-report {
    border: 1px solid @sb-gray;
    margin-bottom: 22px;

    > .report-header {
      margin-top: 0px;
      padding: 4px;
      padding-left: 20px;
      background-color: @sb-gray;
      width: 100%;
      > h4 {
        margin: 0px;
        line-height: 20px;
      }
    }
    > .table {
      margin-bottom: 0;

      th.date-column {
        width: 13em;
      }

      th.location-column {
        width: 28em;
      }
    }
  }
}

.relationships-welcome {
  > table {
    width: 100%;
    margin-bottom: 15px;
  }

  > tr {
    td:first-child {
      padding-left: 20px;
    }

    td:last-child {
      padding-right: 20px;
    }

    td.disabled {
      opacity: 0.5;
    }
  }

  > tbody {
    tr {
      &:nth-child(odd) {
        background: @fps-table-odd;
      }

      td {
        position: relative;
        padding-right: 11px;

        &.right {
          text-align: right;
        }

        &:last-child {
          padding-right: 10px;

          &::after {
            display: none;
          }
        }

        &::after {
          content: '';
          position: absolute;
          top: 4px;
          bottom: 4px;
          right: 0;
          width: 1px;
          background: #cfd9d9;
        }
      }
    }
  }

  > th {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    color: #333333;

    &.center {
      text-align: center;
    }

    &.has-sorters {
      cursor: pointer;

      &:hover {
        background: @fps-table-hover;
      }
    }
  }

  > th,
  > td {
    padding: 10px;
  }

  > .sort-button {
    padding-left: 10px;
  }

  > .sbx-no-rows {
    padding: 10px;
  }
}

sb-entity-relationships-page {
  .rl-actions-col {
    display: flex;
    flex-wrap: nowrap;
    float: right;
    padding-right: 25px;
    justify-content: flex-end;

    > div {
      padding-right: 5px;
      display: flex;
      flex-basis: 0;
    }
  }
}

.wait-for-approval-msg {
  color: @sb-gray-darker;
  text-transform: none;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 10px;
}

.signature-popover {
  max-width: 345px;
}
