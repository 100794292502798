@import '../thirdparty/bs3-custom-vars.less';

.rml-content .dla_public_Indent {
  text-indent: 0.5in;
}

.rml-content .dla_public_NoIndent {
  text-indent: 0in;
}

.rml-content .dla_public_BlockIndent {
  text-indent: 0in;
  margin-left: 1in;
  margin-right: 1in;
}

.rml-content .dla_public_Level1 {
  text-indent: 0.5in;
}
.rml-content .dla_public_Level2 {
  text-indent: 1in;
}

.rml-content .dla_public_Level3 {
  text-indent: 1.5in;
}
