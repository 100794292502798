.message-template {
  .taxpayer-address {
    padding-left: 120px;
    margin-bottom: 0px;
  }

  .tax-attention {
    padding-left: 5px;
    font-size: 14px;
    color: @fds-orange-700;
    .fa-flag {
      color: @fds-orange-700;
    }
  }
}
