sb-alert-banner.employee-profile-banner .alert {
  margin: 10px 15px 22px;
}

.employee-profile {
  font-family: @font-family-sans-serif;

  .employee-profile-content-wrapper {
    margin: 0 20px;
  }

  .employee-profile-content {
    color: @sb-white;
  }

  .inner {
    margin: 0 20px;
  }

  .field-title {
    font-size: @font-size-small;
    text-transform: uppercase;
    margin-right: 5px;
  }

  .action-row {
    display: block;
    text-align: right;
    margin-bottom: 10px;
    padding-right: 2.5px;
  }

  .centered {
    text-align: center;
  }

  .profile-pic.no-picture {
    padding: 30px;
    .fa,
    .fas,
    .far,
    .fab {
      font-size: 10em;
    }
  }

  .flex-row.manager-block {
    justify-content: flex-start;
    align-content: flex-start;
    align-items: center;
    height: 90px;
    flex-wrap: nowrap;
    border-top: 5px solid @sb-white;
  }

  .org-chart-icon {
    vertical-align: middle;
    display: block;
    font-size: 2em;
    margin-right: 30px;
  }

  .social-media-links {
    float: right;
    display: block;
    .twitter:hover {
      color: @twitter-blue;
    }
    .linkedin:hover {
      color: @linkedin-blue;
    }
    .fa,
    .fas,
    .far,
    .fab {
      font-size: 2em;
      margin: 0;
    }
  }

  .date-tile {
    flex: none;
    width: 50%;
    .fa,
    .fas,
    .far,
    .fab {
      margin: 5px;
    }

    .fa,
    .fas,
    .far,
    .fab {
      &.date-icon {
        display: block;
        font-size: 70px;
        margin: 10px;
      }
    }
    .dateline {
      font-family: @font-family-sans-serif;
      margin: 10px 0 0 0;
      display: block;
      font-size: @font-size-large;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .green-tile {
    background-color: @sb-green-type;
  }

  .dark-gray-tile {
    background-color: @fps-text;
  }

  .gray-tile {
    background-color: @sb-gray;
  }

  .orange-tile {
    background-color: @sb-orange-type;
  }

  .blue-tile {
    background-color: @sb-light-blue;
  }

  .purple-tile {
    background-color: @sb-executive;
  }

  .purple-tile,
  .blue-tile,
  .orange-tile,
  .green-tile,
  .gray-tile,
  .dark-gray-tile {
    padding: 15px;
  }

  .flex-row > * {
    border: 2.5px solid @sb-white;
  }

  .flex-row .flex-row > * {
    border: none;
  }

  .flex-row > .birthday-box {
    border-right: 5px solid @sb-white;
  }

  .interests-box {
    border-top: 5px solid @sb-white;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    a {
      color: @sb-white;
    }
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  h5.employee-name {
    font-size: @font-size-h3;
  }
  h6.employee-title {
    font-size: @font-size-h5;
  }

  .flex-2 {
    flex: 2 1;
  }

  .flex-1 {
    flex: 1 1;
  }

  @profile-pic-p: 20%;
  @left-col-w: 75%;

  .profile-pic {
    width: @profile-pic-p;
    height: @profile-pic-p;
  }

  .title-tiles {
    width: @left-col-w - @profile-pic-p;
  }

  .right-tile {
    width: 100% - @left-col-w;
  }

  .left-tile {
    width: @left-col-w;
  }

  @media only screen and (max-device-width: 990px) {
    .right-tile,
    .left-tile,
    .title-tiles {
      width: 100%;
    }

    .profile-pic {
      width: auto;
      max-width: 100%;
    }

    .flex-row {
      justify-content: center;
    }
  }
}
