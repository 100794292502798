/*
 * For jQuery/Other Plugin CSS Corrections and Overides
 */

/*----- File Upload Plugin ---- */
table.files {
  td p {
    margin: 0;
  }
  .progress {
    display: none;
  }
  .progress.in {
    display: block;
  }
}
