@import 'thirdparty/bs3-custom-vars.less';
@import 'animations.less';

.onboarding_header {
  text-align: center;

  .shoobx_x {
    color: @fps-text-secondary;
    font-family: 'Shoobx';
    font-size: 6em;
  }
}

.page_header {
  font-family: 'Source Sans Pro';
  font-weight: 300;
  font-size: 2.25em;
  color: @fps-page-header;
  text-transform: none;
}

.page_subheader {
  margin: 0 30px 3%;
  font-size: 0.8125em;
  font-weight: 500;
  color: @fps-text-secondary;
}

.onboarding {
  .section_subheader {
    display: inline-block;
    margin: 0;
    font-size: 1em;
    font-weight: 600;
    color: @fps-text;
    line-height: 1.4em;
    text-transform: none;
  }

  &.onboarding2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    [data-sb-form-name='new_onboarding2'] {
      display: inline-block;
      width: 65%;
    }

    .right_content {
      display: inline-block;
      width: 32%;
      padding: 15px;
      margin-top: 50px;
      background-color: @fps-box-background;

      > img {
        position: absolute;
        transform: translate(50%, -95%);
      }

      > h1 {
        font-size: 1em;
        font-weight: 600;
        color: @fps-section-header;
        text-align: center;
      }

      > ul {
        padding-left: @subtree-padding;
      }
    }
  }

  &.pricing_priced {
    .fee_section {
      display: flex;
      padding: 30px 20px;
      &.enablement_section {
        background-color: @fps-box-background;
      }
    }

    .fee_header {
      min-width: 220px;

      .section_header {
        display: inline-block;
        margin: 0 0 2%;
        font-size: 1.7em;
        font-weight: 800;
        color: @fps-section-header;
      }

      .price {
        font-weight: 400;
        font-size: 1.5em;
      }

      .billed_annually {
        font-size: 1em;
        font-weight: 400;
      }
    }

    .img-shell {
      margin-left: 5%;
    }

    .pricing_text {
      margin-left: -20px;

      li {
        margin: 0 0 5px 0;
      }
    }
  }

  &.pricing_free {
    .main_content {
      display: flex;
      justify-content: space-between;

      .left_content {
        display: flex;

        .free_text {
          font-size: 2em;
        }

        .price {
          font-size: 5em;
          text-align: center;

          .dollar {
            vertical-align: middle;
          }

          .value {
            font-size: 1.5em;
            font-weight: 800;
            vertical-align: middle;
          }
        }

        a {
          display: block;
          text-align: center;
        }

        ul {
          list-style: none;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          li {
            font-weight: 300;

            &::before {
              content: 'e';
              margin-right: 10px;
              font-family: 'Shoobx';
              vertical-align: middle;
            }
          }
        }
      }

      img {
        margin: 0 auto;
      }
    }

    p {
      margin: 5% 0 0;
    }
  }

  &.instructions {
    .intro {
      display: flex;
      align-items: center;

      .img-shell {
        margin-left: 3%;
        padding-bottom: 30px;
      }
    }

    .get_started {
      margin-bottom: 5%;

      > .section_subheader {
        margin-bottom: 1.5%;
      }

      .box_container {
        display: flex;
        justify-content: space-between;

        .section_subheader {
          margin-bottom: 3%;
        }

        .box {
          width: 32%;
          padding: 25px;
          background-color: @fps-box-background;
        }
      }
    }

    .onboarding_prompt {
      display: flex;

      .img-shell {
        margin-right: 3%;
      }
    }
  }
}

.step-description {
  margin-bottom: 40px;
}

.sections-description {
  font-size: 1.15em;
  font-weight: bold;
}

.orientation-blocks {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 20px;
  margin-right: 0;
}

.row.orientation-blocks > div.col-md-3 {
  margin: 20px 0;
}

.orientation-block {
  background: @fps-box-background;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
}

.orientation-block img {
  width: 120px;
  height: 120px;
  margin-top: -50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.orientation-block h3 {
  margin: 20px 0 20px;
}

.orientation-block li {
  margin-bottom: 10px;
}

img.rocketship {
  max-height: 375px;
  width: auto;
}

.onboarding-support {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  &div {
    max-width: 500px;
  }
}

hr.onboarding_charter {
  margin-top: 0px;
  margin-bottom: 0px;
  border-top-width: 2px;
}

.onboarding_company_summary {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 10px;

  section {
    display: flex;
    flex-wrap: wrap;
    &:not(:last-child) {
      margin-bottom: 8px;
    }

    > div:first-child {
      height: 20px;
      margin-bottom: 20px;
      min-width: 20px;
      padding-right: 10px;
      label {
        max-width: 140px;
      }
    }

    .values {
      display: flex;
      flex-direction: column;

      &.break {
        min-width: 100%;

        label {
          min-width: 20px;
          padding-right: 10px;
        }
      }
      sbx-icon {
        padding-right: 5px;
      }
    }
  }
}
