sb-approval-list {
  span {
    display: block;
  }
  sb-inject-scope span {
    display: inline-block;
  }
  li:not(:last-child) {
    margin-bottom: 10px;
  }
  li,
  .rejection {
    clear: both;
  }
  .rejection {
    margin-top: 10px;
  }
}
