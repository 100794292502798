.fa.fa-check-circle.success-icon {
  color: @sb-green;
}

.fa.fa-times-circle {
  &.not-started {
    color: @sb-red;
  }
  &.inactive {
    color: @fps-text-secondary;
  }
}

.fa.fa-clock-o.wait {
  color: @sb-orange;
}

.fa.fa-ellipsis.in-progress {
  color: @sb-orange;
}

.fa.fa-exclamation-circle.todo {
  color: @sb-orange;
}

.fa.fa-exclamation-circle.fail {
  color: @sb-red;
}
