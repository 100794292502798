.counsel-settings-wi {
  .reset-button {
    margin-top: 24px;
    button {
      float: right;
    }
  }
  ul {
    padding-left: 20px;
  }
  li {
    list-style-type: none;
  }
  .top-info,
  .counsel-table {
    padding: 0 35px;
  }
  .top-panel {
    padding: 0 25px;
  }

  .top-info {
    display: flex;
    flex-direction: row;

    .section-info {
      flex-basis: 370px;
    }
  }

  .top-panel {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    z-index: 100;
    background: #fff;

    .panel-option {
      flex-grow: 0;

      ul {
        padding-left: 0;
      }

      &:not(:last-child) {
        a {
          border-right: solid 2px @sb-gray-light;
        }
      }

      a {
        color: @fps-text;
        font-size: 14px;
        font-weight: bold;
        padding: 0 10px;

        &:hover {
          background: @sb-green;
          border-radius: 3px;
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}
