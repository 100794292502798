/*
 * Styles for Data Room and document views
 */

@toc-button-width: 110px;
@toc-button-height: 25px;
@toc-close-width: 30px;
@toc-left-margin: -10px;

@folder-border-style: 1px solid @sb-gray-lighter;

sb-document-status-type {
  .doc-status-active {
    color: @sb-green;
  }
  .doc-status-pending:before {
    content: 'f';
    color: @sb-yellow;
  }
  .doc-status-inactive {
    color: @fps-text-secondary;
  }
}

.clickable {
  cursor: pointer;

  sbx-icon {
    color: @sb-green-type;
    margin-right: 10px;

    i {
      text-align: center;
      width: 1.25em;
      color: @sb-green-type;
    }
  }

  &:hover {
    color: @sb-green-bright;

    sbx-icon {
      color: @sb-green-bright;
    }
  }
}

.base-list-page sb-filtered-list .result-cards .document-cards .list-group-item.open {
  border: 1px solid @sb-gray-light;
}

.dr-listing-header {
  line-height: 35px;
  background-color: @sb-gray-light;
  font-size: 85%;
  text-transform: uppercase;
  font-weight: normal;
}

.share-button {
  font-weight: 700;
  padding-left: 0px !important;
}

.upload-document-modal {
  sb-multi-file-upload {
    width: 100%;
    height: 450px;
  }

  .modal-footer {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
  }
  .sbx-dropzone {
    height: 300px;

    .dnd-info {
      font-size: 30px;
    }
  }
}

.base-list-page sb-data-room-page {
  .side {
    .filters {
      .field-tag {
        /* text-transform: uppercase; /* I suspect this might be back. */
        font-size: 14px;
        .switch-container {
          vertical-align: middle;
        }
      }
      sb-switch {
        font-size: 14px;
        margin-right: 8px;
        margin-left: auto;
      }

      .filter-categories-new ul li {
        padding: 1px 0 1px 8px;
      }
    }
  }
  .action-icons {
    text-align: right;
    .btn {
      padding: 5px;
      font-size: 16px;
    }
  }
  .dr-breadcrumbs {
    font-size: 120%;
    padding: 10px 0;
    display: flex;

    .action-icons {
      margin-left: auto;

      sb-collapsed-actions {
        .btn {
          border: none;
          background: none;
        }

        items sb-sharing-btn {
          padding: 5px;

          button {
            width: 100%;
            background-color: transparent;
            border: 0;
          }
        }
        span.btn,
        sb-sharing-btn button {
          text-transform: none;
          text-align: left;
          color: @fps-text;

          i {
            margin-right: 5px;
            text-align: center;
            width: 1.25em;
            padding: 0;
            color: @sb-green-type;
          }

          &:hover {
            color: @sb-green-type;
            text-decoration: none;
          }
        }
      }
    }
  }
  .bottom-load {
    margin-bottom: 30px;
  }
}

.document-card.standalone-document-card {
  display: flex;

  .document-icon {
    padding-right: 10px;
  }
  .document {
    flex: 1;
  }
}

.document-card,
.folder-card {
  sb-breadcrumb {
    font-size: 85%;
    color: @fps-text-secondary;

    span::before {
      font-size: 100%;
    }
  }
  .header-box {
    position: relative;
  }
  h4 {
    text-transform: none;
    font-size: 16px;
    margin: 4px 0 0 0;

    a {
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
    }
  }
  h4.not-allowed {
    color: @fps-text;
  }
  .dr-status {
    margin: 5px 0;
    white-space: nowrap;
  }
  .dr-date {
    margin: 5px 0;
    white-space: nowrap;
  }
  .basic-info {
    color: @fps-text-secondary;
    font-size: 90%;
  }
  .rule {
    border-top: 1px solid @sb-gray-light;
    margin-top: 5px;
  }

  dl {
    margin: 0;
    padding: 5px 15px 0 0;
    width: 50%;
    font-size: 85%;
    float: left;
    border-left: none;

    &.full {
      width: 100%;
    }
    dt {
      color: @fps-text-secondary;
      text-transform: uppercase;
      font-weight: normal;
      display: inline;
      font-size: 12px;
      margin-right: 5px;
    }
    dt:after {
      content: ': ';
    }
    dd {
      display: inline;
      font-weight: normal;
      font-size: 16px;
    }
    dd:not(:last-child):after {
      content: '';
      display: block;
    }
  }
  .action-icons {
    text-align: right;
    .dropdown {
      text-align: left;
      display: inline;
    }
    .dropdown-menu {
      min-width: 180px;
    }
  }

  .icons {
    color: @fps-text-secondary;
    font-size: 150%;
    i {
      float: right;
      clear: both;
    }
  }

  sb-collapsed-actions .btn {
    border: none;
    background: none;
    .fa,
    .fas,
    .far,
    .fab {
      font-size: 20px;
    }
    &:active {
      box-shadow: none;
      outline: none;
    }
  }
}

.base-list-page .result-cards .folder-card {
  h4 {
    margin: 5px 0;
    a {
      color: @fps-text;
      text-decoration: none;
    }
  }
}

.mixed-case-heading {
  text-transform: none;
  font-weight: 400;
  font-size: 200%;
  line-height: 100%;
  color: @sb-gray-darker;
}

/* single document item */
.dr-header {
  padding: 10px 5px 5px;

  .backlink {
    margin-top: 8px;
  }

  h1.dr-heading {
    .mixed-case-heading();
    margin: 0 70px;

    .fa-file {
      position: absolute;
      font-size: 210%;
      color: @fps-text-secondary;
      left: 30px;
      top: 5px;
    }
  }

  .toolbar {
    margin: 5px 0 10px 70px;

    > *:not(:first-child) {
      margin-left: 10px;
    }

    .icons {
      color: @fps-text-secondary;
      line-height: 14px;
      font-size: 150%;
      position: relative;
      bottom: -4px;
    }

    .date {
      font-weight: 400;
    }

    small {
      line-height: 80%;
      text-transform: uppercase;
      font-weight: 600;
      color: @fps-text-secondary;
      font-size: 12px;
      border-left: 1px solid @sb-gray;
      padding-left: 15px;
    }

    .folderinfo:not(:last-child):after {
      content: ', ';
    }
  }
}

.red-text {
  color: @sb-red-type;
}

.dr-content-page {
  @dr-content-sidebar-width: 350px;
  @dr-content-padding: 45px;

  border: 1px solid @sb-gray;
  position: relative;
  margin: 20px 0 10px @toc-button-height;

  .divider {
    background-color: rgba(0, 0, 0, 0.03);
    border-right: 1px solid @sb-gray;
    height: 100%;
    margin: 0;
    pointer-events: none;
    position: absolute;
    width: @dr-content-sidebar-width;
  }

  .shoobx-pdf-preview {
    margin-left: @dr-content-sidebar-width;
  }

  .shoobx-document-body {
    margin: 0;
    margin-left: @dr-content-sidebar-width;
    // padding-left: @dr-content-sidebar-margin;
    padding: @dr-content-padding;

    table {
      margin-bottom: 11px;
    }

    > table,
    .subsection,
    section {
      clear: both;
    }
  }
  .document-info {
    float: left;
  }
  .section-info,
  .document-info {
    // Sections infos are inserted into the document itself, so it inherites
    // document styles. We need to reset them here.
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    font-size: 100%;
    width: @dr-content-sidebar-width;
    padding: 0 20px 20px 20px;
    color: @fps-text;

    h3 {
      margin-top: 0;
      font-style: normal;
      font-size: 100%;
      color: @fps-text;

      .fa-question-circle {
        color: @fps-text-secondary;
      }
    }

    .section-label {
      margin-top: 10px;
    }
    dl {
      dt {
        margin-top: 10px;
        color: @fps-text-secondary;
        font-weight: normal;
      }
      dl {
        margin-left: 20px;
        dt {
          margin-top: 5px;
        }
        dt:before {
          content: '\21b3';
          margin-left: -1em;
        }
      }
    }
  }
  .bound-to-content {
    float: left;
    margin-left: -@dr-content-sidebar-width - @dr-content-padding;
  }

  .section-content.rml-content {
    margin: 0 4em;
  }
  .no-preview {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

/* XXX: HACK!!! Waiting for Albertas to come up with a universal solution. */

.data-room#content {
  padding: 0px 10px 0px 10px;
  bottom: auto;

  .nav-tabs {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.toc {
  position: fixed;
  // left: 50px;
  z-index: 10;

  .outline-button {
    height: @toc-button-width;
    width: @toc-button-height;
    color: @fps-text-secondary;
    background-color: @sb-gray-light;
    position: absolute;
    cursor: pointer;
    margin-left: @toc-left-margin;

    .inner {
      text-transform: uppercase;
      display: block;
      width: @toc-button-width;
      height: @toc-button-height;

      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);

      -webkit-transform-origin: right top;
      -moz-transform-origin: right top;
      -ms-transform-origin: right top;
      -o-transform-origin: right top;

      margin-left: -@toc-button-width;
      padding: 1px 10px;
      font-weight: bold;
    }

    .fa,
    .fas,
    .far,
    .fab {
      position: absolute;
      top: 4px;
      padding: 4px 0;
      background-color: @sb-green-type;
      color: white;
      width: 25px;
      // height: 25px;
      margin-bottom: -25px;
      text-align: center;
    }
  }

  .outline-sections {
    // outline: 1px solid blue;
    min-height: @toc-button-width;
    border: 4px solid @sb-gray-light;
    border-left: @toc-button-height solid @sb-gray-light;
    background-color: white;
    padding: 10px 5px;
    padding-right: @toc-close-width + 5px;
    margin-left: @toc-left-margin;

    .outline-section-page {
      float: left;
      max-width: 300px;
    }
  }

  .outline-close {
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    :hover {
      background-color: @fps-list-hover;
    }
    .inner {
      // outline: 1px solid red;
      height: 100%;
      display: table;
      a {
        width: @toc-close-width;
        padding: 5px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        color: @fps-text-secondary;
      }
    }
  }
}

.dataroom-access {
  .header {
    margin-left: 35px;
  }
  .dataroom-access-nav {
    padding: 10px 20px 0px 10px;
    bottom: auto;
  }
  .dataroom-access-buttons {
    padding: 10px;
  }
  .backlink {
    margin-top: 23px;
    padding-right: 20px;
  }
}

.finance-document-card {
  dl {
    float: none;
    width: auto;
  }

  .finance-actions {
    margin-top: 15px;
  }
}

#signature-table {
  td {
    vertical-align: bottom;
    padding-right: 10px;
  }
}

.sharing-modal {
  sb-sharing-list {
    padding-bottom: 5px;
    display: block;
  }
  .sharing-list-items li {
    padding: 10px 0px;
    margin: -0;
    border-bottom: 1px solid @sb-gray-light;
  }
  .grant-detail {
    padding: 10px 0;
    border-top: 1px solid @sb-gray-light;

    .properties {
      margin-bottom: 0px;
      dt {
        font-size: 12px;
        padding-right: 5px;
      }
      sb-collapsed-actions .dropdown {
        display: inline;
      }
    }
  }
  .sharing-list-items li:last-child {
    border: none;
  }

  ul.sharing-grants {
    padding-bottom: 5px;
    .grant {
      margin: 0;
      .row {
        margin: 0;
      }
    }
  }
  .row.grant-row {
    margin: 0;
    cursor: pointer;
  }
  sb-sharing-grant {
    .active {
      border: 1px solid @sb-gray-light;
    }
    .icon {
      padding-right: 5px;
    }
  }

  .sharing-grants-headers {
    margin: 0;
    padding: 0;
    line-height: 35px;
    text-transform: uppercase;
    font-size: 85%;
  }

  .sharing-grants-headers li {
    margin: 0;
    background-color: @sb-gray-light;
  }
  .grant .panel-detail {
    .circle-icon {
      color: white;
      background: @sb-gray;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      text-align: center;
      line-height: 10px;
      vertical-align: middle;
      padding: 5px;
    }
    padding: 10px 15px 10px;
  }
  .panel-detail.icon {
    padding: 0;
  }

  ol,
  ul {
    padding-left: 0;
    list-style: none;
  }
}

.diligence-multi-step {
  padding-left: 20px;
}

sb-dataroom-folder-tree {
  > sb-dataroom-folder-level:first-child {
    border-bottom: @folder-border-style;
    margin-bottom: 1px;
    div[ng-transclude='icon'] {
      padding-left: 0px;
      width: 28px;
    }
  }
  + sb-dataroom-folder-levelx {
    border-top: @folder-border-style;
    margin-top: 1px;
  }
}

sb-trash-listing {
  @left-right-padding: 15px;
  @top-bottom-padding: 10px;
  li {
    display: flex;
    padding: @top-bottom-padding @left-right-padding;
    align-items: center;
    > *:not(:last-child) {
      margin-right: @left-right-padding;
    }
    > span {
      flex: 1;
    }
    > *:not(span) {
      font-size: 20px !important;
    }
  }
}

sb-dataroom-folder-level {
  @left-right-padding: 5px;
  @icon-width: 14px;
  @count-width: 30px;
  @item-min-height: 30px;
  @scroll-bar-width: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: @item-min-height;
  padding: 0 (@left-right-padding + @scroll-bar-width) 0 0;
  &[disabled='disabled'] {
    color: @fps-text-secondary;
    cursor: auto;
  }
  &[indent-level='0'] {
    border-top: @folder-border-style;
  }
  > *:not(.tree-checkbox) {
    flex: 1;
  }
  .border {
    flex: none;
    width: @left-right-padding;
    align-self: stretch;
  }
  &.selected {
    background: @fps-list-selected;
    .border {
      background: @sb-green;
    }
  }
  div[ng-transclude='icon'],
  .count {
    height: @item-min-height;
    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    flex: none;
    & > * {
      flex: 1;
      text-align: center;
    }
  }
  div[ng-transclude='icon'] {
    width: @icon-width;
    font-size: 14px;
  }
  div[ng-transclude='itemtitle'] {
    line-height: 14px;
    padding: 8px 0;
  }
  .count {
    width: @count-width;
    margin-left: 2 * @left-right-padding;
    span {
      background: @sb-gray;
      color: #fff;
      font-size: 12px;
    }
  }
  .title {
    font-weight: 600;
    &.empty {
      font-weight: 200;
    }
  }
}

.share-table-icons {
  padding: 5px;
  color: @fps-text-secondary;
}

sb-bulk-sharing-level {
  display: flex;
  cursor: pointer;
  min-height: 25px;
  span.marker {
    flex: 0.01;
  }
  .parent-selected .checkradios-checkbox.checkradios-unselected .checkradios-check {
    display: block;
    color: @sb-green;
  }
  .marker {
    border-left: 5px solid white;
  }
  .marker.bordered {
    border-left: 5px solid @sb-green;
  }
  &.closed {
    min-height: 0px;
    height: 0px;
  }
  sb-collapsed-actions .dropdown {
    width: 147px;
    button {
      width: 100%;
      text-align: left;
    }
  }
  .sharing-controls {
    float: right;
    > * {
      float: right;
      padding-right: 5px;
    }
  }
  .toggle {
    flex: 0.04;
  }
  > * {
    flex: 1;
  }
  .sharing-info {
    font-size: 90%;
    flex: 0;
    flex-basis: 210px;
    sb-collapsed-actions .dropdown {
      width: auto;
    }
    .sharing-level {
      min-width: 100px;
      display: inline-block;
    }
  }
}

sb-document-details-summary {
  display: block;
  ul {
    margin: 0;
    padding: 12px 0;
    columns: 2;

    li {
      margin-bottom: 10px;

      & > h5 {
        color: @fps-text-secondary;
        text-transform: uppercase;
        font-weight: normal;
        display: inline;
        font-size: 12px;
        margin-right: 5px;
      }
      & > h5::after {
        content: ': ';
      }
    }
  }
}

sb-dataroom-listing,
.document-card,
.folder-card {
  .fa-folder,
  .fa-file,
  .fa-file-alt {
    &.deferred::after,
    &.managed::after {
      height: 0;
      width: 0;
      display: inline-block;
      font-style: normal;
      font-weight: normal;
      cursor: default;
      position: relative;
    }
    &.managed::after {
      font-family: Shoobx;
      content: 'x';
    }
  }
  .fa-folder {
    font-size: 21px;
    color: @fps-text-secondary;
    &.managed::after {
      left: -1.5ex;
      top: -0.25ex;
      font-size: 12px;
      color: #ffffff;
    }
  }
  .fa-file,
  .fa-file-alt {
    font-size: 24px;

    color: @fps-text;
    &.managed::after {
      left: -1.25ex;
      top: -0.1ex;
      font-size: 16px;
      color: @fps-text-secondary;
    }
    &.deferred::after {
      font-family: 'Font Awesome 6 Free';
      font-weight: 900;
      content: '\f071'; // .fa-exclamation-triange
      left: -1.5ex;
      top: -0.2ex;
      font-size: 50%;
      color: @sb-blue;
    }
  }
}

sb-dataroom-listing {
  position: relative;
  display: block;
  > .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    text-align: center;
    align-items: center;
    z-index: 10;
    font-size: 25px;
    > * {
      flex: 1;
    }
  }
  ul {
    margin: 0;
  }
  li.document {
    transition: 0.1s ease border-color;
    > header {
      transition: 0.1s ease background;
    }
  }
  > header,
  li.folder,
  li.document > header {
    cursor: pointer;
    display: flex;
    > * {
      flex: 1;
      padding: 8px 12px 8px 0;
    }
    > *:first-child {
      width: 60%;
      flex: none;
      padding-left: 12px;
    }
  }
  li.folder > * {
    width: 100% !important;
    sb-breadcrumb {
      display: block;
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 5px;
    }
  }
  li.document {
    > header > *:last-child {
      display: flex;
      justify-content: space-between;
      > button {
        width: 22px;
        outline: none;
      }
    }
    sb-document-details-summary {
      margin: 0 12px;
    }
    &.open {
      border-color: @sb-gray-lighter;
    }
    &.open:nth-child(2n + 1) {
      border-top-color: #fff;
      sb-document-details-summary {
        border-top: 1px solid @sb-gray-lighter;
      }
    }
  }
  li.empty {
    background: #fff;
    color: @fps-text-secondary;
    font-size: 22px;
    padding: 8px 12px;
  }
  > header {
    background: @sb-gray-light;
    text-transform: uppercase;
    font-size: 14px;
    user-select: none;
    .no-sort {
      cursor: auto;
    }
    &.sort-order-asc span.sort-key::after,
    &.sort-order-dsc span.sort-key::after {
      font-family: 'Font Awesome 6 Free';
      font-weight: 900;
      color: @sb-gray-darker;
      padding-left: 15px;
    }
    &.sort-order-asc span.sort-key::after {
      content: '\f062'; // up arrow
    }
    &.sort-order-dsc span.sort-key::after {
      content: '\f063'; // down arrow
    }
  }
  > .list-unstyled-container > ul > li {
    border: 2px solid #fff;
    > *:first-child:not(header),
    > header > *:first-child {
      font-weight: bold;
      .fa,
      .fas,
      .far,
      .fab {
        padding-right: 15px;
      }
    }
  }
  > .list-unstyled-container > ul > li:nth-child(2n) {
    border-color: @sb-gray-lighter;
    &.document > header,
    &.folder {
      background: @fps-list-odd;
    }
  }
  li.selected {
    border-color: @sb-green !important;
    > header {
      background: @sb-green !important;
      color: #fff;
    }
    .fa::after,
    .fas::after,
    .far::after,
    .fab::after,
    .fa,
    .fas,
    .far,
    .fab {
      color: #fff;
    }
  }
}

.doc-perms-heading {
  button {
    margin-left: 15px;
  }
}
.doc-perms-list {
  .header {
    margin-bottom: 3px;
    > div {
      background-color: @sb-gray-light;
      color: @fps-text;
      font-weight: 300;
      font-size: 110%;
      padding: 5px 15px;
    }
  }
  > .row:not(:first-child) {
    border: 3px solid @sb-gray-light;
    margin-bottom: 3px;
    > div:first-child {
      padding: 15px;
      a {
        color: @fps-text;
      }
    }
  }
  div.access-items {
    .fa,
    .fas,
    .far,
    .fab,
    strong {
      color: @fps-text-secondary;
    }
    .fa,
    .fas,
    .far,
    .fab {
      &.level {
        color: @fps-text;
        padding-right: 10px;
      }
    }
    > div.row {
      &:only-child {
        // Center vertically
        padding-top: 10px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid @sb-gray-light;
      }
      > div {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
}
