/*
 * Playtype Foundry
 * Fidelity Sans
 *
 * Ultra Light - 100
 * Light - 300
 * Light Italic - 300
 * Regular - 400
 * Italic - 400
 * Bold - 700
 * Bold Italic - 700
 * Extra Bold - 800
 *
 *
 * Fidelity Sans Condensed
 *
 * Medium
 *
 */

@font-face {
  font-family: 'Fidelity Sans';
  src: url('./../../fonts/fidelity-sans/FidelitySans-UltraLight.eot')
    format('embedded-opentype');
  src:
    url('./../../fonts/fidelity-sans/FidelitySans-UltraLight.woff2') format('woff2'),
    url('./../../fonts/fidelity-sans/FidelitySans-UltraLight.woff') format('woff'),
    url('./../../fonts/fidelity-sans/FidelitySans-UltraLight.svg') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Fidelity Sans';
  src: url('./../../fonts/fidelity-sans/FidelitySans-Light.eot')
    format('embedded-opentype');
  src:
    url('./../../fonts/fidelity-sans/FidelitySans-Light.woff2') format('woff2'),
    url('./../../fonts/fidelity-sans/FidelitySans-Light.woff') format('woff'),
    url('./../../fonts/fidelity-sans/FidelitySans-Light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Fidelity Sans';
  src: url('./../../fonts/fidelity-sans/FidelitySans-LightItalic.eot')
    format('embedded-opentype');
  src:
    url('./../../fonts/fidelity-sans/FidelitySans-LightItalic.woff2') format('woff2'),
    url('./../../fonts/fidelity-sans/FidelitySans-LightItalic.woff') format('woff'),
    url('./../../fonts/fidelity-sans/FidelitySans-LightItalic.svg') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Fidelity Sans';
  src: url('./../../fonts/fidelity-sans/FidelitySans-Regular.eot')
    format('embedded-opentype');
  src:
    url('./../../fonts/fidelity-sans/FidelitySans-Regular.woff2') format('woff2'),
    url('./../../fonts/fidelity-sans/FidelitySans-Regular.woff') format('woff'),
    url('./../../fonts/fidelity-sans/FidelitySans-Regular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Fidelity Sans';
  src: url('./../../fonts/fidelity-sans/FidelitySans-Italic.eot')
    format('embedded-opentype');
  src:
    url('./../../fonts/fidelity-sans/FidelitySans-Italic.woff2') format('woff2'),
    url('./../../fonts/fidelity-sans/FidelitySans-Italic.woff') format('woff'),
    url('./../../fonts/fidelity-sans/FidelitySans-Italic.svg') format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Fidelity Sans';
  src: url('./../../fonts/fidelity-sans/FidelitySans-Bold.eot')
    format('embedded-opentype');
  src:
    url('./../../fonts/fidelity-sans/FidelitySans-Bold.woff2') format('woff2'),
    url('./../../fonts/fidelity-sans/FidelitySans-Bold.woff') format('woff'),
    url('./../../fonts/fidelity-sans/FidelitySans-Bold.svg') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Fidelity Sans';
  src: url('./../../fonts/fidelity-sans/FidelitySans-BoldItalic.eot')
    format('embedded-opentype');
  src:
    url('./../../fonts/fidelity-sans/FidelitySans-BoldItalic.woff2') format('woff2'),
    url('./../../fonts/fidelity-sans/FidelitySans-BoldItalic.woff') format('woff'),
    url('./../../fonts/fidelity-sans/FidelitySans-BoldItalic.svg') format('svg');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Fidelity Sans';
  src: url('./../../fonts/fidelity-sans/FidelitySans-ExtraBold.eot')
    format('embedded-opentype');
  src:
    url('./../../fonts/fidelity-sans/FidelitySans-ExtraBold.woff2') format('woff2'),
    url('./../../fonts/fidelity-sans/FidelitySans-ExtraBold.woff') format('woff'),
    url('./../../fonts/fidelity-sans/FidelitySans-ExtraBold.svg') format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Fidelity Sans Condensed';
  src: url('./../../fonts/fidelity-sans/FidelitySansCondensed-Medium.eot')
    format('embedded-opentype');
  src:
    url('./../../fonts/fidelity-sans/FidelitySansCondensed-Medium.woff') format('woff'),
    url('./../../fonts/fidelity-sans/FidelitySansCondensed-Medium.woff2')
      format('woff2'),
    url('./../../fonts/fidelity-sans/FidelitySansCondensed-Medium.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}
