.modal .dt-viewer-content {
  margin: 0;
}
.diff-text-add {
  color: #5e8e0a;
}
.diff-text-add * {
  color: inherit;
}
.diff-text-remove {
  color: @sb-red-type;
  text-decoration: line-through;
}
.diff-text-remove * {
  color: inherit;
}
.diff-formatting-add {
  color: @sb-blue;
}
.diff-formatting-remove {
  color: @sb-blue;
}
sb-document-template-reviewer .doctemplate-body .tal-markup.diff-text-add {
  border-color: #5e8e0a;
}
sb-document-template-reviewer .doctemplate-body .tal-markup.diff-text-remove {
  border-color: @sb-red-type;
}
sb-document-template-reviewer .doctemplate-body .tal-markup.diff-attribute-update {
  border-color: @sb-blue;
}
sb-document-template-reviewer
  .doctemplate-body
  fieldset.tal-markup.diff-text-add
  legend {
  color: #5e8e0a;
}
sb-document-template-reviewer
  .doctemplate-body
  fieldset.tal-markup.diff-text-remove
  legend {
  color: @sb-red-type;
}
sb-document-template-reviewer
  .doctemplate-body
  fieldset.tal-markup.diff-attribute-update
  legend {
  color: @sb-blue;
}

sb-document-template-reviewer {
  .alert {
    margin-top: 10px;
  }
  .compare-btn {
    display: block;
    margin-top: 10px;
  }
  .doctemplate-body {
    min-height: 400px;
    position: relative;
    text-align: justify;
    > .loading {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.7);
      width: 100%;
      height: 100%;
    }
    > sb-compiled-content {
      position: static;
    }
    .rml-content {
      margin: 10px;
    }
    .doctemplate-help-popover {
      .fa,
      .fas,
      .far,
      .fab {
        color: @sb-green-type;
      }
    }
    .doctemplate-comment {
      position: relative;
      .fa,
      .fas,
      .far,
      .fab {
        color: @sb-green-type;
        position: absolute;
        top: 14px;
        left: 0;
        font-size: 25px;
      }
      .main-comment {
        margin-left: 50px;
        text-align: left;
        border: 2px solid @sb-orange;
        position: relative;
        border-radius: 4px;
      }
      .main-comment:before {
        content: ' ';
        position: absolute;
        top: 10px;
        left: -20px;
        border-width: 16px 20px 2px 0;
        border-style: solid;
        border-color: transparent @sb-orange;
        display: block;
        width: 0;
      }
      .comment-author-time {
        padding: 10px;
        text-transform: uppercase;
        font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
        color: @sb-green-type;
        font-weight: bold;
      }
      .comment-content-print {
        display: none;
        padding: 0 10px 10px;
      }
      .comment-content {
        max-height: 0;
        transition: 0.5s ease;
        padding: 0 10px;
        overflow: hidden;
      }
      .main-comment:hover .comment-content {
        max-height: 500px;
        padding-bottom: 10px;
        transition: 0.5s ease;
      }
    }
    .doctemplate-body-para {
      margin-bottom: 15px;
      clear: both;
    }
    .tal-inline-condition {
      background: #d1e1fb;
    }
    .tal-inline-loop {
      background: #f5d1fb;
    }
    .tal-markup {
      border: 2px dashed @sb-gray;
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 20px;
      h3 {
        margin-top: -29px;
        margin-bottom: 20px;
        position: absolute;
        color: @fps-text-secondary;
        font-weight: bold;
        background: white;
        font-style: normal;
        font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
        max-width: 730px;
      }
    }
    fieldset.tal-condition.tal-markup {
      clear: both;
    }
    fieldset.tal-markup {
      padding: 10px 15px 15px 5px;
      legend {
        color: @fps-text-secondary;
        font-weight: bold;
        background: white;
        font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
        max-width: 730px;
        margin-bottom: 0px;
        font-size: 13px;
        width: clear;
        text-transform: uppercase;
        width: auto;
        border: none;
        padding: 0 5px;
      }
    }
    fieldset .tal-data fieldset legend {
      word-wrap: break-word;
      max-width: 98%;
    }
    .tal-data:only-child {
      margin-left: 225px;
    }

    .tal-code {
      color: #8bc246;
      background: #000000;
    }
    .attribute-change {
      display: none;
    }
    .tal-table > fieldset,
    .tal-row {
      clear: both;
    }
    .tal-data {
      float: left;
      width: 15%;
      padding: 5px;
    }
    .tal-data:last-child {
      width: 84%;
    }
    .diff-text-add {
      color: #5e8e0a;
    }
    .diff-text-add * {
      color: inherit;
    }
    .diff-text-remove {
      color: @sb-red-type;
      text-decoration: line-through;
    }
    .diff-text-remove * {
      color: inherit;
    }
    .diff-text-add fieldset.tal-markup legend {
      color: #5e8e0a;
    }
    .diff-text-remove fieldset.tal-markup legend {
      color: @sb-red-type;
    }
    .tail {
      font-style: normal;
      font-weight: normal;
    }
    .anchor {
      visibility: hidden;
    }
  } // end .doctemplate-body
}

@media print {
  sb-document-template-reviewer {
    .comment-content,
    .dt-viewer-content-right {
      display: none !important;
    }
    .dt-viewer-content-left {
      border: none;
      width: 100%;
    }
    .dt-viewer-content table {
      width: 100%;
    }
    .comment-content-print {
      display: block !important;
    }
  }
}
