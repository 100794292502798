.investment-portfolio {
  sb-pie-chart,
  sb-bar-chart {
    display: block;
  }

  .investment-portfolio-container {
    margin-top: 59px;
    padding-top: 15px;
  }

  .total-invested-title {
    background: @fps-group-background;
    color: @sb-green;
    padding: 10px 15px 5px;
    text-align: center;
    font-size: @font-size-h2;
    line-height: @font-size-h2;
    font-weight: 700;

    > span {
      display: block;
      font-size: @font-size-h4;
      font-weight: 300;
    }
  }

  .my-exit-scenarios-title {
    color: @fps-text;
    font-size: @font-size-h4;
    font-weight: 700;
    text-transform: uppercase;

    &.above-chart {
      height: 83px;
    }
  }

  .investment-portfolio-table {
    tr {
      border-left: 8px solid transparent;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    span.list-item-label {
      color: @fps-text-secondary;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 12px;
      margin-right: 5px;
    }

    span.ownership-date {
      color: @fps-text-secondary;
    }
  }

  .shared-legend {
    margin-top: 10px;
  }

  .exit-scenario-list-item {
    display: flex;
    border-bottom: 1px solid @sb-gray;
    padding-bottom: 5px;

    &:last-child {
      border: 0;
      padding-bottom: 0;
    }
  }

  .set-entity-button {
    margin: 11px 0;
  }

  .exit-scenario-list-item-title {
    width: 30px;
    color: @fps-text-secondary;
    font-size: @font-size-h4;
    align-items: center;
    display: flex;
  }

  .loading-block {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .loading-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 28px;

    .fa-sync {
      color: @sb-gray !important;
      padding: 0 !important;
      margin-right: 5px;
    }
  }

  .chart-wrapper {
    position: relative;

    .loader {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(241, 244, 246, 0.5);
    }
  }
}

.exit-scenario-modal {
  .exit-scenario-modal-exit-input-group {
    background: @fps-group-background;
    // margin: 0 10px 10px 10px;
    padding: 15px;

    .input-group .form-control.currency-field {
      width: 100%;
    }
  }

  .exit-scenario-modal-explanation {
    background: #fff;
    margin-top: 15px;
    padding: 10px 15px;
  }
}
