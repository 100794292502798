.counsel-settings-summary {
  margin-left: auto;
  margin-right: auto;
  width: 90%;

  table {
    border-collapse: separate;
    border-spacing: 5px;
  }

  thead {
    border-bottom: solid @sb-gray-light 3px;
  }

  th {
    padding-left: 10px;
  }

  tr:nth-child(even) {
    background-color: @fps-table-odd;
  }

  td {
    padding: 10px;
  }

  td span {
    width: 100%;
    border-right: solid @sb-gray-light 1px;
    display: inline-block;
  }

  td:last-child span {
    border-right: none;
  }
}
